export default {
  /**
   * Search Field Events
   */
  QUERY_INPUTTED: 'queryInputted',
  BACKSPACE_PRESSED: 'backspacePressed',
  IS_ERROR_OCCURRED: 'isErrorOccurred',
  QUERY_INPUTTED_AFTER_RELOAD: 'queryInputtedAfterReload',
  SHIFT_TAB_PRESSED: 'shiftTabPressed',
  TAB_PRESSED: 'tabPressed',
  ON_FOCUS: 'onFocus',
  ON_BLUR: 'onBlur',
  ON_DOUBLE_CLICK: 'onDoubleClick',
  ENTER_PRESSED: 'enterPressed',
  ESC_PRESSED: 'escPressed',

  /**
   * Search Hits Events
   */
  HIT_ITEM_CLICKED: 'hiItemClicked',
  HIT_ITEM_ACTIVATED: 'hitItemActivated',
  HIT_ITEM_READ_BOOK: 'hitItemReadBook',
  HIT_SEPARATOR_CLICKED: 'hitSeparatorClicked',
  ACTIVE_HIT_ITEM_MOUNTED: 'activeHitItemMounted',
  HIT_ITEM_MOUSE_OUT: 'hitItemMouseOut',
  HIT_ITEM_MOUSE_DOWN: 'hitItemMouseDown',
  HIT_ITEM_MOUSE_UP: 'hitItemMouseUp',
  HIT_ITEM_TOUCH_START: 'hitItemTouchStart',
  HIT_ITEM_TOUCH_END: 'hitItemTouchEnd',
  HIT_ITEM_TOUCH_HOLD: 'hitItemTouchHold',

  /**
   * Search Hits Events
   */
  PARAGRAPH_MOUNTED: 'paragraphMounted',

  /**
   * Navigation Events
   */
  NAVIGATE_BACK: 'navigateBack',
  NAVIGATE_FORWARD: 'navigateForward',

  /**
   * Titles Events
   */
  OPEN_PUBLICATION: 'openPublication',

  /**
   * Search Widget Events
   */
  MAIN_POPUP_OPENED: 'mainPopupOpened',
  MAIN_POPUP_CLOSED: 'mainPopupClosed',
  GET_DOC_BY_DOC_ID: 'getDocByDocId',

  /**
   * Search Filter Item Events
   */
  FILTER_ITEM_MOUNTED: 'filterItemMounted',
  FILTER_ITEM_BEFORE_DESTROY: 'filterItemBeforeDestroy',
};
