import PublicationLocator from './locator/publication-locator';
import PublicationStartLocator from './locator/publication-start-locator';
import PublicationEndLocator from './locator/publication-end-locator';
import ParagraphLocator from './locator/paragraph-locator';
import ParagraphIndexedLocator from './locator/paragraph-indexed-locator';
import InTextLocator from './locator/in-text-locator';
import RangeLocator from './locator/range-locator';
import InTextRangeLocator from './locator/in-text-range-locator';

const RANGE_SEPARATOR = '--';
const INDEX_SEPARATOR = '_';
const OFFSET_SEPARATOR = '.';

/** Augment base class using factory methods */
PublicationLocator.prototype.serialize = function() {
  return serializeLocator(this);
};

PublicationLocator.prototype.toJSON = PublicationLocator.prototype.serialize;

PublicationLocator.prototype.toString = function() {
  return this.constructor.name + '[' + this.serialize() + ']';
};

/** Locator (exported namespace) **/
const Locator = {
  PublicationLocator: PublicationLocator,
  PublicationStartLocator: PublicationStartLocator,
  PublicationEndLocator: PublicationEndLocator,

  ParagraphLocator: ParagraphLocator,
  ParagraphIndexedLocator: ParagraphIndexedLocator,
  InTextLocator: InTextLocator,

  RangeLocator: RangeLocator,
  InTextRangeLocator: InTextRangeLocator,
};

Locator.serialize = serializeLocator;
Locator.deserialize = deserializeLocator;
Locator.getRangeDifference = getRangeDifference;

function serializeLocator(locator) {
  switch (locator.constructor) {
    case PublicationLocator:
      throw new Error('Attempt to serialize PublicationLocator');
    case PublicationStartLocator:
      return '';
    case PublicationEndLocator:
      return '-';

    case ParagraphLocator:
      return _serializeParagraphLocator(locator);
    case ParagraphIndexedLocator:
      return (
        _serializeParagraphLocator(locator) + INDEX_SEPARATOR + locator.index
      );
    case InTextLocator:
      return (
        _serializeParagraphLocator(locator) +
        OFFSET_SEPARATOR +
        locator.logicalCharOffset
      );

    case InTextRangeLocator:
      if (locator.startLocator.equalsByBasis(locator.endLocator)) {
        return (
          serializeLocator(locator.startLocator) +
          OFFSET_SEPARATOR +
          locator.endLocator.logicalCharOffset
        );
      }
    /* falls through */
    case RangeLocator:
      return (
        serializeLocator(locator.startLocator) +
        RANGE_SEPARATOR +
        serializeLocator(locator.endLocator)
      );
    default:
      throw new Error('Attempt to serialize unknown Locator');
  }
}
/**
 * Create a specific Locator instance from its serialization
 *
 * @param {string} str
 * @returns {PublicationLocator}
 */
function deserializeLocator(str) {
  let separatorIndex, lastSeparatorIndex, paragraphId, startLocator, endLocator;
  if (str === '') {
    return new PublicationStartLocator();
  }
  if (str === '-') {
    return new PublicationEndLocator();
  }

  if (str[0] === '"') {
    str = str.replace(/^"(.*)"$/, '$1'); // antm: TODO remove this after Sep. 1st, this was added to avoid FFA-1200
  }
  // '--' check
  if (-1 !== (separatorIndex = str.indexOf(RANGE_SEPARATOR))) {
    startLocator = deserializeLocator(str.slice(0, separatorIndex));
    endLocator = deserializeLocator(
      str.slice(separatorIndex + RANGE_SEPARATOR.length)
    );

    return _createRangeLocator(startLocator, endLocator);
  }

  // '_' check
  if (-1 !== (separatorIndex = str.indexOf(INDEX_SEPARATOR))) {
    paragraphId = str.slice(0, separatorIndex);
    return new ParagraphIndexedLocator(
      paragraphId,
      str.slice(separatorIndex + INDEX_SEPARATOR.length)
    );
  }

  // '.' check
  if (-1 !== (separatorIndex = str.indexOf(OFFSET_SEPARATOR))) {
    lastSeparatorIndex = str.lastIndexOf(OFFSET_SEPARATOR);
    if (separatorIndex === lastSeparatorIndex) {
      return new InTextLocator(
        str.slice(0, separatorIndex),
        str.slice(separatorIndex + OFFSET_SEPARATOR.length)
      );
    }
    paragraphId = str.slice(0, separatorIndex);
    startLocator = new InTextLocator(
      paragraphId,
      str.slice(separatorIndex + OFFSET_SEPARATOR.length, lastSeparatorIndex)
    );
    endLocator = new InTextLocator(
      paragraphId,
      str.slice(lastSeparatorIndex + OFFSET_SEPARATOR.length)
    );
    return new InTextRangeLocator(startLocator, endLocator);
  }

  return new ParagraphLocator(str);
}
/**
 *
 * @param {RangeLocator} a
 * @param {RangeLocator} b
 */
function getRangeDifference(a, b) {
  if (
    !(
      a.endLocator.follows(b.startLocator) &&
      b.endLocator.follows(a.startLocator)
    )
  ) {
    return [[a], [b]];
  }

  const aDiff = [],
    bDiff = [];
  const starts = a.startLocator.compareTo(b.startLocator);
  if (starts < 0) {
    aDiff.push(_createRangeLocator(a.startLocator, b.startLocator));
  } else if (starts > 0) {
    bDiff.push(_createRangeLocator(b.startLocator, a.startLocator));
  }

  const ends = a.endLocator.compareTo(b.endLocator);
  if (ends < 0) {
    bDiff.push(_createRangeLocator(a.endLocator, b.endLocator));
  } else if (ends > 0) {
    aDiff.push(_createRangeLocator(b.endLocator, a.endLocator));
  }

  if (aDiff.length === 0 && bDiff.length === 0) {
    return null;
  }
  return [aDiff, bDiff];
}
/**
 *
 * @param {PublicationLocator} start
 * @param {PublicationLocator} end
 * @returns {RangeLocator|InTextRangeLocator}
 * @private
 */
function _createRangeLocator(start, end) {
  if (
    start.constructor === InTextLocator &&
    end.constructor === InTextLocator
  ) {
    return new InTextRangeLocator(start, end);
  }
  return new RangeLocator(start, end);
}
/**
 *
 * @param {ParagraphLocator} locator
 * @returns {string}
 * @private
 */
function _serializeParagraphLocator(locator) {
  return '' + locator._paragraphNumber + locator._paragraphSuffix;
}

export default Locator;
