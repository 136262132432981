<template>
  <div class="additional-title-content">
    <BaseSwitcher
      class="additional-title-control"
      label="Show book"
      :is-checked="isMetaBlockOn"
      @change="switcherHandlerMetaBlock"
    />
    <div
      class="back-button"
      @click="switcherHandler(SwitcherEnum.MID_POSITION)"
    >
      <BaseIcon class="ico-arrow" icon-name="ico-arrow-front" />
      <span>{{ $tc('BottomBar.BackButton.label') }}</span>
    </div>
    <div
      v-if="shouldShowExternalLabel"
      class="segment-title-control -external"
      @click="openPublication"
    >
      <span>{{ $tc('BottomBar.SegmentTitleControl.ExternalLabel') }}</span>
      <BaseIcon class="ico-arrow" icon-name="ico-external-link" />
    </div>
    <div
      v-if="shouldShowInternalLabel"
      class="segment-title-control -internal"
      @click="openPublication"
    >
      <span>{{ $tc('BottomBar.SegmentTitleControl.InternalLabel') }}</span>
      <BaseIcon class="ico-arrow" icon-name="ico-arrow-front" />
    </div>
    <Navigation data-test="navigation" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import BaseIcon from '@/components/BaseIcon.vue';
  import BaseSwitcher from '@/components/BaseSwitcher.vue';
  import Navigation from '@/containers/navigation/Navigation.vue';
  import PublicationVisibilityStatuses from '@/enums/PublicationVisibilityStatusesEnum.js';
  import SwitcherEnum from '@/enums/SwitcherEnum.js';
  import { SUPPLEMENTAL } from '@/constants/constants';

  export default {
    name: 'ResultsAdditionalTitle',
    components: {
      BaseIcon,
      Navigation,
      BaseSwitcher,
    },
    data() {
      return {
        SwitcherEnum,
      };
    },
    computed: {
      ...mapState('SwHitsStore', ['activeHit']),
      ...mapState('SwPublicationsStore', ['activePublicationOpenLink']),
      ...mapState('SwContextStore', ['isMetaBlockOn']),

      isCopyrightVisible() {
        return (
          this.activeHit?.bookMeta?.accessStatus ===
          PublicationVisibilityStatuses.ENCUMBERED
        );
      },

      shouldShowExternalLabel() {
        return (
          (this.isSupplemental || this.isCopyrightVisible) &&
          this.activePublicationOpenLink &&
          this.isValidSupplUrl
        );
      },
      shouldShowInternalLabel() {
        return (
          !this.isSupplemental &&
          !this.isCopyrightVisible &&
          this.activePublicationOpenLink
        );
      },

      isSupplemental() {
        return this.activeHit?.bookMeta?.type === SUPPLEMENTAL;
      },

      isValidSupplUrl() {
        const bookMeta = this.activeHit?.bookMeta || {};
        const validSourceUrl = /^https?/i.test(bookMeta?.sourceUrl || '');
        return this.isSupplemental && validSourceUrl;
      },
    },

    methods: {
      switcherHandler(paraName) {
        this.$store.dispatch('SwContextStore/setSegmentedSwitcher', paraName);
      },
      openPublication() {
        this.$store.dispatch(
          'SwPublicationsStore/openPublication',
          this.activeHit
        );
      },
      switcherHandlerMetaBlock(paraName) {
        this.$store.dispatch('SwContextStore/setSwitchMetaBlock', paraName);
      },
    },
  };
</script>

<style lang="less">
  @import '../../assets/styles/variables.less';

  .additional-title-content {
    .segment-title-control {
      display: none;
    }

    .additional-title-control {
      @media @big, @sw-normal {
        display: none !important;
      }
      .switch-container {
        @media screen and (max-width: 319px) {
          display: none;
        }
      }
    }
  }
</style>
