<template>
  <BaseIcon
    :icon-name="iconProps.iconName"
    :viewBox="iconProps.viewBox"
    :class="iconProps.class"
    :width="iconProps.width"
    :height="iconProps.height"
  />
</template>

<script>
  import BaseIcon from '@/components/BaseIcon.vue';

  export default {
    name: 'BookIcon',
    components: {
      BaseIcon,
    },
    props: {
      publicationType: String,
    },
    computed: {
      iconProps() {
        return {
          iconName: 'pic-default-cover',
          width: 32,
          height: 42,
          viewBox: '0 0 360 472',
          class: 'pic-default-cover',
        };
      },
    },
  };
</script>
