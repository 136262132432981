<template>
  <div
    class="-segment"
    :class="{ '-collapsed': segment.showButton, [segment.selector]: true }"
    :style="{ width: segmentWidth }"
  >
    <div class="segment-title">
      <div class="segment-title-text" @click="toggleBlock(segment.name)">
        <BaseIcon viewBox="0 0 24 24" icon-name="ico-title-icon" />
        <span>{{ segment.name }}</span>
      </div>
      <slot name="additional-title-content"></slot>
    </div>
    <slot class="expanded-content"></slot>
    <div
      class="collapsed-content"
      dir="ltr"
      @click="toggleBlock(segment.selector)"
    >
      <div class="collapsed-label">{{ segment.name }}</div>
    </div>
    <div
      class="resizer"
      :disabled="segment.resizerDisabled"
      @mousedown="initResize"
      v-vue-touch:start="initResize"
      :data-segment-name="segment.name"
    ></div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/BaseIcon.vue';
  import * as log from 'loglevel';
  log.setLevel('error');

  const EMIT_TOGGLE_BLOCK = 'toggle-block';
  const EMIT_INIT_RESIZE = 'init-resize';

  export default {
    name: 'Segment',
    components: {
      BaseIcon,
    },
    props: {
      segment: {
        type: Object,
        required: true,
      },
    },
    computed: {
      media() {
        return this.$store.getters['SwMediaDetectorStore/getMedia'];
      },
      segmentWidth() {
        return this.media.outIpad ? this.segment.width : '';
      },
    },
    methods: {
      toggleBlock(name) {
        this.$emit(EMIT_TOGGLE_BLOCK, name);
      },
      initResize(e, name) {
        if (!e) {
          log.warn(`event was not provided in initResize method`);
          return;
        }
        const isTouchEvent = e.type === 'touchstart';
        const targetElement = isTouchEvent ? e.touches[0].target : e.target;
        if (!targetElement.classList.contains('resizer')) {
          return;
        }
        this.$emit(EMIT_INIT_RESIZE, e, name);
      },
    },
  };
</script>

<style lang="less">
  @import '../../assets/styles/variables.less';

  .-segment {
    .resizer {
      display: none;
      background: #ccc;
      width: 24px;
      margin-inline-start: -12px;
      height: 100%;
      max-height: 100% !important;
      position: absolute;
      top: 0;
      cursor: col-resize;
      opacity: 0;
      user-select: none;

      @media @big-alt {
        display: block;
      }

      &[disabled] {
        display: none;
      }
    }

    .additional-title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media @sw-narrow {
        flex-grow: 1;
      }
    }
  }

  .toggle-button {
    background-color: #f1f1f1;
    padding: 5px;
    cursor: pointer;
  }
</style>
