<template>
  <div>
    <SearchFilter />
  </div>
</template>

<script>
  import SearchFilter from '@/containers/searchfilter/SearchFilter.vue';
  export default {
    name: 'FilterComponent',
    components: {
      SearchFilter,
    },
  };
</script>

<style></style>
