export default {
  name: 'SwMediaDetectorStore',
  namespaced: true,
  state: {
    common: {
      narrow: false,
      ipad: false,
      normal: false,
      outIpad: false,
      wide: false,
    },
  },
  getters: {
    getMedia: state => {
      return state.common;
    },
  },
  actions: {
    setMedia({ commit }, media) {
      commit('setMedia', media);
    },
  },
  mutations: {
    setMedia(state, media) {
      state.common = { ...state.common, ...media.common };
    },
  },
};
