<template>
  <div class="expanded-content">
    <div class="-no-results">
      <BaseIcon viewBox="0 0 145 144" icon-name="pic-book" />
    </div>
    <div class="search-detailed-content-wrapper">
      <div class="regular-content-wrapper">
        <BookMeta />
        <Paragraphs class="search-result-item-content" />
        <div v-if="isCopyrightVisible" class="copyright-block">
          {{ $tc('CopyRight.label') }}
        </div>
        <div
          v-if="!isCopyrightVisible"
          class="fab-wrapper"
          @click="openPublication"
        >
          <BaseIcon viewBox="0 0 24 24" icon-name="ico-book-new" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import BaseIcon from '@/components/BaseIcon.vue';
  import BookMeta from '@/containers/bookmeta/BookMeta.vue';
  import Paragraphs from '@/containers/paragraphs/Paragraphs.vue';

  import PublicationVisibilityStatuses from '@/enums/PublicationVisibilityStatusesEnum.js';

  export default {
    name: 'ResultsContent',
    components: {
      BaseIcon,
      BookMeta,
      Paragraphs,
    },
    computed: {
      ...mapState('SwHitsStore', ['activeHit']),

      isCopyrightVisible() {
        return (
          this.activeHit?.bookMeta?.accessStatus ===
          PublicationVisibilityStatuses.ENCUMBERED
        );
      },
    },
    methods: {
      openPublication(event) {
        if (event) {
          event.stopPropagation();
        }
        this.$store.dispatch(
          'SwPublicationsStore/openPublication',
          this.activeHit
        );
      },
    },
  };
</script>

<style lang="less" scoped>
  @import '../../assets/styles/variables.less';

  .expanded-content {
    .fab-wrapper {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--sw-primary-color);
      right: 16px;
      bottom: 12px;
      transition: all 0.25s ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      @media @big, @sw-normal {
        display: none;
      }

      &:hover {
        box-shadow: inset 0 0 0 3px var(--sw-primary-color),
          inset 0 0 15px rgba(255, 255, 255, 0.35),
          0 0 3px var(--sw-primary-color);
      }

      svg {
        fill: white;
      }
    }
  }
</style>
