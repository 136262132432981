import get from 'lodash/get';

import FullTextSearchService from '@/services/Search/FullTextSearchService';
import localStorageService from '@/services/LocalStorageService';
import LocalStorageKeyEnum from '@/enums/LocalStorageKeyEnum';
import ParseResponse from '@/services/ElasticSearch/ParseResponse';
import request from '@/services/ElasticSearch/Request';

import languageUtils from '@/utils/languageUtils.js';
import requestUtils from '@/utils/requestUtils.js';

import config from '@/configs/default.config.json';

import * as log from 'loglevel';
import {
  OOL,
  TIMER_FOR_GET_CONFIG_RETRY,
  COUNTER_FOR_GET_CONFIG_ATTEMPTS,
  FOCUS_SELECTOR_PARAGRAPHS,
  FOCUS_SELECTOR_SEARCH_FIELD,
  FOCUS_SELECTOR_HITS,
  FOCUS_FILTER_FIELD,
  FOCUS_FILTER_BLOCK,
} from '@/constants/constants';

log.setLevel('error');
let timer;
let attemptCounter = 0;

const state = _createInitialContextState();

function _createInitialContextState() {
  return {
    parameters: {},
    env: null,
    brand: null,
    searchInfo: null,
    esInfoMap: null,
    esOnline: null,
    spriteUrl: '',
    isPartialAccess: null,
    openedPublicationId: null,
    lang: 'en',
    segmentedSwitcher: '-mid-position',
    mainPopupOpened: false,
    menuOpened: false,
    keyboardOpened: false,
    infoOpened: false,
    focus: {
      [FOCUS_SELECTOR_SEARCH_FIELD]: true,
      [FOCUS_SELECTOR_HITS]: false,
      [FOCUS_FILTER_FIELD]: false,
      [FOCUS_FILTER_BLOCK]: false,
      [FOCUS_SELECTOR_PARAGRAPHS]: false,
    },
    viewMode: config.viewModes.popup,
    searchResultsSegments:
      localStorageService.getDataFromLocalStorage(
        LocalStorageKeyEnum.SEARCH_RESULTS_SEGMENTS
      ) || null,
    isMetaBlockOn: false,
    topBarInitiated: false,
  };
}

const getters = {
  isExtension: state => {
    return state.isExtension;
  },
  getEnv: state => {
    return state.env;
  },
  getBrand: state => {
    return state.brand;
  },
  getEsInfoMap: state => {
    return state.esInfoMap;
  },
  getSearchInfo: state => {
    return state.searchInfo;
  },
  getSupplementalLibConfig: state => {
    return get(state, 'parameters.supplementalLibConfig', {});
  },
  getIndexSummaryUrls: state => {
    return get(state, 'parameters.indexSummaryUrls', {});
  },
  getElasticSearchUrl: state => {
    return get(state, 'parameters.elasticSearchUrl', null);
  },
  getExternalHosts: state => {
    return get(state, 'parameters.externalHosts', null);
  },
  getUrlShortenerDomain: state => {
    return get(state, 'parameters.urlShortenerDomain', null);
  },
  getLang: state => {
    return state.lang;
  },
  getKeyboardOpened: state => {
    return state.keyboardOpened;
  },
  getLocalizedNumber: state => number => {
    return languageUtils.localizeNumber(number, state.lang);
  },
  isParametersInitiated: state => {
    return Object.keys(state.parameters).length > 0;
  },
  getOpenedPublicationId: state => {
    return state.openedPublicationId;
  },
  isPageView: state => {
    return state.viewMode === config.viewModes.page;
  },
  isPopupView: state => {
    return state.viewMode === config.viewModes.popup;
  },
  isSimpleView: state => {
    return state.viewMode === config.viewModes.simple;
  },
  getSearchResultsSegments: state => {
    return state.searchResultsSegments;
  },
  isParagraphsCollapsed: state => {
    return state.searchResultsSegments?.showResultsButton;
  },
  shouldShowFilterCategories: state => {
    return state.brand !== OOL;
  },
  isMetaBlockOn: state => {
    return state.isMetaBlockOn;
  },
};

const actions = {
  async initSearch({ commit }, store) {
    try {
      const initSearchResponse = await initFullTextSearchService(store);

      const esInfoMap = initSearchResponse?.esInfoMap;
      if (!Object.keys(esInfoMap).length) {
        commit('setEsOnline', false);
        throw new Error('Elasticsearch is unavailable');
      }

      const searchInfo = initSearchResponse.searchInfo;
      if (!searchInfo) {
        throw new Error('Search info was not found');
      }

      commit('setSearchInfo', searchInfo);
      commit('setEsInfoMap', esInfoMap);
      commit('setEsOnline', true);
    } catch (error) {
      log.error(error);
      throw error;
    }
  },
  initConfigParameters({ commit, state }) {
    const parameters = get(config, 'parameters', {});
    commit('setBrand', parameters.brand);

    parameters.elasticSearchUrl = state.elasticSearchUrl;
    commit('setParameters', parameters);
  },
  async initRemoteConfigParameters({ commit, dispatch }, env) {
    try {
      if (!config.parameters.indexSummaryUrls.hasOwnProperty(env)) {
        log.warn(
          `[search-widget] Requested config for unknown environment.
           Please, provide proper environment name in place where search widget defined.`
        );
      }
      const requestFn = getRemoteConfigParameters.bind(
        null,
        config.parameters.indexSummaryUrls[env]
      );
      const remoteConfig = await requestUtils.retryRequest(requestFn);
      dispatch('SwErrorStore/setIsErrorOccurred', false, { root: true });
      commit('setParameters', remoteConfig);
      return Promise.resolve();
    } catch (error) {
      log.error(
        '[search-widget] Set config action failed with error: ' + error
      );
      if (
        error.response &&
        (error.response.status === 500 || error.response.status === 404)
      ) {
        dispatch('SwErrorStore/setIsErrorOccurred', true, { root: true });
        if (attemptCounter < COUNTER_FOR_GET_CONFIG_ATTEMPTS) {
          getConfigureParameters(dispatch, env);
        } else {
          dispatch('SwErrorStore/setStopRetry', true, { root: true });
        }
      }
      return Promise.reject();
    }
  },
  setPartialAccess({ commit }, isPartialAccess) {
    commit('setPartialAccess', isPartialAccess);
  },
  setOpenedPublicationId({ commit }, openedPublicationId) {
    commit('setOpenedPublicationId', openedPublicationId);
  },
  setEnv({ commit }, env) {
    commit('setEnv', env);
  },
  setBrand({ commit }, brand) {
    commit('setBrand', brand);
  },
  setIsExtension({ commit }, isExtension) {
    commit('setIsExtension', isExtension);
  },
  setLang({ commit, dispatch, state }, lang) {
    if (state.lang === undefined || state.lang === lang) {
      return;
    }

    const supportedLangs = config.languages;
    if (!supportedLangs.includes(lang)) {
      lang = config.defaultLanguage;
    }

    if (state.keyboardOpened && lang === config.defaultLanguages.en) {
      dispatch('setKeyboardOpened', false);
    }
    dispatch('SwSearchStore/totalReset', true, { root: true });
    dispatch('setMenuOpened', false);
    commit('setLang', lang);
  },
  setSegmentedSwitcher({ commit }, segmentedSwitcher) {
    commit('setSegmentedSwitcher', segmentedSwitcher);
  },
  setMainPopupOpened({ commit, dispatch }, isOpened) {
    commit('setMainPopupOpened', isOpened);

    if (isOpened) {
      dispatch('SwSearchStore/getLastSearchText', null, { root: true });
      dispatch('SwHitsStore/getLastActiveHit', null, { root: true });
      dispatch('SwFilterStore/getLastFilterText', null, { root: true });
    }
  },
  setMenuOpened({ commit }, isOpened) {
    commit('setMenuOpened', isOpened);
  },
  setKeyboardOpened({ commit }, isKeyboardOpened) {
    commit('setKeyboardOpened', isKeyboardOpened);
  },
  setInfoOpened({ commit }, isInfoOpened) {
    commit('setInfoOpened', isInfoOpened);
  },
  changeFocus({ commit, state }, options) {
    const focus = { ...state.focus };
    if (options.selector) {
      commit('SwKeyboardStore/setLastFocusedField', options.selector, {
        root: true,
      });
    }

    options.selector
      ? Object.keys(focus).forEach(
          key => (focus[key] = key === options.selector)
        )
      : Object.keys(focus).some(setFocusByDirection);

    commit('changeFocus', focus);

    function setFocusByDirection(key, index, array) {
      if (key && focus[key]) {
        focus[key] = false;

        const nextElement = array[index + options.direction];
        if (nextElement) {
          focus[nextElement] = true;
        } else {
          const i = index === array?.length - 1 ? 0 : array?.length - 1;
          focus[array[i]] = true;
        }
        return true;
      }
    }
  },
  setViewMode({ commit }, viewMode) {
    viewMode = viewMode || config.viewModes.popup;
    commit('setViewMode', viewMode);
  },
  setSearchResultsSegments({ commit }, searchResultsSegments) {
    commit('setSearchResultsSegments', searchResultsSegments);
  },
  segmentScrollUp() {},
  segmentScrollDown() {},
  setSwitchMetaBlock({ commit }, isMetaBlockOn) {
    commit('setSwitchMetaBlock', isMetaBlockOn);
  },
};

const mutations = {
  setSpriteUrl(state, url) {
    state.spriteUrl = url;
  },
  setParameters(state, parameters) {
    state.parameters = { ...parameters, ...state.parameters };
  },
  setEnv(state, env) {
    state.env = env;
  },
  setBrand(state, brand) {
    state.brand = brand;
  },
  setSearchInfo(state, searchInfo) {
    state.searchInfo = searchInfo;
  },
  setEsInfoMap(state, esInfoMap) {
    state.esInfoMap = esInfoMap;
  },
  setEsOnline(state, esOnline) {
    state.esOnline = esOnline;
  },
  setPartialAccess(state, isPartialAccess) {
    state.isPartialAccess = isPartialAccess;
  },
  setOpenedPublicationId(state, openedPublicationId) {
    state.openedPublicationId = openedPublicationId;
  },
  setIsExtension(state, isExtension) {
    state.isExtension = isExtension;
  },
  setLang(state, lang) {
    state.lang = lang;
  },
  setSegmentedSwitcher(state, segmentedSwitcher) {
    state.segmentedSwitcher = segmentedSwitcher;
  },
  setMainPopupOpened(state, isMainPopupOpened) {
    state.mainPopupOpened = isMainPopupOpened;
  },
  setMenuOpened(state, isMenuOpened) {
    state.menuOpened = isMenuOpened;
  },
  setKeyboardOpened(state, isKeyboardOpened) {
    state.keyboardOpened = isKeyboardOpened;
  },
  setInfoOpened(state, isInfoOpened) {
    state.infoOpened = isInfoOpened;
  },
  changeFocus(state, focus) {
    state.focus = focus;
  },
  setViewMode(state, viewMode) {
    state.viewMode = viewMode;
  },
  setSearchResultsSegments(state, searchResultsSegments) {
    if (!searchResultsSegments) {
      return;
    }
    state.searchResultsSegments = searchResultsSegments;
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_RESULTS_SEGMENTS,
      searchResultsSegments
    );
  },
  setSwitchMetaBlock(state, isMetaBlockOn) {
    state.isMetaBlockOn = isMetaBlockOn;
  },
  setTopBarInitiated(state, topBarInitiated) {
    state.topBarInitiated = topBarInitiated;
  },
};

async function initFullTextSearchService(store) {
  const responses = await FullTextSearchService.init(store);
  return ParseResponse.parseInitSearchResponse({ responses });
}

async function getRemoteConfigParameters(indexSummaryUrls) {
  const response = await request.request(
    indexSummaryUrls,
    'get',
    `searchWidgetConfig`
  );
  return ParseResponse.parseRemoteConfigResponse({ response });
}

function getConfigureParameters(dispatch, env) {
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
  timer = setTimeout(() => {
    dispatch('initRemoteConfigParameters', env);
    attemptCounter++;
  }, TIMER_FOR_GET_CONFIG_RETRY);
}

export default {
  name: 'SwContextStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
