<template>
  <div ref="bottomBar" class="search-results-block">
    <Info v-if="isSearchTipsOpened" />
    <div class="search-results-wrapper">
      <div class="search-results-content-block" :class="segmentedSwitcher">
        <Segment
          v-for="(segment, index) in updatedSegments"
          :class="{ '-pointer-events-none': segmentPointerNone }"
          :key="index"
          :segment="segment"
          @toggle-block="toggleBlock(segment.name)"
          @init-resize="initResize($event, segment.name)"
        >
          <template v-slot:additional-title-content>
            <component :is="segment.titleComponent" />
          </template>
          <component :is="segment.component" class="expanded-content" />
        </Segment>
      </div>
      <div class="search-results-controls-block">
        <div class="search-results-controls-wrapper" :class="segmentedSwitcher">
          <div class="active-bg"></div>
          <BaseButton
            v-for="(item, index) in switcherControls"
            :class="{
              active: item.paraName === segmentedSwitcher,
            }"
            :key="index"
            @click="switcherHandler(item.paraName)"
          >
            <span>{{ item.name }}</span>
          </BaseButton>
        </div>
      </div>
    </div>

    <Keyboard v-if="isKeyboardVisible" />
  </div>
</template>

<script>
  import { focus } from 'vue-focus';
  import { mapState } from 'vuex';

  import config from '@/configs/default.config.json';

  import {
    SPACE_SCROLL_UP,
    SPACE_SCROLL_DOWN,
    PORTUGUESE,
  } from '@/constants/constants';

  import SwitcherEnum from '@/enums/SwitcherEnum.js';

  import Segment from '@/containers/segment/Segment.vue';
  import HitsContent from '@/containers/hitsContent/HitsContent.vue';
  import HitsAdditionalTitle from '@/containers/hitsContent/HitsAdditionalTitle.vue';
  import ResultsContent from '@/containers/resultsContent/ResultsContent.vue';
  import ResultsAdditionalTitle from '@/containers/resultsContent/ResultsAdditionalTitle.vue';
  import FilterContent from '@/containers/filterContent/FilterContent.vue';

  import Info from '@/containers/info/Info.vue';
  import BaseButton from '@/components/BaseButton.vue';
  import Keyboard from '@/containers/keyboard/Keyboard.vue';

  import eventManager from '@/services/EventService';
  import i18n from '@/i18n';

  const MIN_BLOCK_WIDTH = 340;
  const MIN_BLOCK_WIDTH_VISIBLE = 350;
  const COLLAPSED_BLOCK_WIDTH = 72;
  const WRAPPER_BLOCK_NAME = 'search-results-content-block';
  const START_SECTION_NAME = 'filter-block';
  const MIDDLE_SECTION_NAME = 'hits-block';
  const END_SECTION_NAME = 'results-block';
  const START_LABEL = i18n.t('BottomBar.SwitcherPosition.StartLabel');
  const MIDDLE_LABEL = i18n.t('BottomBar.SwitcherPosition.MiddleLabel');
  const END_LABEL = i18n.t('BottomBar.SwitcherPosition.EndLabel');
  const DEFAULT_SEGMENT_WIDTH = 100 / 3 + '%';
  const THRESHOLD_SCROLL_TOLERANCE = 10;

  class SegmentData {
    constructor(data) {
      this.name = data.name;
      this.selector = data.selector;
      this.component = data.component;
      this.titleComponent = data.titleComponent || null;
      this.width = 0;
      this.showButton = false;
      this.resizerDisabled = false;
    }

    setWidth(value) {
      this.width = value;
      return this;
    }
    setShowButton(value) {
      this.showButton = !!value;
      return this;
    }
    setResizerDisabled(value) {
      this.resizerDisabled = !!value;
      return this;
    }
  }

  const segmentsArray = [
    new SegmentData({
      name: MIDDLE_LABEL,
      selector: MIDDLE_SECTION_NAME,
      component: HitsContent,
      titleComponent: HitsAdditionalTitle,
    }),
    new SegmentData({
      name: END_LABEL,
      selector: END_SECTION_NAME,
      component: ResultsContent,
      titleComponent: ResultsAdditionalTitle,
    }),
    new SegmentData({
      name: START_LABEL,
      selector: START_SECTION_NAME,
      component: FilterContent,
      titleComponent: HitsAdditionalTitle,
    }),
  ];

  export default {
    name: 'BottomBar',
    components: {
      Segment,
      Info,
      Keyboard,
      BaseButton,
    },
    directives: { focus: focus },
    data() {
      return {
        switcherControls: [
          {
            name: START_LABEL,
            paraName: SwitcherEnum.START_POSITION,
          },
          {
            name: MIDDLE_LABEL,
            paraName: SwitcherEnum.MID_POSITION,
          },
          {
            name: END_LABEL,
            paraName: SwitcherEnum.END_POSITION,
          },
        ],

        parentWidth: null,
        hitsWidth: null,
        resultsWidth: null,
        filterWidth: null,
        showHitsButton: false,
        showResultsButton: false,
        showFilterButton: false,
        currentBlock: null,
        nextBlock: null,
        altBlock: null,
        wrapperBlock: null,
        startBlock: null,
        middleBlock: null,
        endBlock: null,
        startX: 0,
        startWidth: 0,
        nextBlockStartWidth: 0,
        altBlockStartWidth: 0,
        segments: segmentsArray,
        segmentPointerNone: false,
        resizeObserver: null,
        touchStartY: 0,
      };
    },
    computed: {
      ...mapState('SwContextStore', [
        'lang',
        'keyboardOpened',
        'infoOpened',
        'mainPopupOpened',
        'segmentedSwitcher',
      ]),
      ...mapState('SwHitsStore', ['activeHit']),
      ...mapState('SwFilterStore', ['filterOpened']),
      ...mapState('SwSearchStore', ['searchInProcess', 'foundNothing']),

      updatedSegments() {
        return this.segments.map(segment => {
          switch (segment.name) {
            case MIDDLE_LABEL:
              segment
                .setWidth(this.getHitsSegmentWidth)
                .setShowButton(this.showHitsButton)
                .setResizerDisabled(
                  this.showFilterButton || this.showHitsButton
                );
              break;
            case END_LABEL:
              segment
                .setWidth(this.getResultsSegmentWidth)
                .setShowButton(this.showResultsButton)
                .setResizerDisabled(
                  this.showResultsButton || this.showHitsButton
                );
              break;
            case START_LABEL:
              segment
                .setWidth(this.getFilterSegmentWidth)
                .setShowButton(this.showFilterButton);
              break;
          }
          return segment;
        });
      },

      isKeyboardVisible() {
        return (
          this.keyboardOpened &&
          (this.isRtlLanguage() || this.lang === PORTUGUESE)
        );
      },

      isSearchTipsOpened() {
        return this.infoOpened;
      },

      media() {
        return this.$store.getters['SwMediaDetectorStore/getMedia'];
      },

      getSearchResultsSegments() {
        return this.$store.getters['SwContextStore/getSearchResultsSegments'];
      },
      getHitsSegmentWidth() {
        return this.hitsWidth ? this.hitsWidth + 'px' : DEFAULT_SEGMENT_WIDTH;
      },
      getResultsSegmentWidth() {
        return this.resultsWidth
          ? this.resultsWidth + 'px'
          : DEFAULT_SEGMENT_WIDTH;
      },
      getFilterSegmentWidth() {
        return this.filterWidth
          ? this.filterWidth + 'px'
          : DEFAULT_SEGMENT_WIDTH;
      },
    },

    watch: {
      media(newVal) {
        if (
          newVal.ipad &&
          this.segmentedSwitcher === SwitcherEnum.MID_POSITION
        ) {
          this.$store.dispatch(
            'SwContextStore/setSegmentedSwitcher',
            SwitcherEnum.END_POSITION
          );
        }

        if (newVal.ipad || newVal.narrow) {
          this.middleBlock.style.width = '';
          this.endBlock.style.width = '';
          this.startBlock.style.width = '';
          this.showHitsButton = false;
          this.showResultsButton = false;
          this.showFilterButton = false;
        } else {
          const searchResultsSegments = this.getSearchResultsSegments;
          this.setSegmentsParameters(searchResultsSegments);

          this.middleBlock.style.width = this.hitsWidth + 'px';
          this.endBlock.style.width = this.resultsWidth + 'px';
          this.startBlock.style.width = this.filterWidth + 'px';
        }
      },
    },

    mounted() {
      eventManager.subscribe(SPACE_SCROLL_UP, () => this.$_triggerScroll(-1));
      eventManager.subscribe(SPACE_SCROLL_DOWN, () => this.$_triggerScroll(1));
      this.$refs.bottomBar.addEventListener(
        'wheel',
        event => {
          this.wheelEventAction(event);
        },
        { passive: true }
      );
      this.$refs.bottomBar.addEventListener(
        'touchstart',
        event => {
          this.touchstartEventAction(event);
        },
        { passive: true }
      );
      this.$refs.bottomBar.addEventListener(
        'touchmove',
        event => {
          this.touchmoveEventAction(event);
        },
        { passive: true }
      );

      const searchResultsSegments = this.getSearchResultsSegments;
      this.setSegmentsParameters(searchResultsSegments);

      this.wrapperBlock = this.$el.querySelector(`.${WRAPPER_BLOCK_NAME}`);
      this.startBlock = this.$el.querySelector(`.${START_SECTION_NAME}`);
      this.middleBlock = this.$el.querySelector(`.${MIDDLE_SECTION_NAME}`);
      this.endBlock = this.$el.querySelector(`.${END_SECTION_NAME}`);

      this.blocks = {
        [MIDDLE_LABEL]: {
          current: this.middleBlock,
          next: this.startBlock,
          alt: this.endBlock,
        },
        [END_LABEL]: {
          current: this.endBlock,
          next: this.middleBlock,
          alt: this.startBlock,
        },
        [START_LABEL]: {
          current: this.startBlock,
          next: this.middleBlock,
          alt: this.endBlock,
        },
      };

      this.resizeObserver = new ResizeObserver(entries => {
        if (this.media.outIpad) {
          const containerWidth = entries[0].contentRect.width;
          this.adjustColumnWidths(containerWidth);
        }
      });
      this.resizeObserver.observe(this.wrapperBlock);
    },

    beforeDestroy() {
      eventManager.unsubscribe(SPACE_SCROLL_UP);
      eventManager.unsubscribe(SPACE_SCROLL_DOWN);
      this.$refs.bottomBar.removeEventListener('wheel', event => {
        this.wheelEventAction(event);
      });
      this.$refs.bottomBar.removeEventListener('touchstart', event => {
        this.touchstartEventAction(event);
      });
      this.$refs.bottomBar.removeEventListener('touchmove', event => {
        this.touchmoveEventAction(event);
      });
      this.resizeObserver.disconnect();
    },

    methods: {
      isRtlLanguage() {
        return config.rtlLangs.indexOf(this.lang) !== -1;
      },

      $_triggerScroll(direction) {
        const wrapper = this.$el.querySelectorAll('.search-results-wrapper');
        const container = wrapper[0].querySelector('.virtual-list:focus');

        if (container) {
          container.scrollTop += (container.clientHeight / 3) * direction;
        }
      },

      switcherHandler(paraName) {
        this.$store.dispatch('SwContextStore/setSegmentedSwitcher', paraName);
      },

      openPublication() {
        this.$store.dispatch(
          'SwPublicationsStore/openPublication',
          this.activeHit
        );
      },

      setSegmentsParameters(segments) {
        if (!segments) {
          return;
        }
        this.hitsWidth = segments.hitsWidth || this.hitsWidth;
        this.resultsWidth = segments.resultsWidth || this.resultsWidth;
        this.filterWidth = segments.filterWidth || this.filterWidth;
        this.showHitsButton = segments.showHitsButton || this.showHitsButton;
        this.showResultsButton =
          segments.showResultsButton || this.showResultsButton;
        this.showFilterButton =
          segments.showFilterButton || this.showFilterButton;
      },

      setBlocksWidth() {
        this.parentWidth = this.wrapperBlock.offsetWidth;
        this.hitsWidth = this.middleBlock.offsetWidth;
        this.resultsWidth = this.endBlock.offsetWidth;
        this.filterWidth = this.startBlock.offsetWidth;
      },

      initResize(event) {
        const block = event.target.dataset.segmentName;
        this.currentBlock = this.blocks[block]?.current;
        this.nextBlock = this.blocks[block]?.next;

        this.startX = this.getStartX(event);
        this.startWidth = this.currentBlock.offsetWidth;
        this.nextBlockStartWidth = this.nextBlock
          ? this.nextBlock.offsetWidth
          : COLLAPSED_BLOCK_WIDTH;

        document.addEventListener('mousemove', this.resize);
        document.addEventListener('mouseup', this.stopResize);
        document.addEventListener('touchmove', this.resize);
        document.addEventListener('touchend', this.stopResize);
      },

      resize(event) {
        this.setBlocksWidth();
        this.segmentPointerNone = true;
        const dx = this.getDx(event);

        if (dx > this.parentWidth - COLLAPSED_BLOCK_WIDTH * 2) {
          return;
        }

        const newWidth = this.startWidth + dx;
        const nextBlockNewWidth = this.nextBlock
          ? this.nextBlockStartWidth - dx
          : COLLAPSED_BLOCK_WIDTH;

        const blockResize = (block, width) => {
          if (width < MIN_BLOCK_WIDTH) {
            block.style.width = `${COLLAPSED_BLOCK_WIDTH}px`;
            this.toggleButtonForBlock(block, true);
            return false;
          }
          block.style.width = width + 'px';
          this.toggleButtonForBlock(block, false);
          return true;
        };

        if (!blockResize(this.currentBlock, newWidth)) {
          this.nextBlock.style.width =
            nextBlockNewWidth + newWidth - COLLAPSED_BLOCK_WIDTH + 'px';
          this.setSearchResultsSegments();
          return;
        }

        if (!blockResize(this.nextBlock, nextBlockNewWidth)) {
          this.currentBlock.style.width =
            nextBlockNewWidth - COLLAPSED_BLOCK_WIDTH + newWidth + 'px';
        }
        this.setSearchResultsSegments();
      },

      getDx(event) {
        if ('touches' in event) {
          return this.isRtlLanguage()
            ? event.touches[0].pageX - this.startX
            : this.startX - event.touches[0].pageX;
        }

        return this.isRtlLanguage()
          ? event.pageX - this.startX
          : this.startX - event.pageX;
      },

      getStartX(event) {
        return 'touches' in event ? event.touches[0].pageX : event.pageX;
      },

      stopResize() {
        document.removeEventListener('mousemove', this.resize);
        document.removeEventListener('mouseup', this.stopResize);
        document.removeEventListener('touchmove', this.resize);
        document.removeEventListener('touchend', this.stopResize);
        this.segmentPointerNone = false;
      },

      toggleBlock(block) {
        this.setBlocksWidth();
        let result;
        switch (block) {
          case MIDDLE_LABEL:
            result = this.toggleWidth({
              currentButtonVisibility: this.showHitsButton,
              nextButtonVisibility: this.showResultsButton,
              altButtonVisibility: this.showFilterButton,
              currentBlockWidth: this.hitsWidth,
              nextBlockWidth: this.resultsWidth,
              altNextBlockWidth: this.filterWidth,
            });
            this.hitsWidth = result.currentBlockWidth;
            this.resultsWidth = result.nextBlockWidth;
            this.filterWidth = result.altNextBlockWidth;
            this.showHitsButton = result.currentButtonVisibility;
            this.showResultsButton = result.nextButtonVisibility;
            this.showFilterButton = result.altButtonVisibility;
            break;

          case END_LABEL:
            result = this.toggleWidth({
              currentButtonVisibility: this.showResultsButton,
              nextButtonVisibility: this.showHitsButton,
              altButtonVisibility: this.showFilterButton,
              currentBlockWidth: this.resultsWidth,
              nextBlockWidth: this.hitsWidth,
              altNextBlockWidth: this.filterWidth,
            });
            this.resultsWidth = result.currentBlockWidth;
            this.hitsWidth = result.nextBlockWidth;
            this.filterWidth = result.altNextBlockWidth;
            this.showResultsButton = result.currentButtonVisibility;
            this.showHitsButton = result.nextButtonVisibility;
            this.showFilterButton = result.altButtonVisibility;
            break;

          case START_LABEL:
            result = this.toggleWidth({
              currentButtonVisibility: this.showFilterButton,
              nextButtonVisibility: this.showHitsButton,
              altButtonVisibility: this.showResultsButton,
              currentBlockWidth: this.filterWidth,
              nextBlockWidth: this.hitsWidth,
              altNextBlockWidth: this.resultsWidth,
            });
            this.filterWidth = result.currentBlockWidth;
            this.hitsWidth = result.nextBlockWidth;
            this.resultsWidth = result.altNextBlockWidth;
            this.showFilterButton = result.currentButtonVisibility;
            this.showHitsButton = result.nextButtonVisibility;
            this.showResultsButton = result.altButtonVisibility;
            break;

          default:
            break;
        }
        this.setSearchResultsSegments();
      },

      toggleWidth({
        currentButtonVisibility,
        nextButtonVisibility,
        altButtonVisibility,
        currentBlockWidth,
        nextBlockWidth,
        altNextBlockWidth,
      }) {
        if (!currentButtonVisibility) {
          const result = this.transferWidths({
            nextBlockWidth,
            altNextBlockWidth,
            nextButtonVisibility,
            altButtonVisibility,
            currentBlockWidth,
          });

          return {
            currentBlockWidth: COLLAPSED_BLOCK_WIDTH,
            altNextBlockWidth: result.altNextBlockWidth,
            nextBlockWidth: result.nextBlockWidth,
            currentButtonVisibility: true,
            nextButtonVisibility: result.nextButtonVisibility,
            altButtonVisibility: result.altButtonVisibility,
          };
        }

        if (nextBlockWidth < MIN_BLOCK_WIDTH) {
          altNextBlockWidth =
            altNextBlockWidth -
            (MIN_BLOCK_WIDTH_VISIBLE - COLLAPSED_BLOCK_WIDTH);
        } else if (
          nextBlockWidth - MIN_BLOCK_WIDTH_VISIBLE <
          MIN_BLOCK_WIDTH_VISIBLE
        ) {
          nextBlockWidth = altNextBlockWidth =
            (this.parentWidth - MIN_BLOCK_WIDTH_VISIBLE) / 2;
        } else {
          nextBlockWidth =
            nextBlockWidth - (MIN_BLOCK_WIDTH_VISIBLE - COLLAPSED_BLOCK_WIDTH);
        }

        return {
          currentBlockWidth: MIN_BLOCK_WIDTH_VISIBLE,
          nextBlockWidth,
          altNextBlockWidth,
          currentButtonVisibility: false,
          nextButtonVisibility,
          altButtonVisibility,
        };
      },

      transferWidths({
        nextBlockWidth,
        altNextBlockWidth,
        nextButtonVisibility,
        altButtonVisibility,
        currentBlockWidth,
      }) {
        if (
          nextBlockWidth === COLLAPSED_BLOCK_WIDTH &&
          altNextBlockWidth === COLLAPSED_BLOCK_WIDTH
        ) {
          nextBlockWidth += currentBlockWidth - COLLAPSED_BLOCK_WIDTH;
          nextButtonVisibility = false;
        } else if (nextBlockWidth === COLLAPSED_BLOCK_WIDTH) {
          altNextBlockWidth += currentBlockWidth - COLLAPSED_BLOCK_WIDTH;
        } else {
          nextBlockWidth += currentBlockWidth - COLLAPSED_BLOCK_WIDTH;
        }

        return {
          nextBlockWidth,
          altNextBlockWidth,
          nextButtonVisibility,
          altButtonVisibility,
        };
      },

      toggleButtonForBlock(block, show) {
        if (block.classList.contains(MIDDLE_SECTION_NAME)) {
          this.showHitsButton = show;
        } else if (block.classList.contains(END_SECTION_NAME)) {
          this.showResultsButton = show;
        } else {
          this.showFilterButton = show;
        }
      },

      adjustColumnWidths(wrapperWidth) {
        const { hitsWidth, resultsWidth, filterWidth } = this;
        const totalWidth = hitsWidth + resultsWidth + filterWidth;

        if (
          wrapperWidth === 0 ||
          hitsWidth === null ||
          resultsWidth === null ||
          filterWidth === null ||
          totalWidth === wrapperWidth
        ) {
          return;
        }
        const widths = {
          hitsWidth,
          resultsWidth,
          filterWidth,
        };
        const sortedBlocks = Object.keys(widths).sort(
          (a, b) => widths[a] - widths[b]
        );
        const smallestWidthSum =
          widths[sortedBlocks[0]] + widths[sortedBlocks[1]];
        const largestBlock = sortedBlocks[2];
        const newLargeBlockWidth = wrapperWidth - smallestWidthSum;

        if (totalWidth < wrapperWidth) {
          this[largestBlock] = newLargeBlockWidth;
        } else {
          this[largestBlock] = Math.max(MIN_BLOCK_WIDTH, newLargeBlockWidth);
        }

        this.setSearchResultsSegments();
      },

      setSearchResultsSegments() {
        this.$store.dispatch('SwContextStore/setSearchResultsSegments', {
          hitsWidth: this.hitsWidth,
          resultsWidth: this.resultsWidth,
          filterWidth: this.filterWidth,
          showHitsButton: this.showHitsButton,
          showResultsButton: this.showResultsButton,
          showFilterButton: this.showFilterButton,
        });
      },
      wheelEventAction(event) {
        if (Math.abs(event.deltaY) > THRESHOLD_SCROLL_TOLERANCE) {
          const isScrollingDown = event.deltaY > 0;
          const action = isScrollingDown
            ? 'segmentScrollDown'
            : 'segmentScrollUp';
          this.$store.dispatch(`SwContextStore/${action}`);
        }
      },
      touchstartEventAction(event) {
        this.touchStartY = event.touches[0].clientY;
      },
      touchmoveEventAction(event) {
        const touchEndY = event.changedTouches[0].clientY;
        if (
          Math.abs(this.touchStartY - touchEndY) > THRESHOLD_SCROLL_TOLERANCE
        ) {
          const isScrollingDown = this.touchStartY > touchEndY;
          const action = isScrollingDown
            ? 'segmentScrollDown'
            : 'segmentScrollUp';
          this.$store.dispatch(`SwContextStore/${action}`);
        }
      },
    },
  };
</script>

<style lang="less">
  @import 'BottomBar.less';
</style>
