import Vue from 'vue';
import VueI18n from 'vue-i18n';
import pluginChecker from '@/utils/pluginChecker';
import il8nConfig from '@/i18n.config';

let _VueI18n = pluginChecker.getPluginIfInstalled('VueI18n');
const isInsideNuxt = pluginChecker.isInsideNuxt();

if (isInsideNuxt || !_VueI18n) {
  _VueI18n = VueI18n;
  Vue.use(VueI18n);
}

const i18n = new _VueI18n(il8nConfig);

export default i18n;
