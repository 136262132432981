class SelectionContext {
  constructor(data = {}) {
    this.text = data.text || '';
    this.link = data.link || '';
    this.author = data.author || '';
    this.title = data.title || '';
    this.startLocator = data.startLocator || null;
    this.endLocator = data.endLocator || null;
    this.publicationId = data.publicationId || null;
    this.id = data.id || null;
  }

  get context() {
    return `${this.text}\n\n\n${this.author}, ${this.title}, ${this.paragraphsRange}\n${this.link}`;
  }

  get paragraphsRange() {
    const startParaId = this.startLocator?.startLocator?._paragraphNumber;
    const endParaId = this.endLocator?.endLocator?._paragraphNumber;
    let paragraphsRange = '';
    if (startParaId && endParaId) {
      paragraphsRange =
        startParaId === endParaId
          ? `${startParaId}`
          : `${startParaId}-${endParaId}`;
    } else {
      paragraphsRange = startParaId ?? endParaId ?? '';
    }
    return paragraphsRange;
  }

  setText(text) {
    this.text = (text ?? '').trim();
    return this;
  }

  setLink(link) {
    this.link = link ?? '';
    return this;
  }

  setAuthor(author) {
    this.author = author ?? '';
    return this;
  }

  setTitle(title) {
    this.title = title ?? '';
    return this;
  }

  setStartLocator(locator) {
    this.startLocator = locator ?? null;
    return this;
  }

  setEndLocator(locator) {
    if (
      this.startLocator?.startLocator?._paragraphNumber >
      locator?.endLocator?._paragraphNumber
    ) {
      this.endLocator = this.startLocator;
      this.setStartLocator(locator);
      return this;
    }
    this.endLocator = locator ?? null;
    return this;
  }

  setPublicationId(publicationId) {
    this.publicationId = publicationId ?? null;
    return this;
  }

  setDocId(id) {
    this.id = id ?? null;
    return this;
  }
}

export { SelectionContext };
