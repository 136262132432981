import get from 'lodash/get';

import elasticSearch from '@/services/ElasticSearch/ElasticSearch';
import FullTextSearchFactory from '@/classes/factories/queries/FullTextSearchFactory';
import parseResponse from '@/services/ElasticSearch/ParseResponse';

import * as log from 'loglevel';
log.setLevel('info');

const DEFAULT_NAVIGATION_LIST = [];
const DEFAULT_NAVIGATION_INDEX = 0;
const DEFAULT_TOTAL_DOCS_COUNT = 0;

const state = _createInitialNavigationState();

function _createInitialNavigationState() {
  return {
    navigationList: DEFAULT_NAVIGATION_LIST,
    navigationIndex: DEFAULT_NAVIGATION_INDEX,
    totalDocsCount: DEFAULT_TOTAL_DOCS_COUNT,
  };
}

const getters = {
  getNavigationIndex: state => {
    return state.navigationIndex;
  },

  getTotalDocsCount: state => {
    return state.totalDocsCount;
  },

  getNavigationList: state => {
    return state.navigationList;
  },
};

const actions = {
  fillNavigationStore({ commit, state }, navigationList) {
    commit('setNavigationList', navigationList);
    commit(
      'setNavigationIndex',
      state.navigationIndex || DEFAULT_NAVIGATION_INDEX
    );
    commit('setTotalDocsCount', navigationList);
  },

  resetNavigationStore({ commit }) {
    commit('setNavigationList');
    commit('setNavigationIndex');
    commit('setTotalDocsCount');
  },

  setNavigationIndex({ state, commit }, activeHitId) {
    const navigationIndex = state.navigationList.findIndex(hit => {
      return hit.docId === activeHitId;
    });
    commit('setNavigationIndex', navigationIndex);
  },

  setHitByNavigationIndex({ commit, dispatch, state }, index) {
    commit('setNavigationIndex', index);

    const navigationId = get(state, `navigationList[${index}].docId`, null);
    dispatch('SwHitsStore/setActiveHitById', navigationId, { root: true });
  },

  async performNavigationSearch({ dispatch }, activeHit) {
    const navigationListSearchResponse = await dispatch(
      'getNavigation',
      activeHit
    );
    dispatch('fillNavigationStore', navigationListSearchResponse);
  },

  /**
   * @returns {Promise}
   */
  getNavigation({ rootGetters }, payload) {
    try {
      const bookMeta = get(payload, 'bookMeta', {});
      const options = {
        parsedQuery: rootGetters['SwSearchStore/getParsedQuery'],
        language: rootGetters['SwContextStore/getLang'],
        publicationId: bookMeta.publicationId,
        itemsCount: bookMeta.totalResults,
      };
      return getNavigation(options);
    } catch (error) {
      throw new Error();
    }
  },
};

const mutations = {
  setNavigationIndex(state, index) {
    state.navigationIndex = index > 0 ? index : 0;
  },

  setTotalDocsCount(state, navigationList) {
    state.totalDocsCount = navigationList
      ? Object.values(navigationList).length
      : DEFAULT_TOTAL_DOCS_COUNT;
  },

  setNavigationList(state, navigationList) {
    state.navigationList = navigationList || DEFAULT_NAVIGATION_LIST;
  },
};

async function getNavigation(options) {
  try {
    const query = FullTextSearchFactory.createNavigationQueryParams(options);
    const response = await elasticSearch.getNavigateList(query);
    return parseResponse.parseNavigationResponse({
      response,
    });
  } catch (error) {
    log.info(`getNavigationList failed with ${error}`);
    return parseResponse.parseNavigationResponse({ response: [] });
  }
}

export default {
  name: 'SwNavigationStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
