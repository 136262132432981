const getParagraphNumberFromParaId = paraId => {
  if (!paraId || typeof paraId !== 'string') {
    return null;
  }
  return paraId.match(/para_(.*)/)[1];
};

function generateShortUuid() {
  return 'xxxyyy-xyyxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default {
  getParagraphNumberFromParaId,
  generateShortUuid,
};
