import { PARAGRAPH_SELECTOR } from '../../../../constants/constants';

const META_MARKER = 'data-meta';
import Locator from '../locator';
import {
  SCROLL_SELECTOR,
  PARAGRAPH_CONTENT_SELECTOR,
} from '@/constants/constants';

/**
 *
 * @param {Element} element
 * @param {string} selector
 * @returns {boolean}
 * @private
 */
export let _is = (element, selector) => {
  _is =
    'matches' in element
      ? (element, selector) => element.matches(selector)
      : (element, selector) => element.msMatchesSelector(selector);

  return _is(element, selector);
};

const MarkerUtils = {
  getParagraphElementsInRange: function getParagraphElementsInRange(
    startParagraph,
    endParagraph
  ) {
    const paragraphElements = [];
    let currentElement = startParagraph;
    // assertion block
    while (currentElement !== endParagraph) {
      if (this.isContent(currentElement)) {
        paragraphElements.push(currentElement);
      }
      currentElement = this._getParagraphByDirection(currentElement, false);
      if (currentElement === null) {
        throw new Error(
          'Failed to collect paragraphs in range:' +
            ' startParagraph [' +
            startParagraph.id +
            '],' +
            ' endParagraph [' +
            endParagraph.id +
            ']'
        );
      }
    }
    paragraphElements.push(endParagraph);
    return paragraphElements;
  },

  /**
   *
   * @param {Element} element
   * @returns {boolean}
   */
  isContent: function isContent(element) {
    return _is(element, MarkerUtils.getContentElementSelector());
  },

  /**
   *
   * @returns {string}
   */
  getContentElementSelector: function getContentElementSelector() {
    return PARAGRAPH_CONTENT_SELECTOR;
  },

  getParagraphSelector: function getParagraphSelector() {
    return PARAGRAPH_SELECTOR;
  },

  getParagraphById: function getParagraphById(id, paragraphContainer) {
    if (paragraphContainer === undefined) {
      paragraphContainer = window.document.documentElement;
    }
    if (id.indexOf('para_') === 0) {
      throw new Error('Attempt to use prefixed id ' + id);
    }
    return paragraphContainer.querySelector('[data-sw-id=para_' + id + ']');
  },

  _getParagraphByDirection: function _getParagraphByDirection(
    paragraph,
    isDesc
  ) {
    const paragraphs = document.querySelectorAll('[data-sw-id^=para_]');
    const i = Array.prototype.findIndex.call(
      paragraphs,
      a => a.id === paragraph.id
    );

    return paragraphs[i + (isDesc ? -1 : 1)] || null; // if we on last para and try move forward or if we on first para and try move back
  },
  /**
   *
   * @param {Element} element
   * @returns {boolean}
   */
  isMeta: function isMeta(element) {
    return _is(element, MarkerUtils.getMetaElementSelector());
  },

  /**
   *
   * @returns {string}
   */
  getMetaElementSelector: function getMetaElementSelector() {
    return '[' + META_MARKER + ']';
  },

  getBlockIdByLocator: function getBlockIdByLocator(locator) {
    const para = this.getElementByLocator(locator);
    const blockId = para.getAttribute('data-ilmid');
    return blockId || null;
  },

  /**
   *
   * @param {ParagraphLocator|PublicationStartLocator} locator
   * @param {Element} [elementContainer]
   * @returns {?Element}
   */
  getElementByLocator: function getElementByLocator(locator, elementContainer) {
    if (locator instanceof Locator.ParagraphLocator) {
      return this.getParagraphById(locator.paragraphId, elementContainer);
    } else if (locator instanceof Locator.PublicationStartLocator) {
      return this.getPublicationHeaderElement();
    }
    throw new Error(
      'Cannot fetch element by non-paragraph locator: [' +
        locator.toJSON() +
        ']'
    );
  },

  /**
   *
   * @param {Element} [publicationContainer]
   * @returns {Element}
   */
  getPublicationHeaderElement: function getPublicationHeaderElement(
    publicationContainer
  ) {
    publicationContainer =
      publicationContainer || window.document.documentElement;
    const publicationHeaderElement = publicationContainer.querySelector(
      this.getPublicationHeaderSelector()
    );
    return publicationHeaderElement;
  },

  /**
   *
   * @returns {string}
   */
  getPublicationHeaderSelector: function getPublicationHeaderSelector() {
    return '.book-info-box';
  },

  /**
   *
   * @param {Element} paragraphElement
   * @returns {string}
   */
  getParagraphId: function getParagraphId(paragraphElement) {
    if (paragraphElement.id) {
      return paragraphElement.id.slice('para_'.length);
    } else {
      const paraIdAttribute = paragraphElement.getAttribute('data-sw-id');
      return paraIdAttribute?.slice('para_'.length);
    }
  },

  /**
   *
   * @param {Element} element
   * @returns {T[]}
   */
  getMetaElements: function getMetaElements(element) {
    const metaElementsNodeList = element.querySelectorAll(
      MarkerUtils.getMetaElementSelector()
    );
    return Array.prototype.slice.call(metaElementsNodeList);
  },

  getCoordinatesFromEvent: function(event) {
    const preparedEvent = _prepareEvent(event);
    return {
      clientX: preparedEvent.clientX,
      clientY: preparedEvent.clientY,
    };
  },

  /**
   *
   * @param {Element} paragraphContainer
   * @returns {Array.<Element>}
   */
  getParagraphElements: function getParagraphElements(paragraphContainer) {
    const paragraphsVirtualScroll = paragraphContainer.querySelectorAll(
      SCROLL_SELECTOR
    )[0];
    const paragraphsNodeList = paragraphsVirtualScroll.querySelectorAll(
      MarkerUtils.getParagraphSelector()
    );
    return Array.prototype.slice.call(paragraphsNodeList);
  },
  getParaByRangeLocator: function getParaByRangeLocator(locator) {
    if (!locator || !locator.endLocator) {
      return null;
    }
    return window.document.querySelector(
      '[data-sw-id="' + locator.endLocator.prefixedParagraphId + '"]'
    );
  },
  /**
   * @param  {InTextLocator} locator
   */
  getParagraphIdsInRange: function getParagraphIdsInRange(locator) {
    const paragraphIds = [];
    let currentParagraphId = +locator.startLocator.paragraphId;
    const lastParagraphId = +locator.endLocator.paragraphId;
    while (currentParagraphId <= lastParagraphId) {
      paragraphIds.push('para_' + currentParagraphId);
      currentParagraphId = +currentParagraphId + 1;
    }
    return paragraphIds;
  },
};

function _prepareEvent(event) {
  if ('originalEvent' in event) {
    event = event.originalEvent;
  }

  if ('pointers' in event) {
    event = event.pointers[0];
  }

  if (
    event.targetTouches &&
    (event.targetTouches.length || event.changedTouches.length) >= 1
  ) {
    event = event.targetTouches[0] || event.changedTouches[0];
  }

  return event;
}
export default MarkerUtils;
