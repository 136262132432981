import PublicationLocator from './publication-locator';

const paragraphIdPattern = /^\D*(\d+)(\D*)$/;
/**
 * Locator for a paragraph of Publication
 *
 * @constructor
 * @extends {PublicationLocator}
 * @param {string} paragraphId
 */
class ParagraphLocator extends PublicationLocator {
  constructor(paragraphId) {
    super();
    const matches = paragraphIdPattern.exec(paragraphId);
    if (matches === null) {
      throw new Error(
        'Paragraph ID (' +
          paragraphId +
          ') does not match pattern ' +
          paragraphIdPattern.source
      );
    }

    this._paragraphNumber = +matches[1];
    this._paragraphSuffix = matches[2];
    this.paragraphId = this._paragraphNumber + this._paragraphSuffix;
    this.prefixedParagraphId = 'para_' + this.paragraphId;
  }
  /**
   * @override
   *
   * @param {ParagraphLocator|PublicationLocator} locator
   * @returns {number}
   */
  compareTo(locator) {
    return locator.constructor === ParagraphLocator
      ? this._compareParagraphs(this, locator)
      : -locator.compareTo(this) || 0;
  }

  /**
   * @override
   *
   * @param {ParagraphLocator|PublicationLocator} locator
   * @returns {number}
   */
  compareBasisTo(locator) {
    return locator instanceof ParagraphLocator
      ? this._compareParagraphs(this, locator)
      : -locator.compareBasisTo(this) || 0;
  }

  /**
   *
   * @param {ParagraphLocator} a
   * @param {ParagraphLocator} b
   * @returns {number}
   * @private
   */
  _compareParagraphs(a, b) {
    return (
      a._paragraphNumber - b._paragraphNumber ||
      a._paragraphSuffix.localeCompare(b._paragraphSuffix)
    );
  }
}

export default ParagraphLocator;
