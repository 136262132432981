import { render, staticRenderFns } from "./ParagraphItem.vue?vue&type=template&id=9d092558&scoped=true"
import script from "./ParagraphItem.vue?vue&type=script&lang=js"
export * from "./ParagraphItem.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "9d092558",
  null
  ,true
)

export default component.exports