const MOBILE_MAX_TOUCH_POINTS_BORDER = 2;

const OsEnum = {
  Windows: {
    and: [
      { or: [/\bWindows|(Win\d\d)\b/, /\bWin 9x\b/] },
      { not: /\bWindows Phone\b/ },
    ],
  },
  iOS: { or: [/\biPad\b/, /\biPhone\b/, /\biPod\b/] },
  OSX: /\bMac OS\b/,
  Android: /\bAndroid\b/,
  Linux: /\bLinux\b/,
  Other: /./,
};

function _detectIsIpad() {
  const detectedOS = _detector(OsEnum);
  const isIpad = detectedOS === 'OSX' && !!_isTouchDevice();
  return isIpad;
}

function _detector(_enum) {
  return Object.keys(_enum).reduce(function(memo, key) {
    return !memo && test(navigator.userAgent, _enum[key]) ? key : memo;
  }, '');
}
function _isTouchDevice() {
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > MOBILE_MAX_TOUCH_POINTS_BORDER
  );
}

function test(string, regex) {
  if (regex instanceof RegExp) {
    return regex.test(string);
  } else if (regex && Array.isArray(regex.and)) {
    return regex.and.every(_subTest);
  } else if (regex && Array.isArray(regex.or)) {
    return regex.or.some(_subTest);
  } else if (regex?.not) {
    return !test(string, regex.not);
  } else if (_isFunction(regex)) {
    return regex(window);
  } else {
    return false;
  }

  function _subTest(item) {
    return test(string, item);
  }
}

function _isFunction(fn) {
  return fn && {}.toString.call(fn) === '[object Function]';
}

const layoutUtils = {
  isMobile: () => {
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) || _detectIsIpad()
    );
  },
};

export default layoutUtils;
