var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'is-popup-displayed': _vm.mainPopupOpened,
    'rtl-lang': _vm.isTextRightSided,
    'dev-env': _vm.isDev,
    'brand-ocean': _vm.brand === 'ocean',
    'simple-view': _vm.isSimpleView,
  },attrs:{"dir":_vm.getDirection}},[(_vm.mainPopupOpened)?_c('MediaDetector'):_vm._e(),(!_vm.spriteUrl)?_c('div',{staticClass:"icons-sprite"},[_c('BaseIconsSprite')],1):_vm._e(),_c('div',{attrs:{"id":"nav"}}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }