<template>
  <div class="base-loader">
    <div class="loader"></div>
  </div>
</template>

<script>
  export default {
    name: 'BaseLoader',
  };
</script>

<style lang="less">
  .base-loader {
    height: 4px;
    animation: baseLoader 5s linear;
    animation-delay: 1s;
    animation-iteration-count: 1;

    @keyframes baseLoader {
      0% {
        background: #f1f5f9;
      }

      100% {
        background: #f1f5f9;
      }
    }

    @keyframes loader {
      0% {
        width: 0%;
      }
      50% {
        width: 50%;
      }
      100% {
        width: 100%;
      }
    }

    .loader {
      background: var(--sw-primary-color);
      height: 4px;
      width: 0;
      animation: loader 5s normal forwards ease-in-out;
      animation-delay: 1s;
      animation-iteration-count: 1;
    }
  }
</style>
