import get from 'lodash/get';

import HitsFactory from '@/classes/factories/HitsFactory';
import FullTextSearchFactory from '@/classes/factories/queries/FullTextSearchFactory';

import FullTextSearchService from '@/services/Search/FullTextSearchService';
import ParseResponse from '@/services/ElasticSearch/ParseResponse';
import localStorageService from '@/services/LocalStorageService';
import provider from '@/provider';

import LocalStorageKeyEnum from '@/enums/LocalStorageKeyEnum';

import { NUMBER_HITS_GROUPES, REGULAR_HIT_TYPE } from '@/constants/constants';

import * as log from 'loglevel';
log.setLevel('error');

const DEFAULT_HITS = [];
const DEFAULT_ACTIVE_HIT = HitsFactory.createHitItem();
const DEFAULT_LOAD_IN_PROCESS = false;
const DEFAULT_START_INDEX = 0;
const DEFAULT_HITS_UPLOADS_COUNT = 1;

const state = _createInitialSearchHitsState();

function _createInitialSearchHitsState() {
  return {
    uploadsCount: 0,
    hits: DEFAULT_HITS,
    hitsUploadsCount: DEFAULT_HITS_UPLOADS_COUNT,
    activeHit: DEFAULT_ACTIVE_HIT,
    loadHitsInProcess: DEFAULT_LOAD_IN_PROCESS,
    loadHitsByPublicationInProcess: DEFAULT_LOAD_IN_PROCESS,
    expandedPublicationId: null,
  };
}

const getTotalLoadedGroups = hitsUploadsCount => {
  const extraHitsGroupCount = 1;
  return hitsUploadsCount * (NUMBER_HITS_GROUPES - extraHitsGroupCount);
};

const getters = {
  getHits: state => {
    return state.hits;
  },

  getActiveHit: state => {
    return state.activeHit;
  },

  isAllHitsUploaded: (state, getters, rootState) => {
    const totalPublications = rootState.SwSearchStore.totalPublications;
    const totalLoadedGroups = getTotalLoadedGroups(state.hitsUploadsCount);
    return totalPublications <= totalLoadedGroups;
  },

  isAllHitsByExpandedPublicationLoaded: (state, getters) => {
    const expandedPublicationId = getters.getExpandedPublicationId;
    const hits = getters.getHits;
    let total = 0;
    const hitsByPublication = hits.filter(hit => {
      total = hit.bookMeta?.totalResults || 0;
      return hit.publicationId === expandedPublicationId;
    });
    return hitsByPublication.length === total;
  },

  isPublicationExpanded: state => publicationId => {
    return state.expandedPublicationId === publicationId;
  },

  getExpandedPublicationId: state => {
    return state.expandedPublicationId;
  },

  getExpandedPublicationData: (state, getters) => {
    const expandedPublicationId = getters.getExpandedPublicationId;
    const hits = getters.getHits;
    return hits.find(hit => hit.publicationId === expandedPublicationId);
  },
};

const actions = {
  fillHitsStore({ commit, dispatch, state }, hitsSearchResponse) {
    commit('setHits', hitsSearchResponse);
    dispatch('setActiveHit', state.hits[0]);
    dispatch('setLastActiveHit', state.hits[0]);
    commit('setLoadHitsInProcess');
    commit('setLoadHitsByPublicationInProcess');
  },

  resetHitsStore({ commit }) {
    commit('setHits');
    commit('setActiveHit');
    commit('setLoadHitsInProcess');
    commit('setLoadHitsByPublicationInProcess');
    commit('setHitsUploadsCount');
    commit('setExpandedPublicationId');
  },

  async performSearchMoreHits({ commit, dispatch, state }) {
    try {
      commit('setLoadHitsInProcess', true);
      const startIndex = getTotalLoadedGroups(state.hitsUploadsCount) - 1;
      const hitsSearchResponse = await dispatch('getHits', { startIndex });
      commit('setMoreHits', hitsSearchResponse);
      commit('setHitsUploadsCount', state.hitsUploadsCount + 1);
    } finally {
      commit('setLoadHitsInProcess', false);
    }
  },

  async performSearchMoreHitsByPublication(
    { dispatch, commit, getters },
    publicationId
  ) {
    try {
      commit('setLoadHitsByPublicationInProcess', true);

      const hits = getters.getHits;

      const hitsData = hits.reduce(
        (acc, hit) => {
          if (
            hit.type === REGULAR_HIT_TYPE &&
            hit.publicationId === publicationId
          ) {
            if (!acc.totalResults) {
              acc.totalResults = hit.bookMeta?.totalResults;
            }
            acc.count++;
          }
          return acc;
        },
        { count: 0, totalResults: 0 }
      );
      const hitsSearchResponse = await dispatch('getHits', {
        publicationId: publicationId,
        startIndex: hitsData.count,
        collapsed: false,
      });
      commit('setMoreHitsByPublication', {
        hits: hitsSearchResponse,
        publicationId: publicationId,
        totalResults: hitsData.totalResults,
      });
    } finally {
      commit('setLoadHitsByPublicationInProcess', false);
    }
  },

  /**
   * @returns {Promise}
   */
  async getHits({ rootGetters }, payload) {
    const { startIndex, publicationId, collapsed = true } = { ...payload };

    const language = rootGetters['SwContextStore/getLang'];
    const parsedQuery = rootGetters['SwSearchStore/getParsedQuery'];

    const hitsSearchOptions = {
      language,
      startIndex: startIndex || DEFAULT_START_INDEX,
      parsedQuery,
      publicationId,
      collapsed,
    };
    return await getHits(hitsSearchOptions);
  },

  /**
   * @returns {Promise}
   */
  getHitById({ rootGetters }, docId) {
    try {
      const options = {
        docId,
        language: rootGetters['SwContextStore/getLang'],
      };
      return getHitById(options);
    } catch (error) {
      log.error(`getHitById failed with ${error}`);
    }
  },

  async setActiveHit({ commit, dispatch, state }, activeHit) {
    const isNewActiveHit = state.activeHit?.id !== activeHit?.id;
    const isNewCluster =
      state.activeHit?.publicationId !== activeHit?.publicationId;

    commit('setActiveHit', activeHit);

    if (!activeHit.coverPath) {
      const coverPath =
        (await provider.getCoverPath(activeHit?.publicationId)) || '';
      commit('setActiveHitCoverPath', coverPath);
    }
    dispatch('setLastActiveHit', activeHit);

    if (isNewCluster) {
      await dispatch('SwNavigationStore/performNavigationSearch', activeHit, {
        root: true,
      });
    }
    dispatch('SwNavigationStore/setNavigationIndex', activeHit.id, {
      root: true,
    });
    await dispatch(
      'SwPublicationsStore/setActivePublicationOpenLink',
      activeHit,
      { root: true }
    );

    if (isNewActiveHit) {
      await dispatch('SwParagraphsStore/performParagraphsSearch', activeHit, {
        root: true,
      });
    }
  },

  async setActiveHitById({ dispatch, commit, state }, docId) {
    let hit = await dispatch('getHitById', docId);

    if (hit) {
      hit.bookMeta = state.activeHit.bookMeta;
      hit = HitsFactory.createHitItem(hit);
      commit('setActiveHit', hit);
      if (!hit.coverPath) {
        const coverPath =
          (await provider.getCoverPath(hit?.publicationId)) || '';
        commit('setActiveHitCoverPath', coverPath);
      }
      dispatch('setLastActiveHit', hit);

      await dispatch('SwParagraphsStore/performParagraphsSearch', hit, {
        root: true,
      });
      dispatch('SwNavigationStore/setNavigationIndex', hit.id, {
        root: true,
      });
    }
  },

  getLastActiveHit({ commit }) {
    const lastActiveHit = localStorageService.getDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_ACTIVE_HIT
    );
    commit('setActiveHit', lastActiveHit);
  },

  setLastActiveHit({ state }, hit) {
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_ACTIVE_HIT,
      hit || state.activeHit
    );
  },

  removeLastActiveHit() {
    localStorageService.removeDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_ACTIVE_HIT
    );
  },

  resetHitState() {},

  toggleExpandedPublication({ commit, getters }, publicationId) {
    const isPublicationExpanded = getters.isPublicationExpanded(publicationId);
    const pubId = isPublicationExpanded ? null : publicationId;
    commit('setExpandedPublicationId', pubId);
  },

  setHitCollapsed({ commit }, { hit, collapsed }) {
    commit('setHitCollapsed', { hit, collapsed });
  },
};

const mutations = {
  setHits(state, hitsSearchResponse) {
    state.hits = hitsSearchResponse
      ? prepareHits(hitsSearchResponse)
      : DEFAULT_HITS;
  },

  setActiveHit(state, activeHit) {
    state.activeHit = activeHit || DEFAULT_ACTIVE_HIT;
  },

  setHitCollapsed(state, { hit, collapsed }) {
    state.hits.find(h => h.id === hit?.id)?.setCollapsed(collapsed);
  },

  setMoreHits(state, hitsSearchResponse) {
    const hits = prepareHits(hitsSearchResponse);
    const uniqueHits = hits.reduce((acc, hit) => {
      const isUnique =
        state.hits.findIndex(
          uHit =>
            uHit.publicationId === hit.publicationId && uHit.type === hit.type
        ) === -1;
      if (isUnique) {
        acc.push(hit);
      }
      return acc;
    }, []);
    state.hits = [...state.hits, ...uniqueHits];
  },

  setMoreHitsByPublication(state, payload) {
    let { hits } = payload;
    const { publicationId, totalResults } = payload;
    hits = get(hits, 'sentencesList.rows', DEFAULT_HITS);
    const stateHits = [...state.hits];
    hits.forEach(hit => {
      hit.totalResults = hit.totalResults || totalResults;

      if (stateHits.indexOf(hit.docId) !== -1) {
        return;
      }
      const lastHitIndexByPub = state.hits.findLastIndex(
        hit => hit.publicationId === publicationId
      );
      state.hits.splice(lastHitIndexByPub, 0, HitsFactory.createHitItem(hit));
    });
  },

  setLoadHitsInProcess(state, loadHitsInProcess) {
    state.loadHitsInProcess = loadHitsInProcess || DEFAULT_LOAD_IN_PROCESS;
  },

  setLoadHitsByPublicationInProcess(state, loadHitsByPublicationInProcess) {
    state.loadHitsByPublicationInProcess =
      loadHitsByPublicationInProcess || DEFAULT_LOAD_IN_PROCESS;
  },

  setHitsUploadsCount(state, hitsUploadsCount) {
    state.hitsUploadsCount = hitsUploadsCount || DEFAULT_HITS_UPLOADS_COUNT;
  },

  setExpandedPublicationId(state, expandedPublicationId) {
    state.expandedPublicationId = expandedPublicationId || null;
  },

  setActiveHitCoverPath(state, coverPath) {
    state.activeHit.coverPath = coverPath;
  },
};

async function getHits(options) {
  try {
    const query = FullTextSearchFactory.createHitQueryParams(options);
    const response = await FullTextSearchService.getHits(query);
    return ParseResponse.parseHitsResponse({
      response,
      collapsed: options.collapsed,
    });
  } catch (error) {
    throw new Error(`Get hits failed with ${error}`);
  }
}

function getHitById(options) {
  const query = FullTextSearchFactory.createDocByIdQueryParams(options);
  return FullTextSearchService.getDocById(query);
}

function prepareHits(hitsSearchResponse) {
  const hits = get(hitsSearchResponse, 'sentencesList.rows', DEFAULT_HITS);
  return hits.reduce((acc, hit, index) => {
    if (hit.firstGroupItem) {
      acc.push(HitsFactory.createHitItemTopSeparator(hit));
    }

    acc.push(HitsFactory.createHitItem(hit));

    const nextHit = hits[index + 1];
    if (!nextHit || nextHit.publicationId !== hit.publicationId) {
      acc.push(HitsFactory.createHitItemBottomSeparator(hit));
    }
    return acc;
  }, []);
}

export default {
  name: 'SwHitsStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
