<template>
  <div class="tooltip-popup" v-bind:class="[vAlignment, hAlignment, className]">
    <i class="arrow" />
    <div
      class="tooltip-popup-content"
      v-click-outside="clickOutsideHandler"
      :class="TOOLTIP_CLASS_NAME"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  /**
   * props {Object}
   * props.vAlignment {String} - vertical alignment | top, bottom or center
   * props.hAlignment {String} - horizontal alignment | left, right or center
   * props.className {String}
   */
  import ClickOutside from 'vue-click-outside';
  import { TOOLTIP_CLASS_NAME } from '@/constants/constants';

  export default {
    name: 'BaseTooltip',
    directives: {
      ClickOutside,
    },
    props: {
      vAlignment: String,
      hAlignment: String,
      className: String,
    },
    data() {
      return {
        TOOLTIP_CLASS_NAME,
      };
    },
    mounted() {
      const tooltip = this.$el?.querySelector(`.${TOOLTIP_CLASS_NAME}`);
      tooltip?.focus();
    },
    methods: {
      clickOutsideHandler(event) {
        if (event) {
          event.stopPropagation();
        }

        /* start shadow dom workaround */
        const composePath = event.composedPath();
        if (composePath && composePath.length) {
          const isTooltip = composePath.some(el => {
            return (
              typeof el === 'object' &&
              'querySelector' in el &&
              el.querySelector(`:scope > .${TOOLTIP_CLASS_NAME}`)
            );
          });
          if (!isTooltip) {
            this.$emit('baseTooltipEvent', { type: 'clickOutside' });
          }
          return;
        }
        /* end shadow dom workaround */

        const className = event.target.className;
        if (className !== TOOLTIP_CLASS_NAME) {
          this.$emit('baseTooltipEvent', { type: 'clickOutside' });
        }
      },
    },
  };
</script>

<style lang="less">
  @import '../assets/styles/variables.less';

  .tooltip-popup {
    position: relative;
    margin-inline-start: -8px;
    display: flex;
    justify-content: flex-end;
    z-index: 5;
  }

  .tooltip-popup {
    .tooltip-popup-content {
      box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
      background-color: var(--color-main-bg);
      color: var(--color-main-font);
      text-align: center;
      border-radius: 16px;
      position: absolute;
      top: 29px;
      min-width: 220px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 300px;
    }

    i {
      position: absolute;
      bottom: -29px;
      width: 24px;
      height: 12px;
      margin: 0 12px;
      overflow: hidden;
      display: none;

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: var(--color-main-bg);
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
      }
    }

    .icon-container {
      margin-inline-end: 5px;

      svg {
        fill: var(--sw-primary-color);
      }
    }
  }
</style>
