import ParagraphLocator from './paragraph-locator';

/**
 * Locator for a specific block position before or after the specific paragraph of Publication
 *
 * @constructor
 * @extends {ParagraphLocator}
 * @param {string} paragraphId
 * @param {number} index
 *    Negative, if locator points above the paragraph
 *    Positive, if locator points below the paragraph
 */
class InTextLocator extends ParagraphLocator {
  constructor(paragraphId, logicalCharOffset) {
    if (!(logicalCharOffset >= 0)) {
      throw new Error(
        'Logical character offset should be a number, equal or greater than 0'
      );
    }
    super(paragraphId);
    this.logicalCharOffset = +logicalCharOffset;
  }
  /**
   *
   * @param {InTextLocator|PublicationLocator}
   * @returns {number}
   */
  compareTo(locator) {
    let comparisonResult;
    if (locator.constructor === InTextLocator) {
      comparisonResult =
        this.compareBasisTo(locator) ||
        this.logicalCharOffset - locator.logicalCharOffset;
    } else if (locator.constructor === ParagraphLocator) {
      comparisonResult = this.compareBasisTo(locator);
    } else {
      comparisonResult = -locator.compareTo(this) || 0;
    }
    return comparisonResult;
  }
}

export default InTextLocator;
