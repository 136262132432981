<template>
  <div v-if="!!totalDocsCount" v-hotkey="keymap" class="search-result-nav">
    <div dir="auto">
      <span>{{ navigationIndex + 1 }}</span> result of
      <span>{{ totalDocsCount }}</span>
    </div>
    <div class="search-result-controls">
      <div
        class="result-nav-prev result-control"
        :disabled="isBackDisabled"
        @click="navigateBack"
      >
        <span class="icon-container">
          <BaseIcon icon-name="ico-arrow-chevron" />
        </span>
      </div>
      <div
        class="result-nav-next result-control"
        :disabled="isForwardDisabled"
        @click="navigateForward"
      >
        <span class="icon-container">
          <BaseIcon icon-name="ico-arrow-chevron" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import BaseIcon from '@/components/BaseIcon.vue';

  import { NAVIGATION_STEP } from '@/constants/constants';

  import EventEnum from '@/enums/EventEnum';
  import eventManager from '@/services/EventService';

  import * as log from 'loglevel';
  log.setLevel('info');

  export default {
    name: 'Navigation',
    components: {
      BaseIcon,
    },
    computed: {
      ...mapState('SwNavigationStore', [
        'navigationList',
        'navigationIndex',
        'totalDocsCount',
      ]),
      ...mapState('SwHitsStore', ['activeHit']),
      ...mapState('SwContextStore', ['mainPopupOpened']),

      keymap() {
        if (!this.mainPopupOpened) {
          return false;
        }

        return {
          'ctrl+left': () => {
            this.navigateBack();
          },
          'ctrl+right': () => {
            this.navigateForward();
          },
        };
      },

      isBackDisabled() {
        return this.navigationIndex + NAVIGATION_STEP <= 1;
      },

      isForwardDisabled() {
        return this.navigationIndex === this.totalDocsCount - NAVIGATION_STEP;
      },
    },
    mounted() {
      eventManager.subscribe(EventEnum.NAVIGATE_FORWARD, this.navigateForward);
      eventManager.subscribe(EventEnum.NAVIGATE_BACK, this.navigateBack);
    },
    destroyed() {
      eventManager.unsubscribe(
        EventEnum.NAVIGATE_FORWARD,
        this.navigateForward
      );
      eventManager.unsubscribe(EventEnum.NAVIGATE_BACK, this.navigateBack);
    },
    methods: {
      navigateForward() {
        if (this.isForwardDisabled) {
          return;
        }
        this.$_navigate({
          increment: true,
        });
      },

      navigateBack() {
        if (this.isBackDisabled) {
          return;
        }
        this.$_navigate({
          increment: false,
        });
      },

      getLocalizedNumber(number) {
        return this.$store.getters['SwContextStore/getLocalizedNumber'](number);
      },

      $_navigate(options) {
        const activeHitIndex = options.increment
          ? this.navigationIndex + NAVIGATION_STEP
          : this.navigationIndex - NAVIGATION_STEP;
        this.$store.dispatch(
          'SwNavigationStore/setHitByNavigationIndex',
          activeHitIndex
        );
      },
    },
  };
</script>

<style lang="less">
  @import 'Navigation.less';
</style>
