/**
 * Store Config is a dummy Store with real Store structure.
 * It should be used in Unit Testing to set data needed to
 * proper work and testing .vue components, containers, e.t.c.
 * Each module, defined in config, has its own actions and mutations.
 *
 * @type {{namespaced: boolean, state: {}, mutations: {}, actions: {}}}
 */

/**
 * Titles and Headers commit: 68f39451d2a779917e7a15b69a8c972a7305a1c8
 */

import * as searchModule from './modules/search';

import SwNavigationStore from '@/store/modules/NavigationStore';
import SwFilterStore from '@/store/modules/FilterStore';
import SwHitsStore from '@/store/modules/HitsStore';
import SwParagraphsStore from '@/store/modules/ParagraphsStore';
import SwSearchStore from '@/store/modules/SearchStore';
import SwContextStore from '@/store/modules/ContextStore';
import SwOfflineModeStore from './modules/OfflineModeStore';
import SwKeyboardStore from './modules/KeyboardStore';
import SwPublicationsStore from '@/store/modules/PublicationsStore';
import SwMediaDetectorStore from '@/store/modules/MediaDetectorStore';
import SwErrorStore from './modules/ErrorStore';
import SwSelectionContextStore from './modules/SelectionContextStore';

const storeConfig = {
  modules: {
    search: searchModule,

    SwNavigationStore,
    SwFilterStore,
    SwHitsStore,
    SwParagraphsStore,
    SwSearchStore,
    SwContextStore,
    SwOfflineModeStore,
    SwKeyboardStore,
    SwPublicationsStore,
    SwMediaDetectorStore,
    SwErrorStore,
    SwSelectionContextStore,
  },
};

export default storeConfig;
