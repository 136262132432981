<template>
  <div>
    <div class="-no-results">
      <BaseIcon viewBox="0 0 145 144" icon-name="pic-search" />
    </div>
    <div class="search-shortened-content-wrapper">
      <SearchHits />
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/BaseIcon.vue';
  import SearchHits from '@/containers/searchhits/SearchHits.vue';

  export default {
    name: 'HitsContent',
    components: {
      BaseIcon,
      SearchHits,
    },
  };
</script>

<style></style>
