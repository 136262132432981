import axios from 'axios';

import ErrorMessagesEnum from '@/enums/ErrorMessagesEnum';
import eventManager from '../EventService';
import RequestEnum from '@/enums/RequestEnum';
import requestUtils from '@/utils/requestUtils.js';

import * as log from 'loglevel';
log.setLevel('debug');

let elasticReq;
let request;
let store;
const requests = [];

export default {
  /**
   * before start search need get search summary and check elastic search
   * @return {Promise}
   */
  init(_store) {
    store = _store;
    const retryInfo = requestUtils.retryRequest(this.getInfo.bind(this));
    const retryHealthCheck = requestUtils.retryRequest(
      this.healthCheck.bind(this)
    );
    const initPromises = [retryInfo, retryHealthCheck];
    const supplementalLibConfig =
      store.getters['SwContextStore/getSupplementalLibConfig'];
    if (supplementalLibConfig !== null) {
      initPromises.push(this.getSupplementalInfo());
    }
    eventManager.subscribe(
      'cancelPreviousRequest',
      this.cancelPreviousRequests
    );
    eventManager.subscribe(
      'cancelPreviousRequests',
      this.cancelPreviousRequests
    );
    return axios.all(initPromises);
  },

  /**
   * get search summary with content versions
   * @return {Promise}
   */
  getInfo() {
    const env = store.getters['SwContextStore/getEnv'];
    const brand = store.getters['SwContextStore/getBrand'];
    const indexSummaryUrls =
      store.getters['SwContextStore/getIndexSummaryUrls'];
    const serverUrl = indexSummaryUrls[env];

    if (!serverUrl) {
      throw new Error('serverUrl not defined');
    }
    return this.request(serverUrl, 'get', `indexSummary?target=${brand}`);
  },

  /**
   * transport
   * @param  {string} type  is request types get, post, delete, put
   * @param  {string} url
   * @param  {string} path
   * @param  {object} data
   * @param  {boolean} isNonCancelable
   * @return {Promise}
   */
  request(url, type, path, data, requestId, isNonCancelable) {
    if (!isNonCancelable) {
      request = axios.CancelToken.source();
      requests.push(request);
    }

    const apiClient = axios.create({
      baseURL: url,
      withCredentials: false,
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const config = {
      url: `${url}${path}`,
      method: type,
      data: JSON.stringify(data),
    };

    if (!isNonCancelable) {
      config.cancelToken = request?.token;
    }

    return apiClient.request(config).catch(error => {
      if (error.message) {
        log.info(error.message);
      }
      throw error;
    });
  },
  /**
   * check is accessible elastic search
   * @return {Promise}
   */
  healthCheck() {
    return this.elasticRequest('get', '/_cat/indices?format=json', null).then(
      this._createMap
    );
  },
  /**
   * sent request to elastic search server
   * @param  {string} type
   * @param  {string} path
   * @param  {object} data
   * @return {Promise}
   */
  elasticRequest(type, path, data) {
    elasticReq = this.request(this.getUrl(), type, path, data);
    return elasticReq;
  },

  getUrl() {
    const url = store.getters['SwContextStore/getElasticSearchUrl'];
    if (!url) {
      const event = new CustomEvent('searchWidgetError', {
        bubbles: true,
        detail: { error: () => 'elasticSearchUrl is not defined' },
      });
      document.querySelector('#search-popup').dispatchEvent(event);
      throw new Error('elasticSearchUrl is not defined');
    }

    return url;
  },
  /**
   * create map from index info arr
   * @param  {array} esInfo
   * @return {object}
   */
  _createMap(esInfo) {
    const map = {};
    if (esInfo && esInfo.data) {
      esInfo.data.forEach(indexInfo => {
        map[indexInfo.index] = indexInfo;
      });
    }

    return map;
  },

  getSupplementalInfo() {
    return this.supplementalServerRequest(
      'get',
      'search/searchinfo',
      null
    ).catch(function() {
      return Promise.resolve(null);
    });
  },
  /**
   * sent request to application server
   * @param  {string} type
   * @param  {string} path
   * @param  {object} data
   * @return {Promise}
   */
  supplementalServerRequest(type, path, data) {
    const supplementalLibConfig =
      store.getters['SwContextStore/getSupplementalLibConfig'];
    const url = supplementalLibConfig.searchInfoUrl;

    if (!url) {
      log.debug('supplementalUrl not defined');
      return Promise.resolve(null);
    }

    return this.request(url, type, path, data);
  },
  /**
   * sent search request
   * @param  {object} query
   * @param  {string} lang
   * @param  {object} options
   * @return {Promise}
   */
  searchReq(query, lang, options) {
    options = options || {};

    const searchIndexes = [];

    const searchInfo = store.getters['SwContextStore/getSearchInfo'];
    if (!searchInfo) {
      log.debug('There is no searchInfo provided. Request not sent');
    }

    const requestType = options.requestType;
    const indexNames = this.getIndexName(searchInfo, lang, requestType);

    if (!indexNames) {
      log.debug(`There aren't indexNames`);
      return;
    }

    const supplementalIndexNames = this.getSupplementalIndexNames(
      searchInfo,
      lang,
      requestType
    );
    let reqFn;

    options.nonCanceledRequest
      ? (reqFn = this.nonCanceledElasticRequest)
      : (reqFn = this.elasticRequest);

    this.addSearchIndexNames(searchIndexes, indexNames);
    this.addSearchIndexNames(searchIndexes, supplementalIndexNames);

    if (searchIndexes.length === 0) {
      return Promise.reject(
        new Error(
          `Didn't find search indexes indexNames: ${indexNames} and supplementalIndexNames: ${supplementalIndexNames} for search in elastic.`
        )
      );
    }
    return reqFn.call(
      this,
      'post',
      '/' + searchIndexes.join(',') + '/_search',
      query
    );
  },
  /**
   * create index name base on search summary form libary
   * @param  {object} searchInfo
   * @param  {string} lang
   * @return {array}
   */
  getIndexName(searchInfo, lang, requestType) {
    if (!searchInfo) {
      return null;
    }
    const filterIndexSubstring = '-filter-';
    const filterSupplIndexSubstring = 'supl-filter-';
    const allIndexNames = searchInfo.searchSummary[lang] || [];

    switch (requestType) {
      case RequestEnum.REQUEST_FOR_ALL_INDEXES:
        return allIndexNames;
      case RequestEnum.REQUEST_FOR_CONTENT_INDEXES:
        return allIndexNames.filter(
          indexName => !indexName.includes(filterIndexSubstring)
        );
      case RequestEnum.REQUEST_FOR_FILTER_INDEXES:
        return allIndexNames.filter(indexName => {
          return indexName.includes(filterIndexSubstring);
        });
      case RequestEnum.REQUEST_FOR_CORE_FILTER_INDEXES:
        return allIndexNames.filter(indexName => {
          return (
            indexName.includes(filterIndexSubstring) &&
            !indexName.startsWith(filterSupplIndexSubstring)
          );
        });
      case RequestEnum.REQUEST_FOR_SUPPL_FILTER_INDEXES:
        return allIndexNames.filter(indexName =>
          indexName.startsWith(filterSupplIndexSubstring)
        );
      default:
        return allIndexNames;
    }
  },
  /**
   * @param  {object} searchInfo
   * @param  {string} lang
   * @return {object}
   */
  getSupplementalIndexNames(searchInfo, lang, requestType) {
    if (searchInfo === null) {
      return null;
    }

    const allSupplementalInfo = searchInfo.supplementalSummary;
    let supplementalIndexNames = [];

    allSupplementalInfo.forEach(supplementalSummary => {
      const supplementalIndexName = this.getIndexName(
        supplementalSummary,
        lang,
        requestType
      );
      if (supplementalIndexName) {
        supplementalIndexNames = [
          ...supplementalIndexNames,
          ...supplementalIndexName,
        ];
      }
    });

    return supplementalIndexNames;
  },

  filterIndexesByType(indexesList) {
    return indexesList;
  },

  nonCanceledElasticRequest(type, path, data, requestId) {
    const isNonCancelable = true;
    return this.request(
      this.getUrl(),
      type,
      path,
      data,
      requestId,
      isNonCancelable
    );
  },

  addSearchIndexNames(searchIndexes, supplementalIndexNames) {
    supplementalIndexNames.forEach(indexName => {
      this.addSearchIndexName(searchIndexes, indexName);
    });
  },
  /**
   * add to array index name for search by them
   * @param {array} searchIndexes
   * @param {string} indexName
   */
  addSearchIndexName(searchIndexes, indexName) {
    const esInfoMap = store.getters['SwContextStore/getEsInfoMap'];
    if (!esInfoMap.hasOwnProperty(indexName)) {
      if (indexName !== null) {
        log.warn(
          `Didn't find indexName: ${indexName} in elastic search index map.`
        );
      }
      return;
    }

    searchIndexes.push(indexName);
  },

  cancelPreviousRequests() {
    requests.forEach(req => req.cancel(ErrorMessagesEnum.CANCEL_REQUEST));
  },
};
