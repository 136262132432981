import { parseHTML } from '@/services/JQueryMethods';

import find from 'lodash/find';
import get from 'lodash/get';

import { NUMBER_HITS_GROUPES } from '@/constants/constants';

import * as log from 'loglevel';
log.setLevel('info');

const mergePrevNextPara = (prevParaObj, nextParaObj) => {
  const sentencesObj = {
    paraMap: {},
    sortedIds: [],
  };

  if (!prevParaObj && !nextParaObj) {
    return sentencesObj;
  }

  Object.assign(
    sentencesObj.paraMap,
    (prevParaObj && prevParaObj.paraMap) || {},
    (nextParaObj && nextParaObj.paraMap) || {}
  );

  [].push.apply(
    sentencesObj.sortedIds,
    (prevParaObj && prevParaObj.sortedIds) || []
  );
  [].push.apply(
    sentencesObj.sortedIds,
    (nextParaObj && nextParaObj.sortedIds) || []
  );
  return sentencesObj;
};

const addToMap = (map, arr) => {
  arr.forEach(item => {
    map[item] = null;
  });
};

const extractTextContentByClassName = (sentence, className) => {
  const html = parseHTML('<p>' + sentence + '</p>');
  const els = html[0].querySelectorAll(className);

  return Array.prototype.map.call(els, el => {
    return el.textContent.toLowerCase();
  });
};

const getStems = sentence => {
  return extractTextContentByClassName(sentence, '.search-req');
};

const getQuotes = sentence => {
  return extractTextContentByClassName(sentence, '.search-quote');
};

const parseFilterBucketsList = bucketsList => {
  return bucketsList.reduce((parsedList, listItem) => {
    parsedList[listItem.key] = {
      count: listItem.doc_count,
      related: {
        count: listItem.value.buckets?.length,
        list: listItem.value.buckets?.reduce(
          (res, item) => res.concat(item.key),
          []
        ),
      },
    };
    return parsedList;
  }, {});
};

const getSummaryByIndexName = (supplementalSummary, indexName) => {
  const parts = indexName.split('-');
  return find(supplementalSummary, { brand: parts[0] });
};

const createClusteredHitsList = sentences => {
  const CLUSTER_SIZE = 10;

  return sentences.reduce((clusteredSentences, currentSentence, index) => {
    if (index === NUMBER_HITS_GROUPES - 1) {
      log.info('Reached test cluster');
      return clusteredSentences;
    }

    const innerHits = get(currentSentence, 'inner_hits.cluster.hits.hits', []);
    const nextCluster = sentences[index + 1];
    const nextClusterFirstHit = get(
      nextCluster,
      'inner_hits.cluster.hits.hits[0]',
      null
    );

    const parsedInnerHits = innerHits.reduce((hits, currentHit, index) => {
      const nextHitScore = innerHits[index + 1]?._score || 0;
      const isClusterLimitReached = index >= CLUSTER_SIZE;
      const isScoreDecreasing = nextClusterFirstHit
        ? currentHit._score >= nextClusterFirstHit?._score &&
          currentHit._score >= nextHitScore
        : true;

      if (index === 0 || (!isClusterLimitReached && isScoreDecreasing)) {
        hits.push(currentHit);
      }
      return hits;
    }, []);

    if (parsedInnerHits.length) {
      parsedInnerHits[0]._source.firstGroupItem = true;

      //TODO set totalResults in more convenient way
      const totalResults = get(
        currentSentence,
        'inner_hits.cluster.hits.total.value',
        0
      );
      parsedInnerHits.forEach(hit => {
        hit._source.totalResults = totalResults;
        hit._source.collapsedHitsCount = parsedInnerHits.length;
        hit._source.alwaysVisibleHit = true;
      });
    }
    clusteredSentences = [...clusteredSentences, ...parsedInnerHits];
    return clusteredSentences;
  }, []);
};

export default {
  mergePrevNextPara,
  getStems,
  getQuotes,
  addToMap,
  parseFilterBucketsList,
  getSummaryByIndexName,
  createClusteredHitsList,
};
