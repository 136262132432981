//Paragrafs.vue
export const SCROLL_DOWN = 'down';
export const SCROLL_UP = 'up';
export const NUMBER_PARAGRAPHS = 15;
export const NUMBER_PARAGRAPHS_ENCUMBERED = 360;
export const MAX_NEXT_PARA_CHECKS_COUNT = 5;
export const NEXT = 'next';
export const PREV = 'prev';
export const FIRST = 'first';
export const SPACE_SCROLL_UP = 'space-scroll-up';
export const SPACE_SCROLL_DOWN = 'space-scroll-down';
export const INPUT = 'INPUT';
/**
 * When data.offset becomes 0, scrollTop will be 0 and the possibility of scrolling disappears.
 * As a result loadMoreParagraphs doesn't trigger but unloaded paragraphs exists.
 */

export const SENT = 'sent';

//QueryBuilder
export const FRAGMENT_SIZE = 10000;
export const ITEMS_MAX_SIZE = 10000;
export const MAX_BOOST = 10;
export const MIN_BOOST = 1;
export const MAX_SLOP = 2;
export const INNER_HITS_COUNT = 3;
export const NUMBER_CHARS_FOR_START_FUZZINESS = 4;

//SearchUtils
export const MIN_META_LENGTH = 3;

//ValidateQueryService
export const MIN_NUMBER_CHAR_META_DATA = 2;
export const MIN_QUOTES_NUMBER_CHAR = 3;

//Navigation
export const NAVIGATION_STEP = 1;

//Provider
export const UNSET_METHOD = 'unsetMethod';

//utils
export const pixelsForPaddingX = 30;
export const pixelsForPaddingY = 25;

//topBar
export const DISABLE_FILTER_EN = 3;
export const DISABLE_FILTER_AR_FA = 3;

export const CLASS_NAME_FOR_LANGUAGE_LIST = 'languages-list';
export const MIN_CHARS_COUNT_FOR_SEARCH = 3;

export const TOOLTIP_CLASS_NAME = 'base-tooltip';

//request
export const TIMER_FOR_CLEAR_INPUT = 600000; //10 minutes

//BookMeta
export const SUPPLEMENTAL = 'Supplemental';
export const TABLET = 'Tablet';
export const BOOK = 'Book';

//App
export const WIDGET_NAME_MAP = {
  ffa: 'search-widget-ffa',
  ocean: 'search-widget-ocean',
  ool: 'search-widget-ool',
};

export const OOL = 'ool';
export const FFA = 'ffa';
export const OCEAN = 'ocean';
export const PORTUGUESE = 'pt';

//Hits
export const NUMBER_HITS_GROUPES = 15;
export const DEFAULT_HITS_START_INDEX = 0;
export const REGULAR_HIT_TYPE = 'regularHit';
export const TOP_SEPARATOR_TYPE = 'hitsGroupTopSeparator';
export const BOTTOM_SEPARATOR_TYPE = 'hitsGroupBottomSeparator';
export const STICKY_CLASS_NAME = 'sticky-item';

/**
 * Filter
 */
export const FILTER_CATEGORY_AUTHORS = 'authors';
export const FILTER_CATEGORY_PUBLICATIONS = 'publications';
export const FILTER_CATEGORY_CATEGORIES = 'categories';
export const SW_NARROW = 700;

//baseErrorWindow
export const TIMER_FOR_GET_CONFIG_RETRY = 5000;
export const COUNTER_FOR_GET_CONFIG_ATTEMPTS = 5;

/**
 * Context
 */
// inputs
export const FOCUS_FILTER_FIELD = 'filterField';
export const FOCUS_SELECTOR_SEARCH_FIELD = 'searchField';
// blocks
export const FOCUS_SELECTOR_HITS = 'hits';
export const FOCUS_SELECTOR_PARAGRAPHS = 'paragraphs';
export const FOCUS_FILTER_BLOCK = 'filterBlock';

/**
 * selection-utils
 */
export const SCROLL_SELECTOR = '.virtual-list';
export const PARAGRAPH_SELECTOR = '.scroll-item';
export const CONTAINER_SELECTOR = '.search-result-item-content';
export const SELECTION_TYPE = 'Range';
export const PARAGRAPH_CONTENT_SELECTOR = '[data-sw-id]';

export const CORE_FILTER = 'coreFilter';
export const SUPPLEMENTAL_FILTER = 'supplementalFilter';
