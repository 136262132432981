<template>
  <div>
    <div
      class="read-mode-search-popup"
      :class="{
        'expanded-view': expandedView,
        'expanded-infinite': expandedInfinite,
        'search-info-opened': infoOpened,
        'keyboard-opened': keyboardOpened,
        'multi-lang': multiLanguageBrand,
        'is-dirty': isDirty,
      }"
    >
      <div v-hotkey.self="keymap" class="read-mode-search-popup-wrapper">
        <TopBar data-test="top-bar" />
        <BottomBar v-show="showBottomBar" data-test="bottom-bar" />
        <BaseErrorWindow v-show="isErrorOccurred" data-test="error" />
      </div>
    </div>
    <div data-test="backdrop" class="popup-backdrop" @click="closePopup" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import defaultConfig from '@/configs/default.config';

  import TopBar from '@/containers/topbar/TopBar.vue';
  import BottomBar from '@/containers/bottombar/BottomBar.vue';
  import BaseErrorWindow from '@/components/BaseErrorWindow.vue';

  import { FOCUS_SELECTOR_SEARCH_FIELD } from '@/constants/constants';

  export default {
    name: 'Popup',
    components: {
      TopBar,
      BottomBar,
      BaseErrorWindow,
    },
    computed: {
      ...mapState('SwContextStore', [
        'brand',
        'isExtension',
        'mainPopupOpened',
        'keyboardOpened',
        'infoOpened',
        'focus',
      ]),
      ...mapState('SwSearchStore', ['searchText', 'foundNothing']),
      ...mapState('SwHitsStore', ['hits']),
      ...mapState('SwParagraphsStore', ['paragraphs']),
      ...mapState('SwErrorStore', ['isErrorOccurred']),

      showBottomBar() {
        return (
          this.infoOpened ||
          this.keyboardOpened ||
          !this.isErrorOccurred ||
          !this.searchText
        );
      },
      expandedInfinite() {
        return this.foundNothing;
      },
      multiLanguageBrand() {
        return defaultConfig.isBrandMultilanguage[this.brand];
      },
      expandedView() {
        return this.hits.length > 0;
      },
      keymap() {
        if (!this.mainPopupOpened) {
          return false;
        }

        return {
          tab: () => {
            this.setPopupFocused({ direction: 1 });
          },
          'shift+tab': () => {
            this.setPopupFocused({ direction: -1 });
          },
          enter: () => {
            if (this.focus.hits) {
              this.setPopupFocused({ selector: FOCUS_SELECTOR_SEARCH_FIELD });
            }
          },
          esc: () => {
            this.$store.dispatch('SwContextStore/setMainPopupOpened', false);
          },
        };
      },
      isDirty() {
        return this.searchText.length > 2;
      },
    },
    methods: {
      closePopup() {
        this.$store.dispatch('SwContextStore/setMainPopupOpened', false);
      },
      setPopupFocused(options) {
        this.$store.dispatch('SwContextStore/changeFocus', options);
      },
    },
  };
</script>

<style src="./Popup.less" scoped lang="less"></style>
