async function retryRequest(requestFn, options = {}, currentNumReq = 0) {
  const {
    numFastRetry = 5,
    fastRetryTime = 5000,
    longRetryTime = 2 * 60 * 1000,
  } = options;
  try {
    const response = await requestFn();
    return response;
  } catch {
    currentNumReq += 1;
    const waitTime =
      currentNumReq > numFastRetry ? longRetryTime : fastRetryTime;
    await wait(waitTime);
    const resp = await retryRequest(requestFn, options, currentNumReq);
    return resp;
  }
}

function wait(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export default {
  retryRequest,
};
