<template>
  <div
    class="virtual-list-wrapper search-hits"
    :class="{
      'scrolling-on': isScrolling,
      'hide-virtualList-header': hideVirtualListHeader,
    }"
    @click="setFocus()"
    @copy="handleCopyEvent"
    v-hotkey="keymap"
  >
    <virtual-list
      v-focus="focus.hits"
      class="virtual-list"
      :data-sources="hits"
      :data-key="'id'"
      :data-component="searchHitScrollItem"
      :extra-props="{
        activeHitIndex: activeHitIndex,
        hitItemEvent: handleHitItemEvent,
      }"
      :keeps="120"
      :remain="50"
      :start="scrollStartIndex"
      v-on:scroll="onScroll"
      tabindex="100"
      ref="virtualList"
      v-show="isHitsVisible"
      item-class="list-item scroll-item"
      :item-scoped-slots="$scopedSlots"
    >
      <div slot="header" class="fake-header">
        <HitsGroupTopContainer
          v-if="expandedPublicationId"
          :source="expandedPublicationData"
          @hitItemEvent="handleHitItemEvent"
        ></HitsGroupTopContainer>
      </div>
    </virtual-list>
  </div>
</template>

<script>
  import * as log from 'loglevel';
  log.setLevel('info');

  import get from 'lodash/get';
  import { mapState } from 'vuex';
  import { focus } from 'vue-focus';
  import virtualList from 'vue-virtual-scroll-list';

  import SearchHitScrollItem from '@/containers/searchhits/SearchHitScrollItem.vue';
  import HitsGroupTopContainer from '@/containers/searchhits/HitsGroupTopContainer.vue';

  import EventEnum from '@/enums/EventEnum';

  let onScrollTimeout;
  let currentDocSetTimeout;
  let hitsReadyTimeoutFn;

  const SCROLL_TIMEOUT = 300;
  const DEFAULT_ACTIVE_HIT_INDEX = 1;
  const DEFAULT_SCROLL_START_INDEX = 0;
  const HITS_READY_TIMEOUT = 50;
  const CONTAINER_SELECTOR = '.search-hits';

  let selectionChangeTimeout = null;

  import { FOCUS_SELECTOR_HITS } from '@/constants/constants';
  import CopyService from '@/services/CopyService';

  export default {
    name: 'SearchHits',
    directives: {
      focus: focus,
    },
    components: {
      HitsGroupTopContainer,
      'virtual-list': virtualList,
    },
    data() {
      return {
        searchHitScrollItem: SearchHitScrollItem,
        isScrolling: false,
        activeHitIndex: DEFAULT_ACTIVE_HIT_INDEX,
        scrollStartIndex: DEFAULT_SCROLL_START_INDEX,
        isNewReqHitsLoaded: false,
        hideVirtualListHeader: false,
        currentScrollPosition: 0,
        isScrollingDown: false,
        mouseButtonPressed: false,
        currentSelectionSource: null,
      };
    },
    computed: {
      ...mapState('SwHitsStore', [
        'activeHit',
        'loadHitsInProcess',
        'loadHitsByPublicationInProcess',
      ]),
      ...mapState('SwContextStore', ['brand', 'focus', 'mainPopupOpened']),
      ...mapState('SwSearchStore', ['searchText']),
      ...mapState('SwSelectionContextStore', [
        'selectionContext',
        'selectionStartContainerSelector',
      ]),

      isHitsVisible() {
        return this.hits && this.hits.length;
      },
      isAllHitsUploaded() {
        return this.$store.getters['SwHitsStore/isAllHitsUploaded'];
      },
      isAllHitsByExpandedPublicationLoaded() {
        return this.$store.getters[
          'SwHitsStore/isAllHitsByExpandedPublicationLoaded'
        ];
      },
      hits() {
        const hits = this.$store.getters['SwHitsStore/getHits'];
        return hits.filter(hit => {
          if (hit.publicationId === this.expandedPublicationId) {
            return hit;
          }
          return hit.alwaysVisibleHit;
        });
      },
      keymap() {
        if (!this.mainPopupOpened) {
          return false;
        }

        return {
          up: () => {
            if (this.focus && this.focus.hits) {
              this.$_navigate({ isUp: true });
            }
          },
          down: () => {
            if (this.focus && this.focus.hits) {
              this.$_navigate({ isUp: false });
            }
          },
        };
      },
      expandedPublicationId() {
        return this.$store.getters['SwHitsStore/getExpandedPublicationId'];
      },
      expandedPublicationData() {
        return this.$store.getters['SwHitsStore/getExpandedPublicationData'];
      },
    },
    watch: {
      hits(newHits, oldHits) {
        const newFirstHit = get(newHits, '[1]', {});
        const oldFirstHit = get(oldHits, '[1]', {});
        if (!newHits || newHits.length === 0) {
          this.scrollStartIndex = -1;
          return;
        }
        this.isNewReqHitsLoaded = newFirstHit.id !== oldFirstHit.id;
        if (this.isNewReqHitsLoaded) {
          this.scrollStartIndex = DEFAULT_SCROLL_START_INDEX;
        }
      },
      activeHit: {
        handler: function() {
          const index = this.$_getActiveHitIndex();
          this.$_setActiveHitIndex(this.activeHit, index);
        },
      },
      mainPopupOpened: {
        immediate: true,
        handler(isOpened) {
          if (!isOpened || !this.activeHit?.id) {
            return;
          }
          this.$_setActiveHit(this.activeHit);

          const index = this.$_getActiveHitIndex(true);
          this.$_setActiveHitIndex(this.activeHit, index);

          clearTimeout(hitsReadyTimeoutFn);
          hitsReadyTimeoutFn = setTimeout(() => {
            this.$_scrollToActiveHit();
          }, HITS_READY_TIMEOUT);
        },
      },
    },
    mounted() {
      document.addEventListener('selectionchange', this.handleSelectionChange);
    },
    beforeDestroy() {
      document.removeEventListener(
        'selectionchange',
        this.handleSelectionChange
      );
    },
    methods: {
      async handleHitItemEvent({ type, data }) {
        switch (type) {
          case EventEnum.HIT_ITEM_ACTIVATED:
            this.$store.dispatch('SwHitsStore/resetHitState');
            this.$_processActivateHit({
              source: data.source,
              index: data.index,
            });
            break;
          case EventEnum.HIT_ITEM_READ_BOOK:
            this.$store.dispatch(
              'SwPublicationsStore/openPublication',
              data.source
            );
            break;
          case EventEnum.HIT_SEPARATOR_CLICKED: {
            const expandedPublicationId = this.expandedPublicationId;
            const publicationId = data.source?.publicationId;

            await this.$store.dispatch(
              'SwHitsStore/toggleExpandedPublication',
              publicationId
            );

            if (
              !expandedPublicationId ||
              publicationId !== expandedPublicationId
            ) {
              await this.$_updateScrollAfterExpand(publicationId);
              return;
            }

            const topHitIndex = this.hits.findIndex(
              hit => hit.publicationId === expandedPublicationId
            );
            this.$_updateScrollAfterCollapse(topHitIndex);
            break;
          }
          case EventEnum.ACTIVE_HIT_ITEM_MOUNTED:
            if (this.isNewReqHitsLoaded) {
              await this.$nextTick();
              this.$_scrollToIndex(DEFAULT_SCROLL_START_INDEX);
            }
            break;
          case EventEnum.HIT_ITEM_TOUCH_START:
            this.handleTouchStart(data);
            break;
          case EventEnum.HIT_ITEM_TOUCH_END:
            this.handleTouchEnd(data);
            break;
          case EventEnum.HIT_ITEM_MOUSE_UP:
            this.handleMouseUp(data);
            break;
          case EventEnum.HIT_ITEM_MOUSE_DOWN:
            this.handleMouseDown(data);
            break;
          case EventEnum.HIT_ITEM_MOUSE_OUT:
            this.handleMouseOut(data);
            break;
          default:
            break;
        }
      },
      handleCopyEvent(event) {
        event.preventDefault();

        const selectionContext = this.$store.getters[
          'SwSelectionContextStore/getSelectionContext'
        ];
        CopyService.copySelectionContextToClipboard(
          event,
          selectionContext,
          () => {
            this.$store.dispatch(
              'SwSelectionContextStore/resetSelectionContext'
            );
            window.getSelection().removeAllRanges();
            this.currentSelectionSource = null;
          }
        );
      },
      async $_updateScrollAfterExpand(publicationId) {
        try {
          const topHitIndex = this.hits.findIndex(
            hit => hit.publicationId === publicationId
          );
          this.$_scrollToIndex(topHitIndex);

          await this.$store.dispatch(
            'SwHitsStore/performSearchMoreHitsByPublication',
            publicationId
          );

          const virtualListElement = this.$el.querySelector(`.virtual-list`);
          virtualListElement.scrollTop += 1;
        } catch (error) {
          log.info(`Update scroll after expand failed with error: ${error}`);
        }
      },

      $_updateScrollAfterCollapse(index) {
        const currentOffset = this.$refs?.virtualList?.getOffset();
        const virtualScrollOffset = this.$refs?.virtualList?.virtual?.getOffset(
          index
        );
        if (currentOffset > virtualScrollOffset) {
          this.$_scrollToIndex(index);
        }
      },

      $_processActivateHit({ source, index }) {
        this.$_setActiveHit(source);
        this.$_setActiveHitIndex(source, index);
      },

      $_navigate(options) {
        const activeIndex = options.isUp
          ? this.$_getPrevHitIndex()
          : this.$_getNextHitIndex();

        clearTimeout(currentDocSetTimeout);
        const self = this;

        currentDocSetTimeout = setTimeout(() => {
          self.$_processActivateHit({
            source: self.hits[activeIndex],
            index: activeIndex,
          });
          self.$nextTick(() => {
            self.$_updateScrollPosition(options);
          });
        }, SCROLL_TIMEOUT);
      },

      $_getPrevHitIndex() {
        const index = this.hits.findLastIndex((hit, index) => {
          return index < this.activeHitIndex && !hit.isSeparator;
        });
        return Math.max(index, 0);
      },

      $_getNextHitIndex() {
        const index = this.hits.findIndex((hit, index) => {
          return index > this.activeHitIndex && !hit.isSeparator;
        });
        return Math.max(index, this.activeHitIndex);
      },

      $_updateScrollPosition(options) {
        const hit = this.$el.querySelector('.search-results-item-block.active');
        if (hit) {
          const containerRect = this.$el.getBoundingClientRect();
          const hitRect = hit.getBoundingClientRect();
          if (
            hitRect.top > containerRect.top &&
            hitRect.bottom < containerRect.bottom
          ) {
            return;
          }
          hit.scrollIntoView(options.isUp);
        } else if (options.isUp) {
          this.scrollStartIndex = this.activeHitIndex;
        }
      },

      onScroll(e, data) {
        const container = this.$el.querySelectorAll('.virtual-list')[0];
        const containerHeight = container.getBoundingClientRect().height;
        this.hideVirtualListHeader = container.scrollTop < 500;

        if (
          this.loadHitsInProcess &&
          data.padBehind - data.padFront > containerHeight * 7
        ) {
          return;
        }

        const self = this;
        self.isScrolling = true;

        clearTimeout(onScrollTimeout);

        onScrollTimeout = setTimeout(() => {
          self.isScrolling = false;
        }, SCROLL_TIMEOUT);

        if (this.expandedPublicationId) {
          this.$_loadMoreByExpandedPublication();
        } else {
          this.$_loadMore();
        }
      },

      $_setActiveHitIndex(hit, index) {
        if (index === null || !hit || !this.activeHit?.id) {
          return;
        }

        if (index === 0 || hit.id === this.activeHit?.id) {
          this.activeHitIndex = index;
        }
      },

      $_getActiveHitIndex(initialOpen) {
        if (!this.activeHit?.id) {
          return null;
        }

        if (initialOpen || !this.activeHit.firstGroupItem) {
          return this.$_findActiveHitIndex();
        }
        return null;
      },

      $_findActiveHitIndex() {
        let index = this.hits.findIndex(hit => hit.id === this.activeHit.id);

        if (index === -1) {
          index = this.hits
            .map(hit => hit.publicationId)
            .lastIndexOf(this.activeHit.publicationId);
        }
        return index >= 0 ? index : null;
      },

      async $_setActiveHit(hit) {
        try {
          await this.$store.dispatch('SwHitsStore/setActiveHit', hit);
        } catch (e) {
          this.$store.dispatch('SwErrorStore/setIsErrorOccurred', true);
          this.$store.dispatch('SwErrorStore/setErrorMessage', e);
        }
      },

      $_setActiveHitById(hit) {
        this.$store.dispatch('SwHitsStore/setActiveHitById', hit);
      },

      async $_loadMore() {
        if (this.loadHitsInProcess || this.isAllHitsUploaded) {
          return;
        }
        await this.$store.dispatch('SwHitsStore/performSearchMoreHits');
      },

      async $_loadMoreByExpandedPublication() {
        if (
          this.loadHitsByPublicationInProcess ||
          this.isAllHitsByExpandedPublicationLoaded
        ) {
          return;
        }
        await this.$store.dispatch(
          'SwHitsStore/performSearchMoreHitsByPublication',
          this.expandedPublicationId
        );
      },

      setFocus() {
        this.$store.dispatch('SwContextStore/changeFocus', {
          selector: FOCUS_SELECTOR_HITS,
        });
      },

      $_scrollToIndex(index) {
        if (this.$refs.virtualList && typeof index === 'number') {
          this.$refs.virtualList.scrollToIndex(index);
        }
      },

      $_scrollToOffset(offset) {
        if (this.$refs.virtualList && typeof offset === 'number') {
          this.$refs.virtualList.scrollToOffset(offset);
        }
      },

      $_scrollToActiveHit() {
        const scrollElement = this.$el.querySelector('.virtual-list');
        const selectionElement = this.$el.querySelector('.active');

        if (scrollElement && selectionElement) {
          selectionElement.scrollIntoView(true);
        }
      },
      handleTouchStart({ event, source }) {
        if (!this.$_isTouchDevice()) {
          return;
        }

        this.currentSelectionSource = source;
        this.$_handleSelectionStart({ event, source });
      },
      handleTouchEnd() {},
      handleMouseDown({ event, source }) {
        this.currentSelectionSource = source;
        this.mouseButtonPressed = true;
        this.$_handleSelectionStart({ event, source });
      },
      handleMouseUp({ event, source }) {
        this.mouseButtonPressed = false;
        this.$_handleSelectionEnd({ event, source });
      },
      handleMouseOut({ event, source }) {
        if (!this.mouseButtonPressed) {
          return;
        }
        this.mouseButtonPressed = false;

        const _event = { ...event };
        const currentSpanTarget =
          event.target.querySelector('span[data-locator]')?.parentNode ||
          event.target;
        const currentTargetRect = currentSpanTarget?.getBoundingClientRect();
        _event.clientX = currentTargetRect.x + 10;
        _event.clientY = currentTargetRect.y + 10;
        _event.target = event.target;

        const selection = window.getSelection();

        if (selection.rangeCount < 1) {
          return;
        }

        if (event.y > currentTargetRect.y) {
          const preSelectionRange = selection.getRangeAt(0).cloneRange();
          preSelectionRange.setEnd(
            currentSpanTarget,
            currentSpanTarget.childNodes.length
          );
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(preSelectionRange);
        }

        this.$_handleSelectionEnd({ event: _event, source });
      },
      handleSelectionChange(event) {
        if (
          this.selectionStartContainerSelector !== CONTAINER_SELECTOR ||
          !this.$_isTouchDevice() ||
          (!this.$_isInsideShadowRoot() &&
            !this.$el.contains(document.getSelection().anchorNode)) ||
          !document.getSelection()?.toString()?.length
        ) {
          return;
        }
        clearTimeout(selectionChangeTimeout);
        selectionChangeTimeout = setTimeout(() => {
          const range = window.getSelection().getRangeAt(0);
          const rangeClientRects = range.getClientRects();

          this.$_handleSelectionStart({
            event: this.$_recalculateSelectionEvent(event, rangeClientRects[0]),
            source: this.currentSelectionSource,
          });
          this.$_handleSelectionEnd({
            event: this.$_recalculateSelectionEvent(
              event,
              rangeClientRects[rangeClientRects.length - 1],
              rangeClientRects[rangeClientRects.length - 1].width
            ),
            source: this.currentSelectionSource,
          });
        }, 100);
      },
      $_handleSelectionStart({ event, source }) {
        if (this.$_isRightMouseClickTriggered(event)) {
          return;
        }

        this.$store.dispatch('SwSelectionContextStore/setSelectionStart', {
          bookMeta: source?.bookMeta,
          id: source?.id,
          selectionStartEvent: event,
          container: this.$el.closest(CONTAINER_SELECTOR),
          containerSelector: CONTAINER_SELECTOR,
          isWrappedWithLocators: source?.collapsed,
        });
      },
      async $_handleSelectionEnd({ event, source }) {
        if (
          this.$_isRightMouseClickTriggered(event) ||
          this.$_isNotSelectable(source) ||
          !this.selectionContext.startLocator ||
          source.type !== 'regularHit'
        ) {
          return;
        }

        await this.$store.dispatch('SwSelectionContextStore/setSelectionEnd', {
          bookMeta: source?.bookMeta,
          selectionEndEvent: event,
          container: this.$el.closest(CONTAINER_SELECTOR),
          isWrappedWithLocators: source?.collapsed,
        });
        await this.$_setSelection(source);
      },
      $_isTouchDevice() {
        return 'ontouchstart' in window;
      },
      $_isInsideShadowRoot() {
        return this.$el.getRootNode() instanceof ShadowRoot;
      },
      $_setSelection(source) {
        if (
          this.$_isNotSelectable(source) ||
          !this.selectionContext.startLocator ||
          source.type !== 'regularHit'
        ) {
          return;
        }

        const selectionText = window.getSelection().toString();
        return this.$store.dispatch(
          'SwSelectionContextStore/setSelectionText',
          selectionText
        );
      },
      $_isRightMouseClickTriggered(event) {
        return event.button === 2;
      },
      $_isNotSelectable(source) {
        return (
          this.selectionContext.id && this.selectionContext.id !== source.id
        );
      },
      $_recalculateSelectionEvent(event, clientRect, xOffset = 0) {
        const _event = { ...event };
        _event.clientX = clientRect.x + xOffset;
        _event.clientY = clientRect.y + 10;
        _event.target = event.target;

        return _event;
      },
    },
  };
</script>

<style src="./SearchHits.less" lang="less"></style>
