<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :aria-labelledby="iconName"
    :fill="iconColor"
    :viewBox="viewBox"
    role="presentation"
  >
    <use v-bind="{ 'xlink:href': link, href: link }" />
  </svg>
</template>

<script>
  export default {
    name: 'BaseIcon',
    props: {
      iconName: {
        type: String,
        default: 'box',
      },
      width: {
        type: [Number, String],
        default: 20,
      },
      height: {
        type: [Number, String],
        default: 20,
      },
      iconColor: {
        type: String,
        default: 'currentColor',
      },
      viewBox: {
        type: String,
        default: '0 0 24 24',
      },
    },
    computed: {
      link() {
        const SVGSprite = this.$store.state.SwContextStore.spriteUrl || '';
        return `${SVGSprite}#${this.iconName}`;
      },
    },
  };
</script>
