class OfflineSearchService {
  updateOnlineStatus(store) {
    const isOnline = navigator.onLine;
    store.dispatch('SwOfflineModeStore/setIsOnline', isOnline);
    return isOnline;
  }
}

const offlineSearchService = new OfflineSearchService();

export default offlineSearchService;
