import SearchStemsWrapperService from '@/services/SearchStemsWrapperService';
import SearchService from '@/services/SearchService';

const BEFORE_TRUNCATE_CHARS_LIMIT = 250;

class Paragraph {
  constructor(data) {
    this.id = data.id;
    this.title = data.title;
    this.html = data.html;
    this.truncatedHtml = data.truncatedHtml;
  }
}

class ParagraphBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  setHtml(paragraph, contentArray, parsedQuery, activeHit) {
    this.html = createParagraphHtml(
      paragraph,
      contentArray,
      parsedQuery,
      activeHit
    );
    return this;
  }

  setTruncatedHtml(paragraph, contentArray, parsedQuery, activeHit) {
    if (paragraph.paraId === activeHit.paraId) {
      const truncatedContentArray = truncateParagraphs(paragraph, contentArray);
      this.truncatedHtml = createParagraphHtml(
        paragraph,
        truncatedContentArray,
        parsedQuery,
        activeHit
      );
    }

    return this;
  }

  build() {
    return new Paragraph(this);
  }
}

const getParagraphBuilder = () => {
  return new ParagraphBuilder();
};

const createParagraph = (paragraph, contentArray, parsedQuery, activeHit) => {
  return new ParagraphBuilder()
    .setId(paragraph.paraId)
    .setTitle(paragraph.title)
    .setHtml(paragraph, contentArray, parsedQuery, activeHit)
    .setTruncatedHtml(paragraph, contentArray, parsedQuery, activeHit)
    .build();
};

//TODO: move to according utils
function concatParaContentArray(contentArray) {
  return contentArray.reduce((result, contentItem, index) => {
    if (index !== 0) {
      result += ' ';
    }
    result += contentItem.text;
    return result;
  }, '');
}

function truncateParagraphs(activeHit, contentArray) {
  const newContentArray = [];
  let activeParaIndex = contentArray.findIndex(
    content => activeHit && activeHit.id === content.docId
  );
  activeParaIndex = Math.max(activeParaIndex, 0);

  const activePara = contentArray[activeParaIndex];

  if (activePara.text.length > BEFORE_TRUNCATE_CHARS_LIMIT) {
    return [activePara];
  }

  const prevContentItem = contentArray[activeParaIndex - 1];
  if (prevContentItem) {
    prevContentItem.text = SearchService.trimText(
      prevContentItem.text,
      100,
      -1
    );
    newContentArray.push(prevContentItem);
  }

  newContentArray.push(contentArray[activeParaIndex]);

  const nextContentItem = contentArray[activeParaIndex + 1];
  if (nextContentItem) {
    nextContentItem.text = SearchService.trimText(nextContentItem.text, 100, 1);
    newContentArray.push(nextContentItem);
  }
  return newContentArray;
}

function createParagraphHtml(paragraph, contentArray, parsedQuery, activeHit) {
  contentArray.forEach(contentItem => {
    if (activeHit && activeHit.id === contentItem.docId) {
      contentItem.text = SearchStemsWrapperService.wrapTextBySentenceDecorator(
        contentItem.text
      );
    }
  });

  paragraph.text = concatParaContentArray(contentArray);

  let html = SearchStemsWrapperService.createHTML(
    paragraph.text,
    paragraph.paragraphs,
    paragraph.paraId,
    paragraph.tag,
    paragraph
  );

  if (parsedQuery?.quotes?.length) {
    html = SearchStemsWrapperService.highlightSearchResultInSentence(
      html,
      parsedQuery.quotesWords[0],
      parsedQuery.quotesQuery[0]
    );
  }
  return html;
}

export default {
  getParagraphBuilder,
  createParagraph,
};
