<template>
  <div class="keyboard" dir="ltr">
    <div data-test="closeKeyboard" @click="closeKeyboard">
      <span class="icon-container">
        <BaseIcon icon-name="ico-close" />
      </span>
    </div>
    <keyboard v-model="input" :layouts="getLayout" @input="onChange" />
  </div>
</template>

<script>
  /**
   * Keyboard use 'vue-keyboard' node module (https://github.com/MartyWallace/vue-keyboard)
   * In case you need additional language layout for keyboard, just add in layouts folder file
   * with language name (example: arabic.js), insert there corresponding data and include link on layout
   * in 'langToLayoutMap' object.
   *
   * ! you may look for usage example in src/containers/searchfilter/SearchFilter.vue
   */
  import { mapState, mapGetters } from 'vuex';

  import Keyboard from 'vue-keyboard';

  import BaseIcon from '@/components/BaseIcon.vue';
  import arabic from './layouts/arabic.js';
  import farsi from './layouts/farsi.js';
  import portuguese from './layouts/portuguese';

  const langToLayoutMap = {
    fa: farsi,
    ar: arabic,
    en: arabic, //should be change if english layout needed
    pt: portuguese,
  };

  export default {
    components: {
      Keyboard,
      BaseIcon,
    },
    props: {
      language: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('SwContextStore', ['lang', 'focus']),
      ...mapState('SwKeyboardStore', ['keyboardInput', 'lastFocusedField']),
      ...mapGetters('SwKeyboardStore', ['getInput']),
      getLayout() {
        return this.language
          ? langToLayoutMap[this.language]
          : langToLayoutMap[this.lang];
      },
      input: {
        get() {
          return this.getInput(this.lastFocusedField);
        },
        set(val) {
          this.$store.commit('SwKeyboardStore/setKeyboardInput', {
            field: this.lastFocusedField,
            input: val,
          });
        },
      },
    },
    created() {
      this.setSearchFieldFocused();
      this.setModelIfNeed(this.keyboardInput);
    },

    destroyed() {
      this.setSearchFieldFocused();
    },

    methods: {
      async onChange() {
        this.$store.dispatch(
          'SwKeyboardStore/setIsInputFromVirtualKeyboard',
          true
        );
        this.setSearchFieldFocused();
      },

      closeKeyboard() {
        this.$store.dispatch('SwContextStore/setKeyboardOpened', false);
      },

      setSearchFieldFocused() {
        const options = { selector: this.lastFocusedField };
        this.$store.dispatch('SwContextStore/changeFocus', options);
      },
      setModelIfNeed(keyboardInput) {
        const text = keyboardInput?.[this.lastFocusedField] ?? '';
        this.input = text;
      },
    },
  };
</script>

<style lang="less" src="./Keyboard.less" />
