import PublicationLocator from './publication-locator';

/**
 * Locator attached to the end of Publication
 *
 * @constructor
 * @extends {PublicationLocator}
 */

export default class PublicationEndLocator extends PublicationLocator {
  /**
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   */
  compareTo(locator) {
    return this._comparePublicationEndWith(locator);
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   */
  compareBasisTo(locator) {
    return this._comparePublicationEndWith(locator);
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   * @private
   */
  _comparePublicationEndWith(locator) {
    return locator.constructor === PublicationEndLocator ? 0 : 1;
  }
}
