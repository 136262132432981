<template>
  <Component
    :class="{ 'active-publication': isPublicationActive }"
    :is="component"
    :index="index"
    :source="source"
    :activeHitIndex="activeHitIndex"
    @hitItemEvent="hitItemEvent"
  ></Component>
</template>

<script>
  import { mapState } from 'vuex';

  import SearchHitItem from '@/containers/searchhits/SearchHitItem.vue';
  import HitsGroupTopContainer from '@/containers/searchhits/HitsGroupTopContainer.vue';
  import HitsGroupBottomContainer from '@/containers/searchhits/HitsGroupBottomContainer.vue';

  import {
    REGULAR_HIT_TYPE,
    TOP_SEPARATOR_TYPE,
    BOTTOM_SEPARATOR_TYPE,
  } from '@/constants/constants';

  export default {
    name: 'SearchHitScrollItem',
    props: {
      index: {
        type: Number,
      },
      source: {
        type: Object,
        default() {
          return {};
        },
      },
      activeHitIndex: {
        type: Number,
      },
      hitItemEvent: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      ...mapState('SwHitsStore', ['activeHit']),

      isPublicationActive() {
        return this.source?.publicationId === this.activeHit?.publicationId;
      },

      component() {
        switch (this.source?.type) {
          case REGULAR_HIT_TYPE:
            return SearchHitItem;
          case TOP_SEPARATOR_TYPE:
            return HitsGroupTopContainer;
          case BOTTOM_SEPARATOR_TYPE:
            return HitsGroupBottomContainer;
          default:
            return SearchHitItem;
        }
      },
    },
  };
</script>

<style scoped></style>
