import FilterFactory from '@/classes/factories/FilterFactory';
import FullTextSearchFactory from '@/classes/factories/queries/FullTextSearchFactory';

import FullTextSearchService from '@/services/Search/FullTextSearchService';
import ParseResponse from '@/services/ElasticSearch/ParseResponse';
import FilteringService from '@/services/ElasticSearch/FilteringService';

import localStorageService from '@/services/LocalStorageService';
import LocalStorageKeyEnum from '@/enums/LocalStorageKeyEnum';

import filterConfig from '@/configs/filterConfig.js';
import config from '@/configs/default.config.json';

import languageUtils from '@/utils/languageUtils';

import * as log from 'loglevel';
log.setLevel('info');

const DEFAULT_FILTER_TAGS = [];
let afterReloadFoundFilteredPublicationIds = {};

const state = _createInitialFilterState();

function _createInitialFilterState() {
  return {
    filterReady: false,
    filterResponse: null,
    filterTags: DEFAULT_FILTER_TAGS,
    filterByLang: config.languages.reduce((filter, lang) => {
      filter[lang] = FilterFactory.createFilterTree();
      return filter;
    }, {}),
    filter: FilterFactory.createFilterTree(),
    userFilter: FilterFactory.createFilterTree(),
  };
}

const getters = {
  getFilter: (state, getters, rootState, rootGetters) => {
    const lang = rootGetters['SwContextStore/getLang'];
    return state.filterByLang[lang];
  },
  getFilterResponse: state => {
    return state.filterResponse;
  },
  getFilteredPublicationIds: (state, getters, rootState, rootGetters) => {
    const lang = rootGetters['SwContextStore/getLang'];
    return state.filterByLang[lang]?.filteredPublicationIds || [];
  },
  getFilterPinnedAuthorsNormalized: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const lang = rootGetters['SwContextStore/getLang'];
    const brand = rootGetters['SwContextStore/getBrand'];
    const authors = filterConfig[brand]?.[lang]?.pinnedAuthors || [];
    const authorsNormalized = authors.map(author =>
      languageUtils.normalize(author, lang)
    );
    return authorsNormalized;
  },
  getFilterReady: state => {
    return state.filterReady;
  },
};

const actions = {
  fillInitialFilterStore({ commit, rootGetters }, payload) {
    const lang = rootGetters['SwContextStore/getLang'];
    const shouldShowFilterCategories =
      rootGetters['SwContextStore/shouldShowFilterCategories'];
    commit('setFilterResponse', payload);
    commit('setInitialFilter', {
      lang,
      filter: payload,
      isCategoriesHidden: !shouldShowFilterCategories,
    });
    commit('setInitialFilterTags');
  },

  resetFilterStore({ commit, rootGetters }) {
    const lang = rootGetters['SwContextStore/getLang'];
    commit('setFilterResponse');
    commit('setInitialFilter', { lang });
    commit('setInitialFilterTags');
  },

  resetUserFilter({ commit, rootGetters }) {
    const lang = rootGetters['SwContextStore/getLang'];

    commit('resetUserFilter', lang);
    commit('setInitialFilterTags');
  },

  async performFilterSearchIfNeeded({
    dispatch,
    getters,
    commit,
    rootGetters,
  }) {
    try {
      const esInfoMap = rootGetters['SwContextStore/getEsInfoMap'];
      if (!esInfoMap) {
        log.info('performFilterSearchIfNeeded: esInfoMap is not ready');
        return;
      }

      const filter = getters.getFilter;
      const isFilterFullyLoaded =
        !filter.isAnyCategoryEmpty && !filter.isAnyAuthorEmpty;
      if (isFilterFullyLoaded) {
        return;
      }

      commit('setFilterReady', false);

      const lang = rootGetters['SwContextStore/getLang'];

      const filterCategories = await dispatch('getFilterCategories');
      dispatch('fillInitialFilterStore', filterCategories);

      const filterPinnedAuthorsNormalized =
        getters.getFilterPinnedAuthorsNormalized;

      const pinnedFilterAuthorsByCategories = await dispatch(
        'getFilterAuthorsByCategories',
        { includeAuthors: filterPinnedAuthorsNormalized }
      );
      commit('extendFilterCategoriesWithAuthors', {
        lang,
        filter: pinnedFilterAuthorsByCategories,
      });

      const filterAuthorsByCategories = await dispatch(
        'getFilterAuthorsByCategories',
        { excludeAuthors: filterPinnedAuthorsNormalized }
      );
      commit('extendFilterCategoriesWithAuthors', {
        lang,
        filter: filterAuthorsByCategories,
      });

      for await (const [categoryName, categoryAuthors] of Object.entries(
        pinnedFilterAuthorsByCategories
      )) {
        const titles = await dispatch('getFilterTitlesByAuthorsAndCategory', {
          author: categoryAuthors.data.map(
            author =>
              author.info.collectionTitleNormalized ||
              author.info.labelNormalized
          ),
          category: categoryName,
        });
        commit('extendFilterAuthorsWithTitles', {
          lang,
          filter: titles,
        });
      }

      for await (const [categoryName, categoryAuthors] of Object.entries(
        filterAuthorsByCategories
      )) {
        const titles = await dispatch('getFilterTitlesByAuthorsAndCategory', {
          author: categoryAuthors.data.map(
            author =>
              author.info.collectionTitleNormalized ||
              author.info.labelNormalized
          ),
          category: categoryName,
        });
        commit('extendFilterAuthorsWithTitles', {
          lang,
          filter: titles,
        });
      }
      commit('setFilterReady', true);

      if (afterReloadFoundFilteredPublicationIds) {
        commit('setFoundFilteredPublicationIds', {
          lang,
          publicationIds: afterReloadFoundFilteredPublicationIds,
        });
        afterReloadFoundFilteredPublicationIds = null;
      }
    } catch (error) {
      log.info(`performFilterSearch failed with ${error}`);
      throw error;
    }
  },

  /**
   * @returns {Promise<{
   *  categories: {total: *, list: *},
   *  publications: {total: *, list: *},
   *  authors: {total: *, list: *}}
   * >}
   */
  getFilter({ rootGetters }) {
    try {
      const options = {
        language: rootGetters['SwContextStore/getLang'],
      };
      return getFilter(options);
    } catch (error) {
      log.info(`getFilter failed with ${error}`);
      throw error;
    }
  },

  getFilterCategories({ rootGetters }, { indexType } = {}) {
    try {
      const options = {
        language: rootGetters['SwContextStore/getLang'],
        indexType: indexType,
      };
      return getFilterCategories(options);
    } catch (error) {
      log.info(`getFilterCategories failed with ${error}`);
      throw error;
    }
  },

  getFilterAuthorsByCategories(
    { rootGetters },
    { includeAuthors, excludeAuthors } = {}
  ) {
    try {
      const options = {
        language: rootGetters['SwContextStore/getLang'],
        includeAuthors,
        excludeAuthors,
      };
      return getFilterAuthorsByCategories(options);
    } catch (error) {
      log.info(`getFilterAuthorsByCategories failed with ${error}`);
      throw error;
    }
  },

  getFilterTitlesByAuthors({ rootGetters }, { author, category } = {}) {
    try {
      const options = {
        language: rootGetters['SwContextStore/getLang'],
        author: author,
        category: category,
      };
      return getFilterTitlesByAuthors(options);
    } catch (error) {
      log.info(`getFilterTitlesByAuthors failed with ${error}`);
      throw error;
    }
  },

  getFilterTitlesByAuthorsAndCategory(
    { rootGetters },
    { author, category } = {}
  ) {
    try {
      const options = {
        language: rootGetters['SwContextStore/getLang'],
        author: author,
        category: category,
      };
      return getFilterTitlesByAuthorsAndCategory(options);
    } catch (error) {
      log.info(`getFilterTitlesByAuthorsAndCategory failed with ${error}`);
      throw error;
    }
  },

  getLastUserFilter({ commit, rootGetters }) {
    const lang = rootGetters['SwContextStore/getLang'];
    const lastUserFilter = localStorageService.getDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_USER_FILTER
    );
    commit('setInitialFilter', { lang, filter: lastUserFilter });
  },

  setLastUserFilter({ state }) {
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_USER_FILTER,
      state.filter
    );
  },

  removeLastUserFilter() {
    localStorageService.removeDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_USER_FILTER
    );
  },

  toggleExpanded({ commit }, filterItem) {
    commit('setExpanded', { filterItem, expanded: !filterItem.expanded });
  },

  toggleChecked({ commit }, filterItem) {
    commit('setChecked', { filterItem, checked: !filterItem.checked });
  },

  setFilterText({ commit, dispatch, rootGetters }, filterText) {
    const lang = rootGetters['SwContextStore/getLang'];
    commit('setFilterText', { lang, filterText });

    dispatch('setLastFilterText');
  },

  setLastFilterText({ state }, filterText) {
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_FILTER_TEXT,
      filterText || state.filterText
    );
  },

  getLastFilterText({ commit, rootGetters }) {
    const lastFilterText = localStorageService.getDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_FILTER_TEXT
    );
    const lang = rootGetters['SwContextStore/getLang'];
    commit('setFilterText', { lang, lastFilterText });
  },

  removeLastFilterText() {
    localStorageService.removeDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_FILTER_TEXT
    );
  },

  resetFoundFilteredPublicationIds({ rootGetters, commit }) {
    const lang = rootGetters['SwContextStore/getLang'];
    commit('setFoundFilteredPublicationIds', { lang });
  },

  async getFoundFilteredPublicationIds({ commit, rootGetters, getters }) {
    const language = rootGetters['SwContextStore/getLang'];
    const parsedQuery = JSON.parse(
      JSON.stringify(rootGetters['SwSearchStore/getParsedQuery'])
    );
    parsedQuery.filter = [];
    const publicationIds = await getFoundPublicationIds({
      language,
      parsedQuery,
    });

    const isFilterReady = getters.getFilterReady;
    if (!isFilterReady) {
      afterReloadFoundFilteredPublicationIds = publicationIds;
      return;
    }

    commit('setFoundFilteredPublicationIds', {
      lang: language,
      publicationIds,
    });
  },
};

const mutations = {
  setFilterResponse(state, filterResponse) {
    state.filterResponse = filterResponse;
  },

  setInitialFilter(state, { lang, filter, isCategoriesHidden }) {
    if (!filter) {
      return;
    }
    state.filterByLang[lang] = FilterFactory.createFilterTree(
      filter,
      lang,
      isCategoriesHidden
    );
  },

  resetUserFilter(state, lang) {
    Object.freeze(state.filterByLang[lang]);

    const filter = Object.create(state.filterByLang[lang]);
    filter.setChecked(false);
    filter.setFilterText('');
    state.filterByLang[lang] = filter;
  },

  extendFilterCategoriesWithAuthors(state, { lang, filter }) {
    if (!filter) {
      return;
    }
    Object.freeze(state.filterByLang[lang]);

    const _filter = Object.create(state.filterByLang[lang]);
    _filter.extendCategoriesWithAuthors(filter);
    state.filterByLang[lang] = _filter;
  },

  extendFilterAuthorsWithTitles(state, { lang, filter }) {
    if (!filter) {
      return;
    }
    Object.freeze(state.filterByLang[lang]);

    const _filter = Object.create(state.filterByLang[lang]);
    _filter.extendCategoriesAndAuthorsWithTitles(filter);
    state.filterByLang[lang] = _filter;
  },

  setInitialFilterTags(state) {
    state.filterTags = [];
  },

  addFilterTag(state, filterTag) {
    FilteringService.parseTagAdd({
      filter: state.filter,
      tags: state.filterTags,
      addedTag: filterTag,
    });
  },

  removeFilterTag(state, filterTag) {
    FilteringService.parseTagRemove({
      filter: state.filter,
      tags: state.filterTags,
      removedTag: filterTag,
    });
  },

  setFilterReady(state, filterReady) {
    state.filterReady = filterReady;
  },

  setCategoriesExpanded(state, { lang, expanded }) {
    state.filterByLang[lang]?.visibleChildren?.forEach(category => {
      category.setExpanded(expanded);
    });
  },

  setExpanded(state, { filterItem, expanded }) {
    filterItem.setExpanded(expanded);
  },

  setChecked(state, { filterItem, checked }) {
    filterItem.setChecked(checked);
  },

  setFilterText(state, { lang, filterText }) {
    Object.freeze(state.filterByLang[lang]);

    const filter = Object.create(state.filterByLang[lang]);
    const normalizedFilterText = filter.getNormalizedFilterText(
      filterText,
      lang
    );
    filter.setFilterText(normalizedFilterText);
    state.filterByLang[lang] = filter;
  },

  setFoundFilteredPublicationIds(state, { lang, publicationIds = {} }) {
    Object.freeze(state.filterByLang[lang]);

    const filter = Object.create(state.filterByLang[lang]);
    filter.setFoundPublicationIds(publicationIds);
    state.filterByLang[lang] = filter;
  },
};

async function getFilter(options) {
  try {
    const query = FullTextSearchFactory.createFilterQueryParams(options);
    const response = await FullTextSearchService.getFilter(query);
    return ParseResponse.parseFilterResponse({
      response,
      lang: options.language,
    });
  } catch (e) {
    log.info(`getFilter failed with ${e}`);
    return ParseResponse.parseFilterResponse({ response: {} });
  }
}

async function getFilterCategories(options) {
  try {
    const query = FullTextSearchFactory.createFilterQueryParams(options);
    const response = await FullTextSearchService.getFilterCategories(query);
    return ParseResponse.parseFilterCategoriesResponse({
      response,
      lang: options.language,
    });
  } catch (e) {
    log.info(`getFilterCategories failed with ${e}`);
    return ParseResponse.parseFilterResponse({ response: {} });
  }
}

async function getFilterAuthorsByCategories(options) {
  try {
    const query = FullTextSearchFactory.createFilterQueryParams(options);
    const response = await FullTextSearchService.getFilterAuthorsByCategories(
      query
    );
    return ParseResponse.parseFilterAuthorsByCategoriesResponse({
      response,
      lang: options.language,
      includeAuthors: options.includeAuthors,
    });
  } catch (e) {
    log.info(`getFilterAuthorsByCategories failed with ${e}`);
    return ParseResponse.parseFilterResponse({ response: {} });
  }
}

async function getFilterTitlesByAuthors(options) {
  try {
    const query = FullTextSearchFactory.createFilterQueryParams(options);
    const response = await FullTextSearchService.getFilterTitlesByAuthors(
      query
    );
    return ParseResponse.parseFilterTitlesByAuthorsResponse({
      response,
      lang: options.language,
    });
  } catch (e) {
    log.info(`getFilterTitlesByAuthors failed with ${e}`);
    return ParseResponse.parseFilterResponse({ response: {} });
  }
}

async function getFilterTitlesByAuthorsAndCategory(options) {
  try {
    const query = FullTextSearchFactory.createFilterQueryParams(options);
    const response = await FullTextSearchService.getFilterTitlesByCategoryAndAuthor(
      query
    );
    return ParseResponse.parseFilterResponse({
      response,
      lang: options.language,
    });
  } catch (e) {
    log.info(`getFilterTitlesByAuthorsAndCategory failed with ${e}`);
    return ParseResponse.parseFilterResponse({ response: {} });
  }
}

async function getFoundPublicationIds(options) {
  try {
    const query = FullTextSearchFactory.createFoundPublicationIdsQueryParams(
      options
    );
    const response = await FullTextSearchService.getFoundPublicationIds(query);
    return ParseResponse.parseFoundPublicationIdsResponse({
      response,
      lang: options.language,
    });
  } catch (e) {
    log.info(`getFoundPublicationIds failed with ${e}`);
    return ParseResponse.parseFoundPublicationIdsResponse({
      response: {},
    });
  }
}

export default {
  name: 'SwFilterStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
