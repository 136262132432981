<template>
  <span class="media-detector-common">
    <span ref="mediaDetector" class="media-detector">
      <span class="height" />
      <span class="size" />
    </span>
  </span>
</template>

<script>
  import * as log from 'loglevel';
  log.setLevel('trace');

  import layoutManager from '@/utils/layoutManager';

  const MIXIN_LAYOUT_ID = 'LayoutInMixin';

  export default {
    name: 'MediaDetector',
    components: {},
    props: {},
    data() {
      return {
        element: null,
        layoutId: null,
      };
    },
    mounted() {
      this.element = this.$refs.mediaDetector;
      this.layoutId = MIXIN_LAYOUT_ID;
      this.$_checkSize();
      this.subscribeOnLayoutInMixin(this.layoutId);
    },
    destroyed() {
      this.unsubscribeOnLayoutInMixin(this.layoutId);
    },
    methods: {
      getPropVal(element, propName) {
        return window.getComputedStyle(element).getPropertyValue(propName);
      },
      detect() {
        const media = this.getPropVal(this.element, 'font-family');
        const weight = this.getPropVal(this.element, 'font-weight');

        log.trace(media);

        const result = {
          common: {
            narrow: media === 'narrow',
            ipad: weight === '740',
            normal: media === 'normal',
            outIpad: weight === '770',
            wide: media === 'wide',
          },
        };
        return result;
      },
      subscribeOnLayoutInMixin(id) {
        layoutManager.register({
          layout: this.$_onLayoutChange,
          id: id,
        });
      },
      unsubscribeOnLayoutInMixin(id) {
        layoutManager.unregister(id);
      },
      $_onLayoutChange() {
        this.$_checkSize();
      },
      $_checkSize() {
        const media = this.detect();
        this.$store.dispatch('SwMediaDetectorStore/setMedia', media);
      },
    },
  };
</script>

<style lang="less" src="./MediaDetector.less"></style>
