<template>
  <div
    class="hits-group-bottom-container"
    :class="{
      'expanded-view': isPublicationExpanded,
    }"
    v-if="moreHitsCount"
    @click="toggleGroupExpanded"
  >
    <span>
      <span v-if="!isPublicationExpanded">
        <span>More </span>( <span>{{ moreHitsCount }}</span> )
      </span>
      <span v-else>Less</span>
      <BaseIcon icon-name="ico-arrow-solid" />
    </span>
  </div>
</template>

<script>
  import BaseIcon from '@/components/BaseIcon.vue';
  import EventEnum from '@/enums/EventEnum';

  export default {
    name: 'HitsGroupBottomContainer',
    components: {
      BaseIcon,
    },
    props: {
      source: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      moreHitsCount() {
        return Math.max(
          this.source.bookMeta?.totalResults - this.source.collapsedHitsCount,
          0
        );
      },
      isPublicationExpanded() {
        return this.$store.getters['SwHitsStore/isPublicationExpanded'](
          this.source.publicationId
        );
      },
    },
    methods: {
      toggleGroupExpanded() {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_SEPARATOR_CLICKED,
          data: {
            source: this.source,
          },
        });
      },
    },
  };
</script>
