import RangeLocator from './range-locator';
import InTextLocator from './in-text-locator';
/**
 * @constructor
 * @extends {RangeLocator}
 * @param {InTextLocator} startLocator
 * @param {InTextLocator} endLocator
 */

class InTextRangeLocator extends RangeLocator {
  constructor(startLocator, endLocator) {
    if (startLocator.constructor !== InTextLocator) {
      throw new Error(
        startLocator.constructor.name +
          ' should not be used to start InTextRangeLocator'
      );
    }
    if (endLocator.constructor !== InTextLocator) {
      throw new Error(
        endLocator.constructor.name +
          ' should not be used to end InTextRangeLocator'
      );
    }
    super(startLocator, endLocator);
  }
}

export default InTextRangeLocator;
