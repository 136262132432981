import * as log from 'loglevel';
log.setLevel('warn');

export default {
  minimizeSent(textContent) {
    const MAX_SYMBOLS_NUM = 80;
    const dots = '<span class="dots">...</span>';
    const HALF_SYMBOLS_NUM = Math.round(MAX_SYMBOLS_NUM / 2);
    const trimLeftRe = /^\S+\s/g;
    const trimRightRe = /\s\S+$/g;
    const re = /<span class="search-req">[^><]*<\/span>/g;
    textContent = textContent.replace(
      /<span class="search-quote">/g,
      '<span class="search-req">'
    );

    //find words and replace to mark with index
    const words = [];
    let index = 0;
    let title = textContent.replace(re, function(word) {
      words.push(word);
      const resp = '#word' + index + '#';
      index += 1;
      return resp;
    });

    //minimize sentence around word
    const parts = [];
    words.forEach(function(word, index) {
      const searchPart = '#word' + index + '#';
      const indexPart = title.indexOf(searchPart); //find word position in sentence
      const subStr = title.substring(0, indexPart); // get part from string
      let leftPart;
      let rightPart;
      if (subStr.length > MAX_SYMBOLS_NUM) {
        rightPart = subStr
          .substring(0, HALF_SYMBOLS_NUM)
          .replace(trimRightRe, '');
        leftPart = subStr
          .substring(subStr.length - HALF_SYMBOLS_NUM)
          .replace(trimLeftRe, '');
        if (parts.length === 0) {
          // in case first part ignore rigth part
          [].push.apply(parts, [dots, leftPart, word]);
        } else {
          [].push.apply(parts, [rightPart, dots, leftPart, word]);
        }
      } else {
        [].push.apply(parts, [subStr, word]);
      }
      title = title.substring(indexPart + searchPart.length);
    });
    if (title.length > HALF_SYMBOLS_NUM) {
      title = title.substring(0, HALF_SYMBOLS_NUM).replace(trimRightRe, '');
      [].push.apply(parts, [title, dots]);
    } else {
      parts.push(title);
    }
    return parts.join('').replace(/\s{2,}/g, ' ');
  },

  trimText(text = '', trimLen = 100, direction = -1) {
    try {
      const regexp = /(?:<span[^>]*>)[^<]*(?:<\/span>[^\s]*)*|[^\s]+/gm;
      const matches = [...text.matchAll(regexp)];
      return direction === -1
        ? this.trimStart(matches, trimLen)
        : this.trimEnd(matches, trimLen);
    } catch (error) {
      log.warn(`Trim text failed with error: ${error}`);
      return text;
    }
  },

  trimStart(words = [], trimLen = 100) {
    let result = '',
      checkLength = 0,
      count = 0;
    while (checkLength < trimLen && count < words.length) {
      const text = words[count][0].replace(/<[^>]*>?/gm, '');
      checkLength += text.trim().length;
      result += words[count][0] + ' ';
      count++;
    }
    return '... ' + result;
  },

  trimEnd(words = [], trimLen = 100) {
    let result = '',
      checkLength = 0,
      count = words.length - 1;
    while (checkLength < trimLen && count > 0) {
      const text = words[count][0].replace(/<[^>]*>?/gm, '');
      checkLength += text.trim().length;
      result = words[count][0] + ' ' + result;
      count--;
    }
    return result + ' ...';
  },

  wrapWordsWithLocatorData(text, locator) {
    if (!locator) {
      return text;
    }
    const searchReqSpans = [];
    const replaceCharsRegExp = /[/\\^$*+?.()|[\]{}]/g;
    text = text.replace(
      /([^\s]*)(<span[^>]*>[^<]*<\/[^>]*>)([^\s]*)/gim,
      function(match, $1, $2, $3) {
        searchReqSpans.push({
          before: $1 ? $1.replace(replaceCharsRegExp, '\\$&') : '',
          req: $1 + $2 + $3,
          after: $3 ? $3.replace(replaceCharsRegExp, '\\$&') : '',
        });
        return `#span-${searchReqSpans.length}#`;
      }
    );

    const words = text.split(/\s/g);
    const splitedLocator = locator.split('.');
    let startOffset = +splitedLocator[1];
    let isQuoteSpan = false;

    return words
      .map(word => {
        isQuoteSpan = false;
        if (/#span-\d+#/m.test(word)) {
          word = searchReqSpans.shift();
          isQuoteSpan = true;
        }
        const plainWord = (word?.req ?? word).replace(/<[^>]*>?/gm, '');

        const subWords = plainWord.split(/\s/g);
        const resultWord = subWords
          .map((subWord, i) => {
            const offset = subWord.length;
            const endOffset = startOffset + offset;
            const endSpace = i === subWords.length - 1 ? '&#32;' : '';
            if (word.before) {
              subWord = subWord.replace(
                new RegExp(`^(${word.before})`, 'm'),
                `<span class="search-symbol">$&</span>`
              );
            }
            if (word.after) {
              subWord = subWord.replace(
                new RegExp(`(${word.after})$`, 'm'),
                `<span class="search-symbol">$&</span>`
              );
            }
            const wrappedWord = `<span data-locator="${splitedLocator[0]}.${startOffset}.${endOffset}">&#32;${subWord}${endSpace}</span>`;
            startOffset = endOffset;
            return wrappedWord;
          })
          .join('');
        if (!isQuoteSpan) {
          return resultWord;
        }

        return `<span class="search-quote">${resultWord}</span>`;
      })
      .join(' ');
  },
  minimizeWrappedWithLocatorsSentence(textContent) {
    const dots = '<span class="dots">...</span>';
    const MAX_SYMBOLS_NUM = 80;

    textContent = textContent.replace(
      /<span class="search-quote">/g,
      '<span class="search-req">'
    );

    const checkSearchReqRegExp = /<span class="search-req">/i;
    const parts = textContent.split(/\s(?=<span)/);
    const foundParts = [];
    let tmpParts = [];

    for (const part of parts) {
      if (checkSearchReqRegExp.test(part)) {
        let invertIdx = tmpParts.length - 1;

        const leftWords = [];
        const rightWords = [];

        let sumLength = 0;
        let leftLength = 0;
        let rightLength = 0;

        if (tmpParts.length) {
          for (let i = 0; i < tmpParts.length; i++) {
            if (i >= invertIdx && foundParts.length) {
              leftWords.push(tmpParts[i]);
              break;
            }
            leftLength = tmpParts[i].replace(/<[^>]*>/gim, '').length;
            rightLength = tmpParts[invertIdx].replace(/<[^>]*>/gim, '').length;
            sumLength += leftLength + rightLength;

            leftWords.push(tmpParts[i]);
            rightWords.unshift(tmpParts[invertIdx]);

            if (sumLength >= MAX_SYMBOLS_NUM) {
              leftWords.push(dots);
              break;
            }
            invertIdx--;
          }
        }
        if (foundParts.length === 0) {
          if (rightWords.length < tmpParts.length) {
            rightWords.unshift(dots);
          }
          foundParts.push([...rightWords, part]);
        } else {
          foundParts[foundParts.length - 1] = foundParts[
            foundParts.length - 1
          ].concat(leftWords);

          rightWords.push(part);
          foundParts.push(rightWords);
        }
        tmpParts = [];
        continue;
      }
      tmpParts.push(part);
    }

    let currTmpPartsLength = 0;
    let isFinished = false;

    tmpParts = tmpParts.reduce((parts, currPart) => {
      currTmpPartsLength += currPart.replace(/<[^>]*>/gim, '').length;

      if (currTmpPartsLength <= MAX_SYMBOLS_NUM) {
        parts.push(currPart);
      } else if (!isFinished) {
        parts.push(dots);
        isFinished = true;
      }
      return parts;
    }, []);

    return foundParts.map(p => p.join(' ')).join('') + ' ' + tmpParts.join('');
  },
};
