import { FFA, OCEAN, OOL, WIDGET_NAME_MAP } from '@/constants/constants';
let start;
let end;
let selectElement;
let insert;
export default {
  correctInputIfCursorInsideWord(searchTextFromKeyboard, getters, dispatch) {
    if (!searchTextFromKeyboard) {
      return searchTextFromKeyboard;
    }
    let correctInput;
    start = 0;
    end = 0;
    selectElement = this.findElement(getters);
    start = selectElement?.selectionStart;
    end = selectElement?.selectionEnd;
    const isCopyPast = getters['SwKeyboardStore/getIsCopyPast'];
    const isBackspacePressed = this.$_checkIfBackspace(
      searchTextFromKeyboard,
      isCopyPast
    );
    const stringLength = searchTextFromKeyboard.length;
    insert = searchTextFromKeyboard[end - 1];

    if (isBackspacePressed) {
      if (selectElement) {
        correctInput =
          selectElement.value?.substring(selectElement.value.length, end) +
          selectElement.value?.substring(0, start - 1);
      } else {
        correctInput = searchTextFromKeyboard;
      }
      this.correctCursorPositionForDelete();
    } else if (isCopyPast) {
      correctInput = searchTextFromKeyboard;
    } else if (start !== 0 && start < stringLength) {
      correctInput = this.$_getCorrectInput(
        searchTextFromKeyboard,
        stringLength
      );
    } else {
      correctInput = searchTextFromKeyboard;
    }

    isCopyPast &&
      dispatch('SwKeyboardStore/setIsCopyPast', false, { root: true });

    return correctInput;
  },
  findElement(getters) {
    const widgetName = this.$_getWidgetName(getters);
    const widget = document.querySelector(widgetName);
    return widget
      ? widget.shadowRoot.querySelector('input[name="searchInput"]')
      : document.querySelector('input[name="searchInput"]');
  },

  $_getWidgetName(getters) {
    let widgetName;
    const brand = getters['SwContextStore/getBrand'];
    switch (brand) {
      case FFA:
        widgetName = WIDGET_NAME_MAP.ffa;
        break;
      case OCEAN:
        widgetName = WIDGET_NAME_MAP.ocean;
        break;
      case OOL:
        widgetName = WIDGET_NAME_MAP.ool;
        break;
    }
    return widgetName;
  },
  // correctCursorPositionForInsert() {
  //   const isCopyPast = store.getters['SwKeyboardStore/getIsCopyPast'];
  //   let position;
  //   if (isCopyPast) {
  //     position = insert?.length;
  //   } else {
  //     position = 1;
  //   }
  //   selectElement &&
  //     selectElement.setSelectionRange(start + position, end + position);
  // },
  correctCursorPositionForDelete() {
    selectElement?.setSelectionRange(start - 1, end - 1);
  },
  $_checkIfBackspace(searchTextFromKeyboard, isCopyPast) {
    return (
      !isCopyPast &&
      searchTextFromKeyboard.length < selectElement?.value?.length
    );
  },
  $_getCorrectInput(string, stringEnd) {
    const correctInput =
      string.substring(0, start - 1) +
      insert +
      string.substring(end, stringEnd);

    return correctInput;
  },
};
