<template>
  <div
    v-show="isBookSelected"
    class="search-result-item-info-block"
    :class="{ 'local-book': isTypeLibrary }"
  >
    <div class="search-result-item-info-wrapper">
      <div
        v-if="isBookRegistered && isPartialAccess"
        class="registration-overlay"
        @click="openPublication($event)"
      >
        <div class="free-reg">
          Free with registration
        </div>
      </div>
      <div :class="{ '-hidden': isMetaBlockOff }" class="item-block">
        <span v-if="bookCoverPath" class="book-cover-img">
          <img :src="bookCoverPath" />
        </span>
        <span v-if="!bookCoverPath" class="book-cover-placeholder">
          <span class="item-picture-block wide-view">
            <BaseIcon viewBox="0 0 360 472" icon-name="pic-default-cover" />
          </span>
          <span class="item-picture-block narrow-view">
            <BookIcon viewBox="0 0 360 472" :publication-type="bookMeta.type" />
          </span>
        </span>
        <div class="item-wrapper-block">
          <div class="item-text-wrapper">
            <div class="item-text-block" dir="auto">
              <div class="item-title" dir="auto">
                {{ bookMeta.title }}
              </div>
              <div class="item-text-spacer" />
              <div class="item-author" dir="auto">
                {{ bookMeta.author }}
              </div>
            </div>
          </div>

          <div class="control-wrapper-block">
            <div>
              <div
                v-if="shouldShowExternalLabel"
                ref="openLink"
                class="item-link"
                :title="openButtonTooltip"
                @click="openPublication"
              >
                <span v-hotkey="keymap" class="external-label">
                  {{ $t('BookMeta.Open.label') }}
                </span>
                <i class="item-link-icon ico-external">
                  <BaseIcon icon-name="ico-external-link" />
                </i>
              </div>
              <div
                v-if="shouldShowInternalLabel"
                ref="openLink"
                class="item-link"
                :title="openButtonTooltip"
                @click="openPublication"
              >
                <span v-hotkey="keymap" class="local-label">
                  {{ $t('BookMeta.Read.label') }}
                </span>

                <i class="item-link-icon ico-local">
                  <BaseIcon icon-name="ico-arrow-front" />
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="controls-block">
        <Navigation v-if="false" data-test="navigation" class="wide-view" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import BaseIcon from '@/components/BaseIcon.vue';
  import BookIcon from '@/components/BookIcon.vue';

  import Navigation from '@/containers/navigation/Navigation.vue';

  import PublicationVisibilityStatuses from '@/enums/PublicationVisibilityStatusesEnum.js';

  import { SUPPLEMENTAL } from '@/constants/constants';

  import * as log from 'loglevel';

  log.setLevel('info');

  export default {
    name: 'BookMeta',
    components: {
      Navigation,
      BaseIcon,
      BookIcon,
    },
    props: {
      currentDocMock: {
        type: Object,
      },
    },
    data() {
      return {
        openButtonTooltip: this.$tc('Tooltips.openButton'),
      };
    },
    computed: {
      ...mapState('SwHitsStore', ['activeHit']),
      ...mapState('SwParagraphsStore', ['paraMap']),
      ...mapState('SwContextStore', ['isPartialAccess', 'mainPopupOpened']),
      ...mapState('SwPublicationsStore', ['activePublicationOpenLink']),

      bookMeta() {
        return this.activeHit?.bookMeta || {};
      },
      isBookSelected() {
        return !!this.bookMeta?.publicationId;
      },
      bookCoverPath() {
        return this.activeHit.coverPath;
      },
      isTypeLibrary() {
        try {
          return this.activeHit.bookMeta?.urlRules?.type === 'library';
        } catch (error) {
          return false;
        }
      },
      isBookRegistered() {
        return (
          this.bookMeta?.accessStatus ===
          PublicationVisibilityStatuses.REGISTERED
        );
      },
      keymap() {
        if (!this.mainPopupOpened) {
          return false;
        }

        return {
          'ctrl+o': event => {
            event.preventDefault();

            if (this.$refs.openLink) {
              this.$refs.openLink.click();
            }
          },
        };
      },
      isMetaBlockOff() {
        return !this.$store.state.SwContextStore.isMetaBlockOn;
      },
      shouldShowExternalLabel() {
        return (
          (this.isSupplemental || this.isCopyrightVisible) &&
          this.activePublicationOpenLink &&
          this.isValidSupplUrl
        );
      },
      shouldShowInternalLabel() {
        return (
          !this.isSupplemental &&
          !this.isCopyrightVisible &&
          this.activePublicationOpenLink
        );
      },
      isSupplemental() {
        return this.activeHit?.bookMeta?.type === SUPPLEMENTAL;
      },
      isCopyrightVisible() {
        return (
          this.activeHit?.bookMeta?.accessStatus ===
          PublicationVisibilityStatuses.ENCUMBERED
        );
      },
      isValidSupplUrl() {
        const bookMeta = this.activeHit?.bookMeta || {};
        const validSourceUrl = /^https?/i.test(bookMeta?.sourceUrl || '');
        return this.isSupplemental && validSourceUrl;
      },
    },
    methods: {
      openPublication(event) {
        if (event) {
          event.stopPropagation();
        }
        this.$store.dispatch(
          'SwPublicationsStore/openPublication',
          this.activeHit
        );
      },
    },
  };
</script>

<style lang="less">
  @import 'BookMeta.less';
</style>
