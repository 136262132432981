import config from './configs/default.config.json';
import de from '../locales/de.json';
import es from '../locales/es.json';
import fr from '../locales/fr.json';
import it from '../locales/it.json';
import pt from '../locales/pt.json';
import ru from '../locales/ru.json';
import ua from '../locales/ua.json';
import en from '../locales/en.json';
import fa from '../locales/fa.json';
import ar from '../locales/ar.json';

export default {
  locale: config.defaultLanguage, // global variable holding the local language value
  fallbackLocale: config.defaultLanguage, // global variable holding fallback language value
  messages: { de, es, fr, it, pt, ru, ua, en, fa, ar }, // global variable holding translations by specified languages
};
