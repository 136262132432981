import defaultConfig from '../configs/default.config.json';
import provider from '@/provider.js';
import { UNSET_METHOD, SUPPLEMENTAL, TABLET } from '@/constants/constants';

const getLibraryExternalLink = ({ host, bookMeta }) => {
  return `${host}${bookMeta.slug || bookMeta.publicationId}`;
};

const getCombinedExternalLink = ({
  host,
  bookMeta,
  parsedResponse,
  externalLinksConfig,
}) => {
  let stemsQueryString = '';
  let quotesQueryString = '';

  if (!parsedResponse?.stems) {
    return '';
  }

  for (const stem of parsedResponse.stems) {
    stemsQueryString +=
      parsedResponse.stems.indexOf(stem) === parsedResponse.stems.length - 1
        ? `${stem}`
        : `${stem}_`;
  }

  for (const quotes of parsedResponse.quotes) {
    const quotesToString = quotes.join(' ');

    quotesQueryString +=
      parsedResponse.quotes.indexOf(quotes) === parsedResponse.quotes.length - 1
        ? `"${quotesToString}"`
        : `"${quotesToString}"_`;
  }

  const sourceUrl =
    bookMeta.type === TABLET && bookMeta.sourceUrl
      ? bookMeta.sourceUrl
      : `${host}${bookMeta.publicationId}`;

  return (
    `${sourceUrl}/` +
    `${externalLinksConfig.queryString}` +
    `${bookMeta.language}` +
    `&stems=${stemsQueryString}` +
    `&quotes=${quotesQueryString}` +
    `&paraId=${bookMeta.paraId}`
  );
};

const getEmbeddedExternalLink = ({ bookMeta }) => {
  return `${bookMeta.sourceUrl}`;
};

export default {
  /**
   * @returns {String|Boolean} - external link
   */
  getExternalLink({ getters, bookMeta, parsedResponse, brand }) {
    let externalLink = ``;

    bookMeta = bookMeta || {};

    const externalLinksConfig = defaultConfig.externalLinksConfig[brand];
    const externalHosts = getters['SwContextStore/getExternalHosts'];
    const host = externalHosts?.[brand] || externalLinksConfig?.library?.host;

    if (!bookMeta || !host) {
      return false;
    }

    if (!bookMeta?.urlRules?.type) {
      return bookMeta.sourceUrl || false;
    }

    switch (bookMeta?.urlRules?.type) {
      case 'library':
        externalLink = getLibraryExternalLink({ host, bookMeta });
        break;
      case 'combined':
        externalLink = getCombinedExternalLink({
          host,
          bookMeta,
          parsedResponse,
          externalLinksConfig,
        });
        break;
      case 'embedded':
        externalLink = getEmbeddedExternalLink({ bookMeta });
        break;
    }
    return externalLink;
  },

  async getShortenedLink(locator, bookMeta, externalLink) {
    if (bookMeta.type === SUPPLEMENTAL && !bookMeta.sourceUrl) {
      return null;
    }

    const shortenLink = await provider.getShortenLink({
      locator,
      bookId: bookMeta.publicationId,
      sourceUrl: bookMeta.sourceUrl,
      shouldGeneratePubLink: bookMeta.type !== SUPPLEMENTAL,
    });
    const isShortenerAvailable = shortenLink !== UNSET_METHOD;

    if (isShortenerAvailable && locator !== null) {
      return shortenLink;
    } else {
      return externalLink;
    }
  },
};
