<template>
  <ul class="sw-languages-list">
    <li
      v-for="(lang, index) in langs"
      :key="lang"
      data-test="change-language"
      :class="{ active: isActive(index) }"
      @click.stop.prevent="changeSearchLang(index)"
    >
      <span>{{ lang }}</span>

      <span class="icon-container">✔</span>
    </li>
  </ul>
</template>

<script>
  import { mapState } from 'vuex';

  import config from '@/configs/default.config.json';

  import {
    OOL,
    PORTUGUESE,
    FOCUS_SELECTOR_SEARCH_FIELD,
  } from '@/constants/constants';

  import provider from '@/provider.js';

  export default {
    name: 'Languages',
    data() {
      return {
        langs: (config.languages || []).map(lang => {
          if (this.brand === OOL && lang === PORTUGUESE) {
            return;
          }
          return this.$i18n.tc('Languages.' + lang);
        }),
      };
    },
    computed: {
      ...mapState('SwContextStore', ['brand', 'lang']),
    },
    methods: {
      async changeSearchLang(index) {
        this.$store.dispatch('SwContextStore/changeFocus', {
          selector: FOCUS_SELECTOR_SEARCH_FIELD,
        });

        await this.$store.dispatch(
          'SwContextStore/setLang',
          config.languages[index]
        );
        this.$store.dispatch('SwSearchStore/performSearch');
        this.$store.dispatch('SwFilterStore/performFilterSearchIfNeeded');
        return provider.changeSearchLanguage(config.languages[index]);
      },
      isActive(index) {
        return config.languages[index] === this.lang;
      },
    },
  };
</script>

<style lang="less">
  @import 'Languages.less';
</style>
