import axios from 'axios';
import QueryBuilder from '../../services/ElasticSearch/QueryBuilder';
import parseResponse from '../../services/ElasticSearch/ParseResponse';
import request from '../../services/ElasticSearch/Request';

const CORRECTION_PARA_GAPS = 1.5;

// import { elasticSearch_getParagraphsByAccessStatus } from '@/constants/actions-types';

import * as log from 'loglevel';
import RequestEnum from '@/enums/RequestEnum';

log.setLevel('info');

/**
 * function for get results by sentence hit
 * @param  {object} bookList
 * @param  {object} parsedQuery
 * @param  {object} currentDoc
 * @param  {object} options
 * @return {Promise}
 */
// function getSentenceResults(bookList, parsedQuery, currentDoc, options) {
//   extend(options, {
//     includeCurrentPara: false,
//     highlight: parsedQuery,
//   });
//   // const publicationId = currentDoc.publicationId;
//   // const numberItems = bookList[publicationId].totalResults;
//   const paragraphsByAccessStatusArguments = {
//     currentDoc: currentDoc,
//     options: options,
//   };
//   // const navigateListArguments = {
//   //   parsedQuery: parsedQuery,
//   //   publicationId: publicationId,
//   //   lang: options.lang,
//   //   numberItems: numberItems,
//   // };
//   store.dispatch(
//     elasticSearch_getParagraphsByAccessStatus,
//     paragraphsByAccessStatusArguments
//   );
// }

function getParagraphForEncumbered({
  moreTextIds,
  language,
  highlight,
  paragraphsCount,
}) {
  return getMoreText(moreTextIds, language, highlight, paragraphsCount);
}

function getMoreText(moreTextIds, lang, highlight, paragraphsCount) {
  const moreTextQuery = QueryBuilder.createMoreTextQuery(
    moreTextIds,
    highlight,
    paragraphsCount
  );
  const reqOptions = {
    nonCanceledRequest: true,
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  };

  return request.searchReq(moreTextQuery, lang, reqOptions).then(_rawDocs => {
    if (!_rawDocs) {
      return;
    }
    const paraMap = parseResponse.parseParagraphsToMapWithoutParaId({
      response: _rawDocs,
    });

    return _createParaStructureFromParaMap(paraMap);
  });
}

function _createParaStructureFromParaMap(paraMap) {
  const paraIds = Object.keys(paraMap);
  paraIds.sort((paraIdA, paraIdB) => {
    return _getParaNum(paraIdA) - _getParaNum(paraIdB);
  });

  return {
    sortedIds: paraIds,
    paraMap: paraMap,
  };
}

function _getParaNum(paraId) {
  return parseInt(paraId.split('_')[1], 10);
}

// function _fillParaId(_paraResp, currentDoc) {
//   const paraIds = Object.keys(_paraResp.paraMap);
//
//   return paraIds.find(paraId => {
//     return _paraResp.paraMap[paraId].find(doc => {
//       return doc.docId === currentDoc.docId;
//     });
//   });
// }

/**
 * function for get paragraph above and below paraId in publication
 * @param  {string} publicationId
 * @param  {string} paraId
 * @param  {number} paragraphsCount
 * @param  {string} language
 * @param  {object} options         is parameter for choose get paragraphs with current paragraph or not includeCurrentPara: true|false
 * @return {Promise}
 * @param  {string} searchText
 */
function getParagraphs({
  publicationId,
  paraId,
  paragraphsCount,
  language,
  highlight,
  accessStatus,
  searchText,
}) {
  const nextOptions = {
    highlight: highlight,
    includeCurrentPara: true,
    accessStatus: accessStatus,
  };

  return axios.all([
    getPreviousParagraphs({
      publicationId,
      paraId,
      paragraphsCount,
      language,
      highlight,
      options: nextOptions,
      searchText,
    }),
    getNextParagraphs({
      publicationId,
      paraId,
      paragraphsCount,
      language,
      highlight,
      options: nextOptions,
      searchText,
    }),
  ]);
}

/**
 * function for scroll get above paragraphs
 * @param  {string} publicationId
 * @param  {string} paraId
 * @param  {number} paragraphsCount
 * @param  {string} language
 * @param  {object} options         is parameter for choose get paragraphs with current paragraph or not includeCurrentPara: true|false
 * @param  {string} searchText
 * @return {Promise}
 */
function getPreviousParagraphs({
  publicationId,
  paraId,
  paragraphsCount,
  language,
  highlight,
  options,
  searchText,
}) {
  const rightRange = {
    start: 0,
    end: -_applyGapCorrection(paragraphsCount),
  };
  return _getParaObjByRange(
    publicationId,
    paraId,
    rightRange,
    language,
    highlight,
    paragraphsCount,
    searchText
  ).then(paraObj => {
    if (!paraObj) {
      return;
    }
    let end = paraObj.sortedIds?.length;
    let start = end - paragraphsCount;

    if (!options?.includeCurrentPara) {
      end -= 1;
      start -= 1;
    }
    start = Math.max(start, 0);
    return _createSentencesObj(paraObj, start, end);
  });
}

function _applyGapCorrection(paragraphsCount) {
  return Math.round(paragraphsCount * CORRECTION_PARA_GAPS);
}

function _getParaObjByRange(
  publicationId,
  paraId,
  directionRange,
  lang,
  highlightOptions,
  paragraphsCount,
  searchText
) {
  if (!paraId) {
    return Promise.reject(
      `Online search paraId ${paraId} for get pararagraph by directionRange ${JSON.stringify(
        directionRange
      )} is not defined for book ${publicationId}`
    );
  }
  const range = _applyDirection(paraId, directionRange);
  const paraQuery = QueryBuilder.createBookParaQuery(
    publicationId,
    range,
    highlightOptions,
    searchText
  );
  const reqOptions = {
    nonCanceledRequest: true,
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  };
  return request.searchReq(paraQuery, lang, reqOptions).then(_rawDocs => {
    if (!_rawDocs) {
      return;
    }
    const paraMap = parseResponse.parseParagraphsToMap({ response: _rawDocs });
    return _createParaStructureFromParaMap(paraMap);
  });
}

function _applyDirection(paraId, directionRange) {
  const paraNum = _getParaNum(paraId);
  const start = directionRange.start + paraNum;
  const end = directionRange.end + paraNum;
  return {
    start: Math.max(start, 0),
    end: Math.max(end, 0),
  };
}

function _createSentencesObj(paraObj, start, end) {
  const paraMap = {};
  const sortedIds = paraObj.sortedIds.slice(start, end) || [];
  sortedIds.forEach(paraId => {
    paraMap[paraId] = paraObj.paraMap[paraId];
  });
  return {
    paraMap: paraMap,
    sortedIds: sortedIds,
  };
}

/**
 * function for get paragraph below paraId in publication
 * @param  {string} publicationId
 * @param  {string} paraId
 * @param  {number} paragraphsCount
 * @param  {string} language
 * @param  {object} options         is parameter for choose get paragraphs with current paragraph or not includeCurrentPara: true|false
 * @param  {string} searchText
 * @return {Promise}
 */
function getNextParagraphs({
  publicationId,
  paraId,
  paragraphsCount,
  language,
  highlight,
  options,
  searchText,
}) {
  const leftRange = {
    start: _applyGapCorrection(paragraphsCount),
    end: 0,
  };
  return _getParaObjByRange(
    publicationId,
    paraId,
    leftRange,
    language,
    highlight,
    paragraphsCount,
    searchText
  ).then(paraObj => {
    if (!paraObj) {
      return;
    }
    let start = 0;
    let end = paragraphsCount;

    if (options && !options.includeCurrentPara) {
      start += 1;
      end += 1;
    }
    return _createSentencesObj(paraObj, start, end);
  });
}

function getNavigateList({ parsedQuery, publicationId, language, itemsCount }) {
  const from = 0;
  const bookSentencesListQuery = QueryBuilder.createBookSentencesListQuery(
    parsedQuery,
    from,
    itemsCount,
    publicationId
  );
  return request.searchReq(bookSentencesListQuery, language, {
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  });
}

/**
 * get search response based on parsedQuery
 * @param  {object} params
 * @return {Promise}
 */
function getHits(params) {
  const from = Math.max(params.startIndex, 0);
  const sentenceListQuery = QueryBuilder.createSentencesListQuery(
    params.parsedQuery,
    from,
    params.sentencesCount,
    params.publicationId,
    params.collapsed
  );
  const options = {
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  };

  return request.searchReq(sentenceListQuery, params.language, options);
}

function getFoundPublicationIds({ parsedQuery, language }) {
  const from = 0;
  const itemsCount = 0;
  const bookSentencesListQuery = QueryBuilder.createFoundPublicationIdsQuery(
    parsedQuery,
    from,
    itemsCount
  );
  return request.searchReq(bookSentencesListQuery, language, {
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  });
}

function getDocById({ docId, language }) {
  const docIdQuery = QueryBuilder.createDocIdQuery(docId);
  const options = {
    requestType: RequestEnum.REQUEST_FOR_CONTENT_INDEXES,
  };
  return request
    .searchReq(docIdQuery, language, options)
    .then(function(_rawDoc) {
      return parseResponse.parseSingleHitResponse({ response: _rawDoc });
    });
}

function getFilter({ language }) {
  const filterQuery = QueryBuilder.createFiltersQuery();
  return request.searchReq(filterQuery, language, {
    requestType: RequestEnum.REQUEST_FOR_FILTER_INDEXES,
    nonCanceledRequest: true,
  });
}

function getFilterCategories({ language }) {
  const filterQuery = QueryBuilder.createFilterCategoriesQuery();
  return request.searchReq(filterQuery, language, {
    requestType: RequestEnum.REQUEST_FOR_FILTER_INDEXES,
    nonCanceledRequest: true,
  });
}

function getFilterAuthorsByCategories({
  language,
  includeAuthors,
  excludeAuthors,
}) {
  const filterQuery = QueryBuilder.createFilterAuthorsByCategoriesQuery({
    includeAuthors,
    excludeAuthors,
  });
  return request.searchReq(filterQuery, language, {
    requestType: RequestEnum.REQUEST_FOR_FILTER_INDEXES,
    nonCanceledRequest: true,
  });
}

function getFilterTitlesByAuthors({ language, author }) {
  const filterQuery = QueryBuilder.createFilterTitlesByAuthorsQuery(author);
  return request.searchReq(filterQuery, language, {
    requestType: RequestEnum.REQUEST_FOR_FILTER_INDEXES,
    nonCanceledRequest: true,
  });
}

function getFilterTitlesByCategoryAndAuthor({ language, author, category }) {
  const filterQuery = QueryBuilder.createFilterTitlesByCatAndAuthQuery(
    category,
    author
  );
  return request.searchReq(filterQuery, language, {
    requestType: RequestEnum.REQUEST_FOR_FILTER_INDEXES,
    nonCanceledRequest: true,
  });
}

export default {
  // getSentenceResults,
  getParagraphForEncumbered,
  getMoreText,
  getParagraphs,
  getPreviousParagraphs,
  getNextParagraphs,
  getNavigateList,
  getHits,
  getDocById,
  getFilter,
  getFilterCategories,
  getFilterAuthorsByCategories,
  getFilterTitlesByAuthors,
  getFilterTitlesByCategoryAndAuthor,
  getFoundPublicationIds,
};
