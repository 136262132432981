<template>
  <input
    v-model="searchInputValue"
    type="text"
    :placeholder="placeholder"
    name="searchInput"
    required
    :disabled="!isOnline"
    @dblclick="onDblClick"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.exact.stop
    @keydown.shift.tab.exact="onShiftTabPressed"
    @keydown.tab.exact="onTabPressed"
    @keydown.enter.exact="onEnterPressed"
    @keydown.esc.exact="onEscPressed"
    @input="handleInput"
    ref="inputField"
  />
</template>

<script>
  import { FOCUS_SELECTOR_SEARCH_FIELD } from '@/constants/constants';
  import { mapState, mapGetters } from 'vuex';
  import EventEnum from '@/enums/EventEnum';

  export default {
    name: 'BaseInput',
    props: {
      placeholder: String,
    },
    data() {
      return {
        //for save last input after close/open popup and ability to clean in 10 min after last request
        searchInputValue: this.$store.getters['SwSearchStore/getSearchText'],
      };
    },
    computed: {
      ...mapState('SwOfflineModeStore', ['isOnline']),
      ...mapState('SwSearchStore', ['searchText']),
      ...mapState('SwContextStore', ['lang', 'esInfoMap', 'keyboardOpened']),
      ...mapState('SwKeyboardStore', [
        'isCopyPast',
        'isInputFromVirtualKeyboard',
      ]),
      ...mapGetters('SwKeyboardStore', ['getInput']),
      ...mapGetters('SwSearchStore', ['getShouldTriggerInput']),
      virtualKeyboardInput() {
        return this.getInput(FOCUS_SELECTOR_SEARCH_FIELD);
      },
    },
    watch: {
      searchText(value) {
        if (this.searchInputValue !== value) {
          //for text inputted from virtual keyboard. It isn't visible in field
          this.searchInputValue = value;
        }
      },
      searchInputValue(value) {
        this.$store.commit('SwKeyboardStore/setKeyboardInput', {
          field: FOCUS_SELECTOR_SEARCH_FIELD,
          input: value,
        });
        if (!value) {
          //input was deleted by pressing Backspace or Del
          this.$_emitBaseInputEvent('');
        } else if (this.isCopyPast || this.isInputFromVirtualKeyboard) {
          this.$store.dispatch(
            'SwKeyboardStore/setIsInputFromVirtualKeyboard',
            false
          );
          this.handleInput(); //for handle input after copy/past
        }
      },
      esInfoMap(newValue, oldValue) {
        if (oldValue || !this.searchInputValue) {
          return;
        }
        this.$emit('baseInputEvent', {
          type: EventEnum.QUERY_INPUTTED_AFTER_RELOAD,
          payload: {
            searchText: this.searchInputValue,
          },
        });
      },
      keyboardOpened(val) {
        if (val) {
          return;
        }
        this.$store.commit('SwKeyboardStore/setKeyboardInput', {
          field: FOCUS_SELECTOR_SEARCH_FIELD,
          input: this.searchInputValue,
        });
      },
      virtualKeyboardInput(value) {
        this.$_emitBaseInputEvent(value);
      },
    },
    mounted() {
      if (this.getShouldTriggerInput) {
        return;
      }
      if (this.searchInputValue && this.esInfoMap) {
        this.$_emitBaseInputEvent(this.searchInputValue);
      }
      this.$store.commit('SwSearchStore/setShouldTriggerInput', true);
    },
    methods: {
      handleInput(event) {
        if (!this.isOnline) {
          this.searchInputValue = null;
          return;
        }

        // for keyboard
        if (event && event.inputType === 'deleteContentBackward') {
          this.$emit('baseInputEvent', {
            type: EventEnum.BACKSPACE_PRESSED,
          });
        }
        const inputValue = event ? event.target.value : this.searchInputValue;
        this.$_emitBaseInputEvent(inputValue);
        this.$emit('baseInputEvent', {
          type: EventEnum.IS_ERROR_OCCURRED,
        });
      },
      onShiftTabPressed() {
        this.$emit('baseInputEvent', {
          type: EventEnum.SHIFT_TAB_PRESSED,
        });
      },
      onTabPressed() {
        this.$emit('baseInputEvent', {
          type: EventEnum.TAB_PRESSED,
        });
      },
      onEnterPressed(event) {
        this.handleInput(event);
        this.$emit('baseInputEvent', {
          type: EventEnum.ENTER_PRESSED,
        });
      },
      onEscPressed() {
        this.$emit('baseInputEvent', {
          type: EventEnum.ESC_PRESSED,
        });
      },
      onFocus() {
        this.$emit('baseInputEvent', {
          type: EventEnum.ON_FOCUS,
        });
      },
      onBlur() {
        this.$emit('baseInputEvent', {
          type: EventEnum.ON_BLUR,
        });
      },
      onDblClick() {
        this.$emit('baseInputEvent', {
          type: EventEnum.ON_DOUBLE_CLICK,
        });
      },
      $_emitBaseInputEvent(inputValue) {
        this.$emit('baseInputEvent', {
          type: EventEnum.QUERY_INPUTTED,
          payload: {
            searchText: inputValue,
          },
        });
      },
    },
  };
</script>
