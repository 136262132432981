<template>
  <div
    class="search-results-item-block"
    :data-sw-id="source.paraId"
    :class="{
      'regular-search-hit': source.firstGroupItem,
      active: isActiveHit,
      'not-selectable': isNotSelectable,
    }"
    @click="activateHit"
    v-if="isPublicationExpanded || source.alwaysVisibleHit"
  >
    <div class="search-results-item-block-wrapper">
      <div class="item-top-block" v-if="!source.collapsed">
        <span class="number-block">
          <span v-if="source.paraNum" data-meta>#{{ source.paraNum }}</span>
        </span>
        <span class="icon-block" @click.stop="toggleCollapsed">
          <BaseIcon icon-name="ico-arrow-solid" />
        </span>
      </div>
      <div
        v-vue-touch:start="handleTouchStart"
        v-vue-touch:end="handleTouchEnd"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
        @mouseout="handleMouseOut"
      >
        <div
          class="item-text"
          dir="auto"
          v-if="!isActiveHit || source.collapsed"
          :data-locator="source.locator"
        >
          <span class="number-block" v-if="shouldShowParaNumber" data-meta
            >#{{ source.paraNum }} &nbsp;</span
          >
          <span v-html="source.text"></span>
          <span class="dummy-non-selectable">&nbsp;</span>
        </div>
        <div
          v-else
          class="item-text"
          dir="auto"
          v-html="truncatedParagraphHtml"
        />
      </div>

      <div
        v-if="isActiveHit && !source.collapsed"
        class="more-content-link"
        @click.stop="switchToContent"
      >
        <span>{{ $t('SearchHit.MoreContent.label') }}</span>
        <BaseIcon icon-name="ico-arrow-front" />
      </div>
      <BaseLoader v-if="isLoading" class="item-loader" />
    </div>
  </div>
</template>

<script>
  import EventEnum from '@/enums/EventEnum';
  import SwitcherEnum from '@/enums/SwitcherEnum';
  import BaseIcon from '@/components/BaseIcon.vue';
  import BaseLoader from '@/components/BaseLoader.vue';
  import { mapState } from 'vuex';
  import { OOL } from '@/constants/constants';

  export default {
    name: 'SearchHitItem',
    components: {
      BaseIcon,
      BaseLoader,
    },
    props: {
      index: {
        type: Number,
      },
      source: {
        type: Object,
        default() {
          return {};
        },
      },
      activeHitIndex: {
        type: Number,
      },
      hitItemClick: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        shouldShowParaNumber: true,
      };
    },
    computed: {
      ...mapState('SwParagraphsStore', [
        'truncatedParagraphs',
        'paragraphs',
        'loadParagraphsInProcess',
      ]),
      ...mapState('SwHitsStore', ['activeHit']),
      ...mapState('SwSelectionContextStore', [
        'selectionContext',
        'selectionStartContainerSelector',
      ]),

      isNotSelectable() {
        return (
          this.selectionContext.id &&
          this.selectionContext.id !== this.source.id
        );
      },
      isActiveHit() {
        return this.activeHit.id === this.source.id;
      },
      isPublicationExpanded() {
        return this.$store.getters['SwHitsStore/isPublicationExpanded'](
          this.source.publicationId
        );
      },
      isLibraryBook() {
        return this.source?.bookMeta?.urlRules?.type === 'library';
      },
      truncatedParagraphHtml() {
        const searchParagraph = this.paragraphs?.find(
          p => p.id === this.source.paraId
        );
        return searchParagraph
          ? searchParagraph.truncatedHtml
          : this.source.text;
      },
      isLoading() {
        return this.isActiveHit && this.loadParagraphsInProcess;
      },
      media() {
        return this.$store.getters['SwMediaDetectorStore/getMedia'];
      },
      brand() {
        return this.$store.getters['SwContextStore/getBrand'];
      },
    },
    watch: {
      isActiveHit(isActive) {
        if (!isActive) {
          this.setCollapsed(true);
        }
      },
      media(val) {
        if (!val.narrow) {
          this.setCollapsed(true);
        }
      },
    },
    mounted() {
      this.shouldShowParaNumber = this.brand !== OOL;

      if (this.isActiveHit) {
        this.$emit('hitItemEvent', {
          type: EventEnum.ACTIVE_HIT_ITEM_MOUNTED,
          data: {
            index: this.index,
          },
        });
      }
    },
    methods: {
      activateHit() {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_ITEM_ACTIVATED,
          data: {
            index: this.index,
            source: { ...this.source },
          },
        });
        this.toggleCollapsed();
      },
      readBook() {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_ITEM_READ_BOOK,
          data: {
            source: { ...this.source },
          },
        });
      },
      getLocalizedNumber(number) {
        return this.$store.getters['SwContextStore/getLocalizedNumber'](number);
      },
      setCollapsed(isCollapsed) {
        this.$store.dispatch('SwHitsStore/setHitCollapsed', {
          hit: this.source,
          collapsed: isCollapsed,
        });
      },
      toggleCollapsed() {
        if (this.media.narrow) {
          this.setCollapsed(!this.source.collapsed);
        }
      },
      switchToContent() {
        this.$store.dispatch(
          'SwContextStore/setSegmentedSwitcher',
          SwitcherEnum.END_POSITION
        );
      },
      handleTouchStart(event) {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_ITEM_TOUCH_START,
          data: {
            event,
            index: this.index,
            source: { ...this.source },
          },
        });
      },
      handleTouchEnd(event) {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_ITEM_TOUCH_END,
          data: {
            event,
            index: this.index,
            source: { ...this.source },
          },
        });
      },
      handleTouchHold(event) {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_ITEM_TOUCH_HOLD,
          data: {
            event,
            index: this.index,
            source: { ...this.source },
          },
        });
      },
      handleMouseDown(event) {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_ITEM_MOUSE_DOWN,
          data: {
            event,
            index: this.index,
            source: { ...this.source },
          },
        });
      },
      handleMouseUp(event) {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_ITEM_MOUSE_UP,
          data: {
            event,
            index: this.index,
            source: { ...this.source },
          },
        });
      },
      handleMouseOut(event) {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_ITEM_MOUSE_OUT,
          data: {
            event,
            index: this.index,
            source: { ...this.source },
          },
        });
      },
    },
  };
</script>

<style></style>
