import LayoutUtils from '../services/OfflineSearch/publication/dom-utils/layout-utils';

import MarkerUtils from '../services/OfflineSearch/publication/dom-utils/marker-utils';
import Locator from '@/services/OfflineSearch/publication/locator';

const SelectionUtils = {
  findLocatorByEvent: (event, container) => {
    if (!container) {
      return null;
    }

    const { clientX, clientY } = MarkerUtils.getCoordinatesFromEvent(event);

    const elements = MarkerUtils.getParagraphElements(container);
    if (elements.length === 0) {
      return null;
    }
    const position = LayoutUtils.findLogicalPositionByPoint(
      clientX,
      clientY,
      elements,
      container,
      {
        forceLayoutUsage: false,
      }
    );

    if (position === null) {
      return null;
    }
    let isEmptyBlock = true;
    if (position.element) {
      isEmptyBlock = position.element.innerText.length === 0;
    }
    if (isEmptyBlock) {
      return null;
    }
    return LayoutUtils.convertPositionToRangeLocator(position, elements);
  },
  findStartLocatorBySelection: selection => {
    try {
      const selectionRange = selection.getRangeAt(0);
      const startLocator =
        selectionRange.startContainer.parentNode.dataset.locator ||
        selectionRange.startContainer.parentNode.closest('[data-locator]')
          ?.dataset.locator;
      return Locator.deserialize(startLocator);
    } catch (error) {
      return null;
    }
  },
  findEndLocatorBySelection: selection => {
    try {
      const selectionRange = selection.getRangeAt(0);
      const endLocator =
        selectionRange.endContainer.parentNode.dataset.locator ||
        selectionRange.startContainer.parentNode.closest('[data-locator]')
          ?.dataset.locator;
      return Locator.deserialize(endLocator);
    } catch (error) {
      return null;
    }
  },
  createRangeLocator: (start, end) => {
    const startLocator = start?.startLocator;
    const endLocator = end?.endLocator;

    if (
      startLocator._paragraphNumber > endLocator._paragraphNumber ||
      (startLocator._paragraphNumber === endLocator._paragraphNumber &&
        startLocator.logicalCharOffset > endLocator.logicalCharOffset)
    ) {
      return {
        startLocator: end.startLocator,
        endLocator: start.endLocator,
      };
    }

    return {
      startLocator: start.startLocator,
      endLocator: end.endLocator,
    };
  },
};

export default SelectionUtils;
