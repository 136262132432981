const getPluginIfInstalled = pluginName => {
  const installedPlugins =
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__?.Vue?._installedPlugins || [];
  return installedPlugins.find(plugin => plugin.name === pluginName);
};

const isInsideNuxt = () => {
  return window.__VUE_DEVTOOLS_GLOBAL_HOOK__?.Vue?.config?.$nuxt?.$nuxt;
};

export default {
  getPluginIfInstalled,
  isInsideNuxt,
};
