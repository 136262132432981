import { SelectionContext } from '@/classes/factories/SelectionContextFactory';
import selectionUtils from '@/utils/selection-utils';
import ExternalLinkService from '@/services/ExternalLinkService';

import * as log from 'loglevel';
log.setLevel('info');

import { BOOK } from '@/constants/constants';

const state = {
  selectionContext: new SelectionContext(),
  selectionStartContainerSelector: null,
};

const getters = {
  getSelectionContext: state => state.selectionContext.context,
};

const actions = {
  setSelectionStart(
    { commit, dispatch },
    {
      bookMeta,
      id,
      selectionStartEvent,
      container,
      containerSelector,
      isWrappedWithLocators = false,
    }
  ) {
    let startLocator = null;
    try {
      if (isWrappedWithLocators) {
        startLocator = '0.0.0';
      } else {
        startLocator = selectionUtils.findLocatorByEvent(
          selectionStartEvent,
          container
        );
      }
    } catch (error) {
      log.info(`Unable to get locator in shadow dom: ${error}`);
    }

    dispatch('resetSelectionContext');
    commit('setSelectionStartContainerSelector', containerSelector);
    commit('setStartLocator', startLocator);
    commit('setAuthor', bookMeta.author);
    commit('setTitle', bookMeta.title);
    commit('setPublicationId', bookMeta.publicationId);
    commit('setDocId', id);
  },
  async setSelectionEnd(
    { commit, rootGetters, state },
    { bookMeta, selectionEndEvent, container, isWrappedWithLocators = false }
  ) {
    let startLocator = null;
    let endLocator = null;
    try {
      if (isWrappedWithLocators) {
        const selection = window.getSelection();
        startLocator = selectionUtils.findStartLocatorBySelection(selection);
        commit('setStartLocator', startLocator);
        endLocator = selectionUtils.findEndLocatorBySelection(selection);
      } else {
        endLocator = selectionUtils.findLocatorByEvent(
          selectionEndEvent,
          container
        );
      }
    } catch (error) {
      log.info(`Unable to get locator in shadow dom: ${error}`);
      endLocator = state.selectionContext.startLocator;
    }

    commit('setEndLocator', endLocator);

    let link = await ExternalLinkService.getExternalLink({
      getters: rootGetters,
      bookMeta: bookMeta,
      parsedResponse: rootGetters['SwSearchStore/getParsedResponse'],
      brand: rootGetters['SwContextStore/getBrand'],
    });
    if (bookMeta.type === BOOK) {
      link = await $_getShortenedLink({ bookMeta, state, link });
    }
    commit('setLink', link);
  },
  setSelectionText({ commit }, selectionText) {
    commit('setText', selectionText);
  },
  resetSelectionContext({ commit }) {
    commit('resetSelectionContext');
    commit('setSelectionStartContainerSelector', null);
  },
};

const mutations = {
  resetSelectionContext(state) {
    state.selectionContext = new SelectionContext();
  },
  setSelectionStartContainerSelector(state, selector) {
    state.selectionStartContainerSelector = selector;
  },
  setText(state, text) {
    state.selectionContext.setText(text);
  },
  setLink(state, link) {
    state.selectionContext.setLink(link);
  },
  setAuthor(state, author) {
    state.selectionContext.setAuthor(author);
  },
  setTitle(state, title) {
    state.selectionContext.setTitle(title);
  },
  setStartLocator(state, locator) {
    state.selectionContext.setStartLocator(locator);
  },
  setEndLocator(state, locator) {
    state.selectionContext.setEndLocator(locator);
  },
  setPublicationId(state, publicationId) {
    state.selectionContext.setPublicationId(publicationId);
  },
  setDocId(state, id) {
    state.selectionContext.setDocId(id);
  },
};

async function $_getShortenedLink({ bookMeta, state, link }) {
  let shortLink = link;

  const selectionRangeLocator =
    state.selectionContext.startLocator && state.selectionContext.endLocator
      ? selectionUtils.createRangeLocator(
          state.selectionContext.startLocator,
          state.selectionContext.endLocator
        )
      : null;

  try {
    shortLink = await ExternalLinkService.getShortenedLink(
      selectionRangeLocator,
      bookMeta,
      link
    );
  } catch (error) {
    log.warn(`Shortener link error: ${error}`);
  }
  return shortLink;
}

export default {
  name: 'SwSelectionContextStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
