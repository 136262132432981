import get from 'lodash/get';

import ParagraphsFactory from '@/classes/factories/ParagraphsFactory';
import FullTextSearchFactory from '@/classes/factories/queries/FullTextSearchFactory';

import PublicationVisibilityStatuses from '@/enums/PublicationVisibilityStatusesEnum';

import FullTextSearchService from '@/services/Search/FullTextSearchService';
import ParseResponse from '@/services/ElasticSearch/ParseResponse';

import * as log from 'loglevel';
log.setLevel('info');

const DEFAULT_SORTED_IDS = [];
const DEFAULT_PARA_MAP = {};
const DEFAULT_PARAGRAPHS = [];

const state = _createInitialParagraphsState();

function _createInitialParagraphsState() {
  return {
    prevNextMarker: 'first',
    sortedIds: DEFAULT_SORTED_IDS,
    paraMap: DEFAULT_PARA_MAP,
    paragraphs: DEFAULT_PARAGRAPHS,
    loadParagraphsInProcess: false,
    isBookStart: false,
    isBookEnd: false,
  };
}

const getters = {
  getParaMap: state => {
    return state.paraMap;
  },

  getParagraphs: state => {
    return state.paragraphs;
  },

  getAmount: state => {
    return get(state, 'sortedIds', []).length;
  },

  isLoadParagraphsInProcess: state => {
    return state.loadParagraphsInProcess;
  },
};

const actions = {
  fillParagraphsStore({ commit, rootGetters }, { sentencesObj }) {
    sentencesObj = sentencesObj || {};
    const parsedQuery = rootGetters['SwSearchStore/getParsedQuery'];
    const activeHit = rootGetters['SwHitsStore/getActiveHit'];
    if (typeof sentencesObj.paraMap === 'object') {
      Object.keys(sentencesObj.paraMap).forEach(key => {
        sentencesObj.paraMap[key] = sentencesObj.paraMap[key].map(obj => {
          if (obj && typeof obj === 'object' && 'text' in obj) {
            return { ...obj, text: obj.text.replace(/\n/g, '<br/>') };
          }
          return obj;
        });
      });
    }
    commit('setSortedIds', sentencesObj.sortedIds);
    commit('setParaMap', sentencesObj.paraMap);
    commit('setParagraphs', {
      paraMap: sentencesObj.paraMap,
      parsedQuery,
      activeHit,
    });
    commit('setLoadParagraphsInProcess');
    commit('setIsBookStart');
    commit('setIsBookEnd');
  },

  resetParagraphsStore({ commit, rootGetters }) {
    const parsedQuery = rootGetters['SwSearchStore/getParsedQuery'];
    const activeHit = rootGetters['SwHitsStore/getActiveHit'];
    commit('setSortedIds');
    commit('setParaMap');
    commit('setParagraphs', {
      paraMap: {},
      parsedQuery,
      activeHit,
    });
    commit('setLoadParagraphsInProcess');
    commit('setIsBookStart');
    commit('setIsBookEnd');
  },

  async performParagraphsSearch({ dispatch }, activeHit) {
    const paragraphsSearchResponse = await dispatch('getParagraphs', {
      activeHit,
    });
    dispatch('fillParagraphsStore', {
      sentencesObj: paragraphsSearchResponse,
    });
  },

  async performParagraphsSearchPrev({ dispatch, commit, state, rootGetters }) {
    const paraId = get(state, 'paragraphs[0].id', null);
    let prevParagraphs = await dispatch('getPrevParagraphs', paraId);
    prevParagraphs = prevParagraphs || {};

    const firstParaIdInResp = get(prevParagraphs, 'sortedIds[0]', null);
    if (firstParaIdInResp === paraId) {
      commit('setIsBookStart', true);
      return;
    }
    const parsedQuery = rootGetters['SwSearchStore/getParsedQuery'];
    const activeHit = rootGetters['SwHitsStore/getActiveHit'];
    commit('setPrevParagraphs', {
      paraMap: prevParagraphs.paraMap,
      parsedQuery,
      activeHit,
    });
    commit('setPrevSortedIds', prevParagraphs.sortedIds);
    commit('extendParaMap', prevParagraphs.paraMap);
  },

  async performParagraphsSearchNext({ dispatch, commit, state, rootGetters }) {
    const paraId = get(
      state,
      `paragraphs[${state.paragraphs?.length - 1}].id`,
      null
    );
    let nextParagraphs = await dispatch('getNextParagraphs', paraId);
    nextParagraphs = nextParagraphs || {};

    const firstParaIdInResp = get(
      nextParagraphs,
      `sortedIds[${nextParagraphs.sortedIds?.length - 1}]`,
      null
    );
    if (firstParaIdInResp === paraId) {
      commit('setIsBookEnd', true);
      return;
    }
    const parsedQuery = rootGetters['SwSearchStore/getParsedQuery'];
    const activeHit = rootGetters['SwHitsStore/getActiveHit'];
    commit('setNextParagraphs', {
      paraMap: nextParagraphs.paraMap,
      parsedQuery,
      activeHit,
    });
    commit('setNextSortedIds', nextParagraphs.sortedIds);
    commit('extendParaMap', nextParagraphs.paraMap);
  },

  async getParagraphs({ rootGetters, commit }, { activeHit }) {
    try {
      commit('setLoadParagraphsInProcess', true);

      const highlight = rootGetters['SwSearchStore/getParsedQuery'];
      const language = rootGetters['SwContextStore/getLang'];
      const searchText = rootGetters['SwSearchStore/getSearchText'];
      const accessStatus = get(activeHit, 'bookMeta.accessStatus', '');
      const paragraphsCount = null;

      if (accessStatus === PublicationVisibilityStatuses.ENCUMBERED) {
        const paragraphsEncumberedSearchOptions = {
          moreTextIds: activeHit.moreTextIds,
          highlight,
          language,
          paragraphsCount,
        };
        return await getParagraphsForEncumbered(
          paragraphsEncumberedSearchOptions
        );
      }
      const paragraphsSearchOptions = {
        publicationId: activeHit.publicationId,
        paraId: activeHit.paraId,
        language,
        highlight,
        accessStatus,
        searchText,
        paragraphsCount,
      };
      return getParagraphs(paragraphsSearchOptions);
    } catch (error) {
      log.info(`getParagraphs failed with ${error}`);
      return getParagraphs({});
    }
  },

  async getPrevParagraphs({ rootGetters, commit }, paraId) {
    try {
      commit('setLoadParagraphsInProcess', true);

      const highlight = rootGetters['SwSearchStore/getParsedQuery'];
      const language = rootGetters['SwContextStore/getLang'];
      const activeHit = rootGetters['SwHitsStore/getActiveHit'];

      const paragraphsSearchOptions = {
        publicationId: activeHit.publicationId,
        paraId: paraId,
        language,
        highlight,
        accessStatus: activeHit.bookMeta.accessStatus,
        paragraphsCount: 10,
      };
      return await getPrevParagraphs(paragraphsSearchOptions);
    } catch (error) {
      log.info(`getPrevParagraphs failed with ${error}`);
    } finally {
      commit('setLoadParagraphsInProcess', false);
    }
  },

  async getNextParagraphs({ rootGetters, commit }, paraId) {
    try {
      commit('setLoadParagraphsInProcess', true);

      const highlight = rootGetters['SwSearchStore/getParsedQuery'];
      const language = rootGetters['SwContextStore/getLang'];
      const activeHit = rootGetters['SwHitsStore/getActiveHit'];

      const paragraphsSearchOptions = {
        publicationId: activeHit.publicationId,
        paraId: paraId,
        language,
        highlight,
        accessStatus: activeHit.bookMeta.accessStatus,
        paragraphsCount: 10,
      };
      return await getNextParagraphs(paragraphsSearchOptions);
    } catch (error) {
      log.info(`getNextParagraphs failed with ${error}`);
    } finally {
      commit('setLoadParagraphsInProcess', false);
    }
  },
};

const mutations = {
  setSortedIds(state, sortedIds) {
    state.sortedIds = sortedIds || DEFAULT_SORTED_IDS;
  },

  setPrevSortedIds(state, sortedIds) {
    sortedIds = sortedIds || DEFAULT_SORTED_IDS;
    state.sortedIds = [...sortedIds, ...state.sortedIds];
  },

  setNextSortedIds(state, sortedIds) {
    sortedIds = sortedIds || DEFAULT_SORTED_IDS;
    state.sortedIds = [...state.sortedIds, ...sortedIds];
  },

  setParaMap(state, paraMap) {
    state.paraMap = paraMap || DEFAULT_PARA_MAP;
  },

  extendParaMap(state, paraMap) {
    state.paraMap = { ...state.paraMap, ...paraMap };
  },

  setParagraphs(state, { paraMap, parsedQuery, activeHit } = {}) {
    if (!paraMap) {
      state.paragraphs = DEFAULT_PARAGRAPHS;
      return;
    }
    const paragraphs = prepareParagraphs(paraMap, parsedQuery, activeHit);
    state.paragraphs = [...paragraphs];
  },

  setPrevParagraphs(state, { paraMap, parsedQuery, activeHit }) {
    let paragraphs = prepareParagraphs(paraMap, parsedQuery, activeHit);
    paragraphs = paragraphs.filter(para => {
      return !state.paragraphs.some(_para => _para.id === para.id);
    });
    const mergedParagraphs = [...paragraphs, ...state.paragraphs];
    state.paragraphs = [...mergedParagraphs];
  },

  setNextParagraphs(state, { paraMap, parsedQuery, activeHit }) {
    let paragraphs = prepareParagraphs(paraMap, parsedQuery, activeHit);
    paragraphs = paragraphs.filter(para => {
      return !state.paragraphs.some(_para => _para.id === para.id);
    });

    const mergedParagraphs = [...state.paragraphs, ...paragraphs];
    state.paragraphs = [...mergedParagraphs];
  },

  setLoadParagraphsInProcess(state, loadParagraphsInProcess) {
    state.loadParagraphsInProcess = !!loadParagraphsInProcess;
  },

  setIsBookStart(state, isBookStart) {
    state.isBookStart = !!isBookStart;
  },

  setIsBookEnd(state, isBookEnd) {
    state.isBookEnd = !!isBookEnd;
  },
};

function prepareParagraphs(paraMap = {}, parsedQuery, activeHit) {
  const paragraphs = [];

  for (const [paraId, contentArray] of Object.entries(paraMap)) {
    let paragraph = paraMap[paraId][0];
    paragraph = ParagraphsFactory.createParagraph(
      paragraph,
      contentArray,
      parsedQuery,
      activeHit
    );
    paragraphs.push(paragraph);
  }
  return paragraphs;
}

async function getParagraphs(options) {
  try {
    const query = FullTextSearchFactory.createParagraphsQueryParams(options);
    const response = await FullTextSearchService.getParagraphs(query);
    return ParseResponse.parseParagraphsResponse({ response });
  } catch (e) {
    log.info(`getParagraphs failed with ${e}`);
    if (e.isAxiosError) {
      throw e;
    } else {
      return ParseResponse.parseParagraphsResponse({ response: [[], []] });
    }
  }
}

function getPrevParagraphs(options) {
  const query = FullTextSearchFactory.createParagraphsQueryParams(options);
  return FullTextSearchService.getPreviousParagraphs(query);
}

function getNextParagraphs(options) {
  const query = FullTextSearchFactory.createParagraphsQueryParams(options);
  return FullTextSearchService.getNextParagraphs(query);
}

async function getParagraphsForEncumbered(options) {
  const query = FullTextSearchFactory.createParagraphsEncumberedQueryParams(
    options
  );
  return FullTextSearchService.getParagraphForEncumbered(query);
}

export default {
  name: 'SwParagraphsStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
