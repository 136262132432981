<template>
  <span
    class="scroll-item"
    v-html="sanitizedHtml"
    :data-sp-id="source.id"
    dir="auto"
    @mousedown="handleParaMouseDownEvent"
    @mouseup="handleParaMouseUpEvent"
    v-vue-touch:start="handleParaTouchStartEvent"
    v-vue-touch:end="handleParaTouchEndEvent"
  ></span>
</template>

<script>
  import sanitizeHTML from 'sanitize-html';
  import Config from '@/configs/default.config.json';
  import EventEnum from '@/enums/EventEnum';

  export default {
    name: 'ParagraphItem',
    props: {
      index: {
        type: Number,
      },
      source: {
        type: Object,
        default() {
          return {};
        },
      },
      handleParagraphEvent: {
        type: Function,
        default: () => {},
      },
      handleParaMouseDownEvent: {
        type: Function,
        default: () => {},
      },
      handleParaMouseUpEvent: {
        type: Function,
        default: () => {},
      },
      handleParaTouchStartEvent: {
        type: Function,
        default: () => {},
      },
      handleParaTouchEndEvent: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      sanitizedHtml() {
        return sanitizeHTML(
          this.source.html.replace(
            /<span class="search-req"><span class="search-req">([()'"`]?a)<\/span><\/span>/gi,
            '<span class="search-req">$1</span>'
          ),
          {
            allowedTags: Config.sanitizeAllowedTags,
            allowedClasses: {
              span: Config.sanitizeAllowedClasses,
            },
            allowedAttributes: Config.sanitizeAllowedAttributes,
          }
        );
      },
    },
    mounted() {
      this.$_sendMountedEvent();
    },
    methods: {
      $_sendMountedEvent() {
        this.handleParagraphEvent({
          type: EventEnum.PARAGRAPH_MOUNTED,
          data: {
            index: this.index,
            paraId: this.source.id,
          },
        });
      },
    },
  };
</script>

<style scoped></style>
