var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advanced-filter",class:{ '-no-categories': !_vm.shouldShowFilterCategories }},[_c('div',{staticClass:"filter-field-block"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"},{name:"focus",rawName:"v-focus",value:(_vm.focus.filterField),expression:"focus.filterField"}],attrs:{"placeholder":"Find author or title","disabled":!(_vm.filterReady && _vm.getIsOnline)},domProps:{"value":(_vm.inputValue)},on:{"focus":_vm.changeFocus,"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),(_vm.inputValue.length)?_c('div',{staticClass:"clear-filter",on:{"click":_vm.clearInput}},[_c('BaseIcon',{attrs:{"icon-name":"ico-clear"}})],1):_vm._e()]),_c('div',{staticClass:"filter-info-block",on:{"click":_vm.setFocusToBlock}},[_c('div',[_vm._v(" Filtered documents: "),_c('span',[_vm._v(_vm._s(_vm.filterReady ? _vm.filter.checkedCount : 0))])]),_c('BaseButton',{attrs:{"disabled":!_vm.getIsOnline ||
          !(_vm.filter.hasChecked || _vm.filter.filterText || _vm.inputValue.length)},on:{"click":_vm.resetFilter}},[_vm._v("Clear all")])],1),(!_vm.isFilterApplied || _vm.filter.hasFiltered)?_c('div',{directives:[{name:"focus",rawName:"v-focus",value:(_vm.focus.filterBlock),expression:"focus.filterBlock"}],staticClass:"filter-tree-block",class:{
      'filter-applied': _vm.isFilterApplied,
    },on:{"click":_vm.setFocusToBlock}},_vm._l((_vm.filter.visibleChildren),function(category){return _c('div',{key:category.id,staticClass:"filter-first-level -filter-item",class:{
        expanded: category.expanded,
        '-no-category-items': _vm.filter.filterText && !category.hasFiltered,
        '-has-checked': category.hasChecked,
      }},[(_vm.shouldShowFilterCategories)?_c('SearchFilterItem',{directives:[{name:"show",rawName:"v-show",value:(category.isVisible),expression:"category.isVisible"}],attrs:{"filter-item":category},on:{"filterItemEvent":_vm.handleFilterItemEvent}}):_vm._e(),_vm._l((category.visibleChildren),function(author){return _c('div',{key:author.id,staticClass:"filter-second-level -filter-item",class:{
          expanded: author.expanded,
          '-has-checked': author.hasChecked,
        }},[_c('SearchFilterItem',{directives:[{name:"show",rawName:"v-show",value:(author.isVisible),expression:"author.isVisible"}],attrs:{"filter-item":author,"extra-label":_vm.getExtraLabelIfNeeded(author)},on:{"filterItemEvent":_vm.handleFilterItemEvent}}),(_vm.shouldShowNestedItems(author))?_c('section',_vm._l((author.visibleChildren),function(title){return _c('div',{key:title.id,staticClass:"filter-third-level -filter-item"},[_c('SearchFilterItem',{directives:[{name:"show",rawName:"v-show",value:(title.isVisible),expression:"title.isVisible"}],attrs:{"filter-item":title},on:{"filterItemEvent":_vm.handleFilterItemEvent}})],1)}),0):_vm._e()],1)})],2)}),0):_c('div',{staticClass:"filter-no-results"},[_c('BaseIcon',{attrs:{"icon-name":"pic-no-results"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }