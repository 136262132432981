<template>
  <div
    class="hits-group-top-container"
    @click="toggleGroupExpanded"
    :data-publication-id="source.publicationId"
  >
    <div class="item-book-name-block">
      <img v-if="coverPath" alt="publication-cover" :src="coverPath" />
      <BookIcon v-else :publication-type="source.type" />
      <div class="item-book-name" dir="auto">
        <span class="item-book-name-title">{{ source.bookMeta.title }}</span>
        <span
          class="item-book-name-author have-title"
          :class="{ 'is-empty': !source.bookMeta.author }"
        >
          <span>{{ source.bookMeta.author }}</span>
        </span>
      </div>
    </div>
    <div class="item-book-total-results">
      {{ source.bookMeta.totalResults }}
    </div>
    <BaseLoader v-if="isLoading" class="item-loader" />
  </div>
</template>

<script>
  import BookIcon from '@/components/BookIcon.vue';
  import BaseLoader from '@/components/BaseLoader.vue';

  import provider from '@/provider';
  import { mapState } from 'vuex';

  import { STICKY_CLASS_NAME } from '@/constants/constants';
  import EventEnum from '@/enums/EventEnum';

  export default {
    name: 'HitsGroupTopContainer',
    components: {
      BookIcon,
      BaseLoader,
    },
    props: {
      source: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        coverPath: null,
      };
    },
    computed: {
      ...mapState('SwHitsStore', [
        'loadHitsByPublicationInProcess',
        'expandedPublicationId',
      ]),

      isLoading() {
        return (
          this.loadHitsByPublicationInProcess &&
          this.source?.publicationId === this.expandedPublicationId
        );
      },
    },
    async mounted() {
      this.$el.closest('.list-item')?.classList.add(STICKY_CLASS_NAME);
      this.coverPath = await provider.getCoverPath(this.source?.publicationId);
    },
    methods: {
      toggleGroupExpanded() {
        this.$emit('hitItemEvent', {
          type: EventEnum.HIT_SEPARATOR_CLICKED,
          data: {
            source: this.source,
          },
        });
      },
    },
  };
</script>
