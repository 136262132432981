<template>
  <div class="search-field-wrapper" @paste.prevent="transformPasteText">
    <slot>
      <BaseInput
        id="search-input"
        v-focus="focus.searchField"
        autofocus
        tabindex="0"
        :placeholder="$tc(placeholder)"
        @baseInputEvent="handleBaseInputEvent"
      />
    </slot>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import CopyService from '@/services/CopyService.js';
  import { focus } from 'vue-focus';
  import BaseInput from '@/components/BaseInput.vue';
  import config from '@/configs/default.config.json';
  import EventEnum from '@/enums/EventEnum';
  import {
    // FOCUS_SELECTOR_HITS,
    // FOCUS_SELECTOR_PARAGRAPHS,
    FOCUS_SELECTOR_SEARCH_FIELD,
  } from '@/constants/constants';

  // let nextFocusElement;

  export default {
    name: 'SearchField',
    directives: {
      focus: focus,
    },
    components: {
      BaseInput,
    },
    data: () => ({
      prevSearchText: '',
    }),
    computed: {
      ...mapState('SwContextStore', ['brand', 'lang', 'focus']),
      ...mapState('SwOfflineModeStore', ['isOffline']),
      ...mapState('SwKeyboardStore', ['isCopyPast']),
      ...mapState('SwSearchStore', ['searchText']),
      placeholder() {
        let placeholder;
        if (this.brand === config.brands.ool) {
          placeholder = 'BaseInput.placeholderOOL';
        } else {
          placeholder = 'BaseInput.placeholder';
        }
        return placeholder;
      },
    },
    methods: {
      async handleBaseInputEvent(data) {
        switch (data?.type) {
          case EventEnum.QUERY_INPUTTED:
            if (this.prevSearchText === data?.payload?.searchText) {
              return;
            }
            this.$store.dispatch(
              'SwSearchStore/setSearchText',
              data.payload.searchText
            );
            await this.$store.dispatch('SwSearchStore/performSearch');
            this.prevSearchText = data?.payload?.searchText;
            break;
          case EventEnum.BACKSPACE_PRESSED:
            this.$store.dispatch('SwKeyboardStore/setIsBackspacePressed', true);
            break;
          case EventEnum.IS_ERROR_OCCURRED:
            this.$store.dispatch('SwErrorStore/setIsErrorOccurred', false);
            break;
          case EventEnum.QUERY_INPUTTED_AFTER_RELOAD:
            await this.$store.dispatch(
              'SwSearchStore/setSearchText',
              data.payload.searchText
            );
            await this.$store.dispatch('SwSearchStore/performSearch');
            break;
          case EventEnum.SHIFT_TAB_PRESSED:
            this.setShiftTabPressed();
            break;
          case EventEnum.TAB_PRESSED:
            this.setTabPressed();
            break;
          case EventEnum.ENTER_PRESSED:
            this.setEnterPressed();
            this.setFakeBlur();
            break;
          case EventEnum.ESC_PRESSED:
            this.setEscPressed();
            break;
          case EventEnum.ON_FOCUS:
            this.setFocus();
            break;
          case EventEnum.ON_BLUR:
            this.setFakeBlur();
            break;
          case EventEnum.ON_DOUBLE_CLICK:
            this.selectQuery();
            break;
          default:
            break;
        }
      },

      selectQuery: function() {
        this.setFocus();
      },

      setFocus(selector) {
        if (selector) {
          this.$_changeFocus(selector);
          return;
        }

        if (!this.focus.searchField) {
          this.$_changeFocus(FOCUS_SELECTOR_SEARCH_FIELD);
        }

        this.$emit('searchFieldEvent', {
          type: EventEnum.ON_FOCUS,
        });
      },

      setShiftTabPressed() {
        // nextFocusElement = FOCUS_SELECTOR_PARAGRAPHS;
      },

      setTabPressed() {
        // nextFocusElement = FOCUS_SELECTOR_HITS;
      },

      setEnterPressed() {
        // nextFocusElement = FOCUS_SELECTOR_HITS;
      },

      setEscPressed() {
        this.$store.dispatch('SwContextStore/setMainPopupOpened', false);
      },

      setFakeBlur() {
        this.$emit('searchFieldEvent', {
          type: EventEnum.ON_BLUR,
        });
        // TODO rework hotkeys
        // this.$_changeFocus(nextFocusElement);
      },

      transformPasteText(e) {
        this.$store.dispatch('SwKeyboardStore/setIsCopyPast', true);

        const copyText = CopyService.getTransformedPasteText(
          e,
          this.searchText
        );
        this.$store.dispatch('SwSearchStore/setSearchText', copyText);
      },

      $_changeFocus(selector) {
        this.$store.dispatch('SwContextStore/changeFocus', {
          selector,
        });
      },
    },
  };
</script>

<style lang="less">
  .search-field-wrapper {
    display: flex;
    flex-grow: 1;

    input {
      margin-top: 2px;
    }
  }
</style>
