import PublicationLocator from './publication-locator';

/**
 *
 * @constructor
 * @extends {PublicationLocator}
 * @param {PublicationLocator} startLocator
 * @param {PublicationLocator} endLocator
 */
export default class RangeLocator extends PublicationLocator {
  constructor(startLocator, endLocator) {
    super();
    if (endLocator.precedes(startLocator)) {
      throw new Error(
        'Incorrect RangeLocator: end ' +
          endLocator +
          ' should never precede start ' +
          startLocator
      );
    }

    this.startLocator = startLocator;
    this.endLocator = endLocator;
  }
  /**
   *
   * @param {RangeLocator|PublicationLocator} locator
   * @returns {number}
   */
  compareTo(locator) {
    let comparisonResult;
    if (locator instanceof RangeLocator) {
      comparisonResult =
        this.startLocator.compareTo(locator.startLocator) ||
        this.endLocator.compareTo(locator.endLocator);
    } else {
      comparisonResult =
        this.startLocator.compareTo(locator) > 0
          ? 1
          : this.endLocator.compareTo(locator) < 0
          ? -1
          : 0;
    }
    return comparisonResult;
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {boolean}
   */
  contains(locator) {
    return !(
      this.startLocator.follows(locator) || this.endLocator.precedes(locator)
    );
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {boolean}
   */
  startsFrom(locator) {
    return this.startLocator.equals(locator);
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {boolean}
   */
  endsWith(locator) {
    return this.endLocator.equals(locator);
  }

  /**
   *
   * @returns {boolean}
   */
  isCollapsed() {
    return this.startLocator.equals(this.endLocator);
  }
}
