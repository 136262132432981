var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"read-mode-search-popup",class:{
      'expanded-view': _vm.expandedView,
      'expanded-infinite': _vm.expandedInfinite,
      'search-info-opened': _vm.infoOpened,
      'keyboard-opened': _vm.keyboardOpened,
      'multi-lang': _vm.multiLanguageBrand,
      'is-dirty': _vm.isDirty,
    }},[_c('div',{directives:[{name:"hotkey",rawName:"v-hotkey.self",value:(_vm.keymap),expression:"keymap",modifiers:{"self":true}}],staticClass:"read-mode-search-popup-wrapper"},[_c('TopBar',{attrs:{"data-test":"top-bar"}}),_c('BottomBar',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBottomBar),expression:"showBottomBar"}],attrs:{"data-test":"bottom-bar"}}),_c('BaseErrorWindow',{directives:[{name:"show",rawName:"v-show",value:(_vm.isErrorOccurred),expression:"isErrorOccurred"}],attrs:{"data-test":"error"}})],1)]),_c('div',{staticClass:"popup-backdrop",attrs:{"data-test":"backdrop"},on:{"click":_vm.closePopup}})])
}
var staticRenderFns = []

export { render, staticRenderFns }