import BookMetaFactory from '@/classes/factories/BookMetaFactory';
import SearchService from '@/services/SearchService';
import SearchStemsWrapperService from '@/services/SearchStemsWrapperService';
import utils from '@/utils/utils';
import {
  REGULAR_HIT_TYPE,
  TOP_SEPARATOR_TYPE,
  BOTTOM_SEPARATOR_TYPE,
} from '@/constants/constants';

class HitItem {
  constructor(data = {}) {
    this.type = REGULAR_HIT_TYPE;
    this.id = data.id;
    this.firstGroupItem = data.firstGroupItem;
    this.bookMeta = data.bookMeta || {};
    this.text = data.text || '';
    this.paraId = data.paraId;
    this.publicationId = data.publicationId;
    this.slug = data.slug;
    this.moreTextIds = data.moreTextIds;
    this.paraNum = data.paraNum || null;
    this.alwaysVisibleHit = data.alwaysVisibleHit || false;
    this.collapsed = data.collapsed || true;
    this.coverPath = data.coverPath || '';
    this.locator = data.locator || '';
  }

  setBookMeta(bookMeta) {
    this.bookMeta = bookMeta;
    return this;
  }

  setAlwaysVisibleHit(alwaysVisibleHit) {
    this.alwaysVisibleHit = alwaysVisibleHit;
    return this;
  }

  setCollapsed(collapsed) {
    this.collapsed = collapsed;
    return this;
  }
}

class HitItemBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setBookMeta(bookMeta) {
    this.bookMeta = bookMeta;
    return this;
  }

  setText(highlight, paragraphs, locator) {
    if (!highlight) {
      return this;
    }

    highlight = SearchService.wrapWordsWithLocatorData(highlight, locator);
    highlight = SearchService.minimizeWrappedWithLocatorsSentence(highlight);

    const paragraphNumber =
      '<span class="paragraph-number" data-meta>' + paragraphs + '</span>';
    this.text =
      paragraphNumber +
      SearchStemsWrapperService.highlightSearchResultInSentence(highlight);
    return this;
  }

  setFirstGroupItem(firstGroupItem) {
    this.firstGroupItem = firstGroupItem;
    return this;
  }

  setParaId(paraId, locator = '') {
    if (!paraId) {
      const paraNum = locator.split('.')[0];
      paraId = `para_${paraNum}`;
    }
    this.paraId = paraId;
    return this;
  }

  setParaNum(paraId) {
    this.paraNum = utils.getParagraphNumberFromParaId(paraId);
    return this;
  }

  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }

  setSlug(slug) {
    this.slug = slug;
    return this;
  }

  setMoreTextIds(moreTextIds) {
    this.moreTextIds = moreTextIds;
    return this;
  }

  setAlwaysVisibleHit(alwaysVisibleHit) {
    this.alwaysVisibleHit = alwaysVisibleHit;
    return this;
  }

  setLocator(locator) {
    this.locator = locator;
    return this;
  }

  build() {
    return new HitItem(this);
  }
}

const createHitItem = (data = {}) => {
  const bookMeta = BookMetaFactory.createBookMeta(data);

  return new HitItemBuilder()
    .setId(data.docId)
    .setBookMeta(bookMeta)
    .setText(data.highlight, data.paragraphs, data.locator)
    .setFirstGroupItem(data.firstGroupItem)
    .setParaId(data.paraId, data.locator)
    .setParaNum(data.paraId)
    .setPublicationId(data.publicationId)
    .setSlug(data.slug)
    .setMoreTextIds(data.moreTextIds)
    .setAlwaysVisibleHit(data.alwaysVisibleHit)
    .setLocator(data.locator)
    .build();
};

class HitItemSeparator extends HitItem {
  constructor(data = {}) {
    super(data);
    this.collapsedHitsCount = data.collapsedHitsCount;
    this.isSeparator = true;
  }
}

class HitItemTopSeparator extends HitItemSeparator {
  constructor(data = {}) {
    super(data);
    this.type = TOP_SEPARATOR_TYPE;
    this.id = `top-separator-${utils.generateShortUuid()}`;
  }
}

class HitItemBottomSeparator extends HitItemSeparator {
  constructor(data = {}) {
    super(data);
    this.type = BOTTOM_SEPARATOR_TYPE;
    this.id = `bottom-separator-${utils.generateShortUuid()}`;
  }
}

const createHitItemTopSeparator = data => {
  const bookMeta = BookMetaFactory.createBookMeta(data);
  return new HitItemTopSeparator(data)
    .setBookMeta(bookMeta)
    .setAlwaysVisibleHit(true);
};

const createHitItemBottomSeparator = data => {
  const bookMeta = BookMetaFactory.createBookMeta(data);
  return new HitItemBottomSeparator(data)
    .setBookMeta(bookMeta)
    .setAlwaysVisibleHit(true);
};

export default {
  createHitItem,
  createHitItemTopSeparator,
  createHitItemBottomSeparator,
};
