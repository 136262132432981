<template>
  <div class="additional-title-content">
    <div v-if="totalHits" class="results-info">
      {{
        $t('TopBar.BooksMatchesWereFound.label', {
          booksTotal: totalPublications,
          sentencesTotal: totalHits,
        })
      }}
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'HitsAdditionalTitle',
    computed: {
      ...mapState('SwSearchStore', ['totalPublications', 'totalHits']),
    },
  };
</script>

<style lang="less">
  @import '../../assets/styles/variables.less';

  .additional-title-content {
    line-height: 24px;
  }
</style>
