import XRegExp from 'xregexp';

import {
  FRAGMENT_SIZE,
  MAX_BOOST,
  MIN_BOOST,
  MAX_SLOP,
  INNER_HITS_COUNT,
} from '@/constants/constants';

const BOOK_PRIORITY = MAX_BOOST * 0.4;
const EXACT_BOOST = MAX_BOOST - BOOK_PRIORITY;
const complicatedNamesRe = new XRegExp(
  "(?:([’'`-])[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]\\1)|(?:^|[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF])[’'`-]+(?:[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]|$)"
);

const createMustQuery = (...args) => {
  const [standAloneWord] = args;
  return {
    match: {
      text: {
        query: standAloneWord,
      },
    },
  };
};

const getMustNotQuery = (...args) => {
  const [blackList] = args;
  return blackList.map(createMustQuery);
};

const getStandAloneWordsQuery = (...args) => {
  const [standAloneWords] = args;
  const queryWords = [];

  standAloneWords.forEach(standAloneWord => {
    standAloneWord = standAloneWord.replace(/[‘’`'-]/g, '');
    queryWords.push(standAloneWord);
  });

  return queryWords.map(standAloneWord => {
    return {
      bool: {
        should: [
          {
            match_bool_prefix: {
              text: standAloneWord,
            },
          },
          {
            term: {
              text: standAloneWord,
            },
          },
        ],
      },
    };
  });
};

/**
 * get query for search by transliteration words
 */
const getPhoneticQuery = (...args) => {
  const [phoneticWords] = args;
  if (phoneticWords?.length === 0) {
    return [];
  }
  return [
    {
      match: {
        text: {
          query: phoneticWords.join(' '),
          operator: 'or',
          boost: MIN_BOOST,
        },
      },
    },
  ];
};

const createMatchPhraseQuery = (...args) => {
  const [quote, boost] = args;
  return {
    match_phrase: {
      'text.exact': {
        query: quote,
        boost: boost || MIN_BOOST,
      },
    },
  };
};

/**
 * get query for search by quotes
 */
const getPhraseQuery = (...args) => {
  const [quotes] = args;
  return quotes.map(createMatchPhraseQuery);
};

const createQueryString = (...args) => {
  const [str, field, boost, isFilterQueryString] = args;
  const queryString = isFilterQueryString ? `${str}` : `*${str}* OR ${str}`;
  return {
    query_string: {
      default_field: field,
      query: queryString,
      default_operator: 'AND',
      boost: boost,
    },
  };
};

const createMetaDataQuery = (meta, boost) => {
  return [
    createQueryString(meta, 'author.exact', boost),
    createQueryString(meta, 'title.exact', boost),
    createQueryString(meta, 'category.exact', boost),
  ];
};

const getMetaDataQuery = metaData => {
  const metaQuery = [];

  metaData.forEach(meta => {
    [].push.apply(metaQuery, createMetaDataQuery(meta, EXACT_BOOST));
  });

  return {
    bool: {
      should: metaQuery,
    },
  };
};

const getBookIdQuery = bookId => {
  if (!bookId) {
    return [];
  }
  return [
    {
      match: {
        publicationId: bookId,
      },
    },
  ];
};

const getMustQuery = (...args) => {
  const [standAloneWords, quotes, metaData, bookId, phoneticWords] = args;
  const standAloneWordsQuery = getStandAloneWordsQuery(standAloneWords);
  const phoneticWordsQuery = getPhoneticQuery(phoneticWords);
  const phraseQuery = getPhraseQuery(quotes);
  const metaDataQuery = getMetaDataQuery(metaData);
  const bookIdQuery = getBookIdQuery(bookId);

  return [].concat.apply(
    [],
    [
      standAloneWordsQuery,
      phraseQuery,
      metaDataQuery,
      bookIdQuery,
      phoneticWordsQuery,
    ]
  );
};

/**
 * Check if function unneeded in future
 *
  const removeSpecialFilterChars = string => {
    return string.replace(/[()[\]!?/\\:;><=+\-*~|]+/g, ' ');
  };
 */

const createFilterQuery = (...args) => {
  const [filter] = args;
  const categoryKey = 'publicationId';

  if (!filter.length) {
    return null;
  }
  return { terms: { [categoryKey]: filter } };
};

const getFilterQuery = (...args) => {
  const [filter] = args;
  const filterQuery = [];

  if (filter?.length) {
    filterQuery.push(createFilterQuery(filter));
  }
  return filterQuery;
};

/**
 * create main query for search with necessary parts,
 * black list words which need remove and desirable to have
 */
const getSearchQuery = (...args) => {
  const [
    standAloneWords,
    blackList,
    quotes,
    metaData,
    phoneticWords,
    bookId,
    filter,
  ] = args;
  return {
    query: {
      bool: {
        must_not: getMustNotQuery(blackList),
        must: getMustQuery(
          standAloneWords,
          quotes,
          metaData,
          bookId,
          phoneticWords
        ),
        should: [],
        filter: getFilterQuery(filter),
      },
    },
  };
};

const createHighlightObj = (...args) => {
  const [mustQuery] = args;
  return {
    bool: {
      must: mustQuery,
    },
  };
};

/**
 * function for temporary fix quotes highlight
 */

const createHighlightQueryFromSearchQuery = (...args) => {
  const [searchQuery] = args;
  const highlightQuery = JSON.parse(JSON.stringify(searchQuery.query));
  const wordsQuery = [];
  const quotesQuery = [];
  const bool = highlightQuery.bool;

  bool.must.forEach(function(query) {
    if (query.hasOwnProperty('match_phrase')) {
      quotesQuery.push(query);
    } else {
      wordsQuery.push(query);
    }
  });

  return {
    words: createHighlightObj(wordsQuery),
    quotes: createHighlightObj(quotesQuery),
  };
};

const getBoostedStandAloneWordsQuery = (...args) => {
  const [standAloneWords] = args;
  return standAloneWords.map(standAloneWord => {
    return createMatchPhraseQuery(standAloneWord, EXACT_BOOST);
  });
};

const createSlopQuery = (...args) => {
  const [query, slop, boost] = args;

  return {
    match_phrase: {
      text: {
        query: query,
        slop: slop,
        boost: boost,
      },
    },
  };
};

const getSlopQuery = (...args) => {
  const [standAloneWords] = args;

  if (standAloneWords && standAloneWords.length <= 1) {
    return [];
  }

  const query = [];
  const MIN_SLOP_BOOST = 8;
  let queryString;
  if (complicatedNamesRe.test(standAloneWords[0])) {
    queryString = standAloneWords[0];
  } else {
    queryString = standAloneWords.join(' ');
  }

  for (let i = 0; i <= MAX_SLOP; i++) {
    query.push(
      createSlopQuery(queryString, i, MIN_SLOP_BOOST - Number('0.' + i))
    );
  }
  return query;
};

const addSortPriority = (...args) => {
  const [searchQuery, standAloneWords, phraseWithStopWords] = args;
  const exactMatchPriority = getBoostedStandAloneWordsQuery(standAloneWords);
  const phraseWithStopWordsExactMatch = createMatchPhraseQuery(
    phraseWithStopWords || '',
    10
  );
  const slopQuery = getSlopQuery(standAloneWords);
  const should = [].concat.apply(
    [],
    [exactMatchPriority, phraseWithStopWordsExactMatch, slopQuery]
  );

  Object.assign(searchQuery.query.bool, {
    should: should,
  });
  return searchQuery;
};

const wrapInScoreFunction = (...args) => {
  /* eslint-disable-next-line */
  const [searchQuery, from, size, parsedQuery] = args;
  const matchFilter = {
    publicationId: parsedQuery.openedPublicationId || '',
  };

  return {
    query: {
      function_score: {
        query: searchQuery.query,
        functions: [
          {
            filter: {
              match: matchFilter,
            },
            weight: 5000,
          },
          {
            script_score: {
              script: {
                params: {
                  multiplier: 0.2,
                  minWeight: 11,
                },
                source:
                  "_score + (_score * ((params.minWeight - doc['weight'].value) * params.multiplier))",
              },
            },
          },
        ],
        score_mode: 'max',
        boost_mode: 'max',
      },
    },
  };
};

const createHighlightConfig = (...args) => {
  const [highlightQuery, highlightClass] = args;

  return {
    type: 'fvh',
    pre_tags: ['<span class="' + highlightClass + '">'],
    post_tags: ['</span>'],
    number_of_fragments: 0,
    fragment_size: FRAGMENT_SIZE,
    highlight_query: highlightQuery,
  };
};

const addHighlightQuery = (...args) => {
  const [searchQuery, highlightQuery] = args;

  return {
    ...searchQuery,
    ...{
      highlight: {
        fields: {
          text: createHighlightConfig(highlightQuery.words, 'search-req'),
          'text.exact': createHighlightConfig(
            highlightQuery.quotes,
            'search-quote'
          ),
        },
      },
    },
  };
};

const addSizeInfo = (...args) => {
  const [searchQuery, size, from] = args;
  const sizeInfo = {};
  if (typeof from === 'number') {
    sizeInfo.from = from;
  }
  if (typeof size === 'number') {
    sizeInfo.size = size;
  }
  return { ...searchQuery, ...sizeInfo };
};

const addCollapseByPubIdQuery = (...args) => {
  const [searchQuery, size] = args;

  return {
    ...searchQuery,
    ...{
      collapse: {
        field: 'publicationId',
        inner_hits: {
          name: 'cluster',
          size: size || INNER_HITS_COUNT,
          highlight: searchQuery.highlight,
        },
      },
    },
  };
};

const getTotalAggByFieldName = (...args) => {
  const [fieldName] = args;
  return {
    cardinality: {
      field: fieldName,
    },
  };
};

const addTotalResultsAggsQuery = (...args) => {
  const [searchQuery] = args;

  return {
    ...searchQuery,
    ...{ aggs: { totalPublications: getTotalAggByFieldName('publicationId') } },
  };
};

const addPubIdsAggsQuery = (...args) => {
  const [searchQuery] = args;

  return {
    ...searchQuery,
    ...{
      aggs: {
        publicationIds: {
          terms: {
            field: 'publicationId',
            size: 10000,
          },
        },
      },
    },
  };
};

const createSentencesListQuery = (...args) => {
  const [parsedQuery, from, size, publicationId, collapsed] = args;

  let query = getSearchQuery(
    parsedQuery.standAloneWords,
    parsedQuery.blackList,
    parsedQuery.quotes,
    parsedQuery.metaData,
    parsedQuery.phoneticWords,
    publicationId,
    parsedQuery.filter
  );
  const highlightQuery = createHighlightQueryFromSearchQuery(query);
  query = addSortPriority(
    query,
    parsedQuery.standAloneWords,
    parsedQuery.phraseWithStopWords
  );
  query = wrapInScoreFunction(query, from, size, parsedQuery);
  query = addHighlightQuery(query, highlightQuery);
  query = addSizeInfo(query, size, from);

  if (collapsed) {
    query = addCollapseByPubIdQuery(query);
    query = addTotalResultsAggsQuery(query);
    query.track_total_hits = true;
  }
  return query;
};

const createFoundPublicationIdsQuery = (...args) => {
  const [parsedQuery, from, size] = args;
  let query = getSearchQuery(
    parsedQuery.standAloneWords,
    parsedQuery.blackList,
    parsedQuery.quotes,
    parsedQuery.metaData,
    parsedQuery.phoneticWords,
    null,
    parsedQuery.filter
  );
  query = addSizeInfo(query, size, from);
  query = addPubIdsAggsQuery(query);
  return query;
};

const addHighlight = (...args) => {
  let query = args[0];
  const highlightOptions = args[1];
  const searchText = args[2];

  let highlightQuery = getSearchQuery(
    highlightOptions.standAloneWords,
    [],
    highlightOptions.quotes,
    [],
    highlightOptions.phoneticWords,
    '',
    {}
  );
  highlightQuery = createHighlightQueryFromSearchQuery(
    highlightQuery,
    searchText
  );
  query = addHighlightQuery(query, highlightQuery);
  return query;
};

/**
 * query for get sentences list by ids
 */
const createMoreTextQuery = (...args) => {
  const [sentIds, highlightOptions, numberParagraphs] = args;
  const paramsIds = JSON.parse(JSON.stringify(sentIds)).reverse();
  let query = {
    size: numberParagraphs,
    query: {
      function_score: {
        query: {
          ids: {
            values: sentIds,
          },
        },
        script_score: {
          script: {
            lang: 'painless',
            params: {
              ids: paramsIds,
            },
            inline: "return params.ids.indexOf(doc['docId'].value)",
          },
        },
      },
    },
  };
  if (highlightOptions) {
    query = addHighlight(query, highlightOptions);
  }
  return query;
};

const createFiltersQuery = () => {
  return {
    size: 0,
    aggs: {
      genres: {
        terms: {
          field: 'category',
          order: { _key: 'asc' },
          size: 100,
        },
        aggs: {
          authors: {
            terms: {
              field: 'author',
              order: { _key: 'asc' },
              size: 10000,
            },
            aggs: {
              titles: {
                multi_terms: {
                  size: 10000,
                  terms: [
                    {
                      field: 'title',
                    },
                    {
                      field: 'publicationId',
                    },
                    {
                      field: 'type',
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
  };
};

const createFilterTitlesByCatAndAuthQuery = (category, author) => {
  return {
    size: 0,
    aggs: {
      genres: {
        terms: {
          field: 'categoryNormalized',
          include: category,
          size: 1,
        },
        aggs: {
          authors: {
            terms: {
              script: {
                lang: 'painless',
                params: {
                  include: author,
                },
                inline:
                  "doc['collectionTitleNormalized'].size() !== 0 ? " +
                  "(params.include.contains(doc['collectionTitleNormalized'].value) ? doc['collectionTitleNormalized'].value : null) : " +
                  "(params.include.contains(doc['authorNormalized'].value) ? doc['authorNormalized'].value : null)",
              },
              size: 2000,
            },
            aggs: {
              titles: {
                terms: {
                  size: 10000,
                  order: { _key: 'asc' },
                  field: 'titleNormalized',
                },
                aggs: {
                  titles: {
                    multi_terms: {
                      terms: [
                        {
                          field: 'titleNormalizedLabel',
                        },
                        {
                          field: 'publicationId',
                        },
                        {
                          field: 'type',
                        },
                        {
                          field: 'author',
                        },
                        {
                          field: 'authorNormalized',
                        },
                        {
                          field: 'collectionTitleNormalized',
                          missing: '',
                        },
                      ],
                      size: 10000,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
};

const createFilterCategoriesQuery = () => {
  return {
    size: 0,
    aggs: {
      genres: {
        terms: {
          field: 'categoryNormalized',
          order: { _key: 'asc' },
          size: 1000,
        },
        aggs: {
          genres: {
            terms: {
              field: 'categoryNormalizedLabel',
            },
          },
        },
      },
    },
  };
};

const createFilterAuthorsByCategoriesQuery = ({
  category,
  includeAuthors,
  excludeAuthors,
}) => {
  const _sortAuthors = includeAuthors ? undefined : { _key: 'asc' };

  const query = {
    size: 0,
    aggs: {
      genres: {
        terms: {
          field: 'categoryNormalized',
          order: { _key: 'asc' },
          size: 10000,
        },
        aggs: {
          authors: {
            terms: {
              script: {
                lang: 'painless',
                inline:
                  "doc['collectionTitleNormalized'].size() !== 0 ? doc['collectionTitleNormalized'].value : doc['authorNormalized'].value",
              },
              order: _sortAuthors,
              size: 10000,
              include: includeAuthors,
              exclude: excludeAuthors,
            },
            aggs: {
              authors: {
                multi_terms: {
                  terms: [
                    {
                      field: 'author',
                    },
                    {
                      field: 'authorNormalized',
                    },
                    {
                      field: 'collectionTitle',
                      missing: '',
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
  };

  if (category) {
    query.aggs.genres.terms.include = [category];
  }
  return query;
};

const createFilterTitlesByAuthorsQuery = author => {
  const query = {
    size: 0,
    aggs: {
      authors: {
        terms: {
          field: 'authorNormalized',
          order: { _key: 'asc' },
          size: 10000,
        },
        aggs: {
          titles: {
            multi_terms: {
              size: 10000,
              terms: [
                {
                  field: 'bookWeight',
                },
                {
                  field: 'titleNormalized',
                },
              ],
              order: { _key: 'asc' },
            },
            aggs: {
              titles: {
                multi_terms: {
                  terms: [
                    {
                      field: 'titleNormalizedLabel',
                    },
                    {
                      field: 'publicationId',
                    },
                    {
                      field: 'type',
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
  };

  if (author) {
    query.aggs.authors.terms.include = [author];
  }
  return query;
};

const createDocIdQuery = docId => {
  return createMoreTextQuery([docId]);
};

const createBookParaQuery = (...args) => {
  const [publicationId, paraRange, highlightOptions, searchText] = args;

  let query = {
    size: 500,
    query: {
      bool: {
        must: [
          {
            match: {
              publicationId: {
                query: publicationId,
              },
            },
          },
          {
            regexp: {
              paraId: {
                value: 'para_<' + paraRange.start + '-' + paraRange.end + '>',
              },
            },
          },
        ],
      },
    },
  };
  if (highlightOptions) {
    query = addHighlight(query, highlightOptions, searchText);
  }
  return query;
};

const addFilterByBookId = (...args) => {
  const [searchQuery, bookId] = args;

  searchQuery.query.bool.filter.push({
    term: {
      publicationId: bookId,
    },
  });
  return searchQuery;
};

const addSelectionFields = (...args) => {
  const [searchQuery] = args;

  return Object.assign(searchQuery, {
    _source: {
      includes: ['docId', 'paraId'],
    },
  });
};

const createBookSentencesListQuery = (...args) => {
  const [parsedQuery, from, size, bookId] = args;
  let query = getSearchQuery(
    parsedQuery.standAloneWords,
    parsedQuery.blackList,
    parsedQuery.quotes,
    parsedQuery.metaData,
    parsedQuery.phoneticWords,
    '',
    parsedQuery.filter
  );

  query = addSortPriority(
    query,
    parsedQuery.standAloneWords,
    parsedQuery.phraseWithStopWords
  );
  query = addFilterByBookId(query, bookId);
  query = wrapInScoreFunction(query, from, size, parsedQuery);
  query = addSizeInfo(query, size, from);
  query = addSelectionFields(query);
  return query;
};

export default {
  createSentencesListQuery,
  createFoundPublicationIdsQuery,
  createMoreTextQuery,
  createFiltersQuery,
  createFilterCategoriesQuery,
  createFilterAuthorsByCategoriesQuery,
  createFilterTitlesByAuthorsQuery,
  createFilterTitlesByCatAndAuthQuery,
  createDocIdQuery,
  createBookParaQuery,
  createBookSentencesListQuery,
};
