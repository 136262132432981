<template>
  <div id="sw-app">
    <div id="search-popup" @click="showPopup">
      <slot />
    </div>
    <SearchWidget v-bind="attrs" v-on="$listeners">
      <Popup v-show="mainPopupOpened" />
    </SearchWidget>
  </div>
</template>

<script>
  import Vue from 'vue';
  import i18n from '@/i18n';
  import store from '@/store/store';
  import { mapState } from 'vuex';
  import config from '@/configs/default.config.json';
  import spriteUrl from '@/assets/sprite.svg';

  import upperFirst from 'lodash/upperFirst';
  import camelCase from 'lodash/camelCase';
  import EventEnum from '@/enums/EventEnum';
  import { OOL } from '@/constants/constants';

  import SearchWidget from './SearchWidget.vue';

  import digits from '@/filters/digits';

  import VueTouch from 'vue2-touch-events';
  import VueHotkey from 'v-hotkey';

  import Popup from '@/containers/popup/Popup.vue';

  Vue.use(VueHotkey);
  Vue.config.productionTip = false;
  Vue.config.devtools = true;
  Vue.filter('digits', digits);
  Vue.use(VueTouch, {
    namespace: 'vue-touch',
    swipeTolerance: 20,
  });

  /*
   * Polyfills
   **/
  Vue.use(require('@webcomponents/webcomponentsjs'));

  const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /Base[A-Z]\w+\.(vue|js)$/
  );

  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
      camelCase(
        // Strip the leading `./` and extension from the filename
        fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
      )
    );
    // Register component globally
    Vue.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    );
  });

  export default {
    name: 'search-widget',
    store,
    i18n,
    config,
    components: {
      SearchWidget,
      Popup,
    },
    props: {
      profile: String,
      environment: String,
    },
    computed: {
      ...mapState('SwContextStore', ['mainPopupOpened']),
      attrs() {
        return {
          profile: this.profile,
          environment: this.environment,
          ...this.$attrs,
        };
      },
    },
    watch: {
      mainPopupOpened: {
        immediate: true,
        handler(isMainPopupOpened) {
          this.$emit('searchWidgetEvent', {
            type: isMainPopupOpened
              ? EventEnum.MAIN_POPUP_OPENED
              : EventEnum.MAIN_POPUP_CLOSED,
          });
        },
      },
    },
    mounted() {
      const brand = this.$store.getters['SwContextStore/getBrand'];
      if (brand !== OOL) {
        this.$store.commit('SwContextStore/setSpriteUrl', spriteUrl);
      }
    },
    methods: {
      showPopup() {
        this.$i18n.locale = this.interfaceLanguage;
        this.$store.dispatch('SwContextStore/setMainPopupOpened', true);
      },
    },
  };
</script>
