import { UNSET_METHOD } from '@/constants/constants';

class Provider {
  constructor() {}

  async getCoverPath() {
    return null;
  }

  async openPublication() {
    return await UNSET_METHOD;
  }

  async changeSearchLanguage() {
    return await UNSET_METHOD;
  }

  async getShortenLink(locator, bookId) {// eslint-disable-line
    return await UNSET_METHOD;
  }
}

export default new Provider();
