import { SUPPLEMENTAL, UNSET_METHOD } from '@/constants/constants';

import ExternalLinkService from '@/services/ExternalLinkService';

import provider from '@/provider';
import * as log from 'loglevel';

export default {
  name: 'SwPublicationsStore',
  namespaced: true,
  state: {
    activePublicationOpenLink: null,
  },
  getters: {
    getActivePublicationOpenLink: state => {
      return state.activePublicationOpenLink;
    },
  },
  actions: {
    resetPublicationsStore({ commit }) {
      commit('setActivePublicationOpenLink');
    },
    async setActivePublicationOpenLink({ rootGetters, commit }, publication) {
      const parsedResponse = rootGetters['SwSearchStore/getParsedResponse'];
      const brand = rootGetters['SwContextStore/getBrand'];

      const link = await ExternalLinkService.getExternalLink({
        getters: rootGetters,
        bookMeta: publication?.bookMeta,
        parsedResponse: parsedResponse,
        brand: brand,
      });
      commit('setActivePublicationOpenLink', link);
    },
    async openPublication({ dispatch, rootGetters, getters }, publication) {
      try {
        const bookMeta = publication?.bookMeta;
        if (!bookMeta) {
          throw new Error(
            `No book meta found for publication ${publication.id}`
          );
        }

        if (bookMeta.type === SUPPLEMENTAL) {
          $_openExternalLink(bookMeta.sourceUrl);
          return;
        }

        const navigationIndex =
          rootGetters['SwNavigationStore/getNavigationIndex'];
        const navigationList =
          rootGetters['SwNavigationStore/getNavigationList'];
        const searchText = rootGetters['SwSearchStore/getSearchText'];
        const parsedQuery = rootGetters['SwSearchStore/getParsedQuery'];
        const parsedResponse = rootGetters['SwSearchStore/getParsedResponse'];
        parsedQuery.stems = parsedResponse.stems;

        const searchNavigation = {
          searchQuery: searchText,
          navigateIndex: navigationIndex,
          totalResults: bookMeta.totalResults || 0,
          navigateList: navigationList,
          docId: publication?.id,
        };
        const locator = bookMeta.locator;
        const isTitle = false;

        const openPublicationResult = await provider.openPublication(
          publication,
          locator,
          parsedQuery,
          searchNavigation,
          isTitle
        );

        const isPageView = rootGetters['SwContextStore/isPageView'];
        if (openPublicationResult === UNSET_METHOD) {
          const externalLink = getters.getActivePublicationOpenLink;
          $_openExternalLink(externalLink);
        } else if (!isPageView) {
          dispatch('SwContextStore/setMainPopupOpened', false, { root: true });
        }
      } catch (error) {
        log.info(`openPublication failed with ${error}`);
      }
    },
  },
  mutations: {
    setActivePublicationOpenLink(state, link) {
      state.activePublicationOpenLink = link || null;
    },
  },
};

function $_openExternalLink(link) {
  if (!link) {
    return;
  }
  window?.open(link, '_blank', 'location=no');
}
