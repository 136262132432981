<template>
  <div v-html="svg"></div>
</template>

<script>
  import spriteSvg from '!!svg-inline-loader!@/assets/sprite.svg';
  export default {
    data() {
      return {
        svg: spriteSvg,
      };
    },
  };
</script>
