export default {
  ffa: {
    en: {
      pinnedAuthors: [],
    },
  },
  ocean: {
    en: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'matthew',
        'mark',
        'luke',
        'john',
        'muhammad',
      ],
    },
    ar: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'حضرةبهآءالله',
        'حضرة الباب',
        'حضرةعبدالبهاء',
        'حضرة شوقي أفندي',
        'حضرة شوقي أفندي',
        'بيت العدل الاعظم',
        'محمد',
      ],
    },
    fa: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'حضرتبهاءالله',
        'حضرة بهآء الله',
        'حضرت باب',
        'حضرةعبدالبهاء',
        'حضرت عبدالبهاء',
        'حضرت شوقى افندى',
        'بيت العدل اعظم\u200E\u200E',
        'نبيل زرندى',
        'محمد',
      ],
    },
  },
  ool: {
    en: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
      ],
    },
    ar: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'حضرةبهآءالله',
        'حضرة الباب',
        'حضرةعبدالبهاء',
        'حضرة شوقي أفندي',
        'بيت العدل الاعظم',
        'محمد',
      ],
    },
    fa: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'حضرتبهاءالله',
        'حضرة بهآء الله',
        'حضرت باب',
        'حضرةعبدالبهاء',
        'حضرت شوقى افندى',
        'بيت العدل اعظم\u200E\u200E',
        'نبيل زرندى',
        'محمد',
      ],
    },
  },
};
