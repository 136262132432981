import { render, staticRenderFns } from "./BaseSwitcher.vue?vue&type=template&id=8c93de46&scoped=true"
import script from "./BaseSwitcher.vue?vue&type=script&lang=js"
export * from "./BaseSwitcher.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./BaseSwitcher.vue?vue&type=style&index=0&id=8c93de46&prod&scoped=true&lang=less")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8c93de46",
  null
  ,true
)

export default component.exports