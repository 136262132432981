var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"hotkey",rawName:"v-hotkey.stop",value:(_vm.keymap),expression:"keymap",modifiers:{"stop":true}},{name:"vue-touch",rawName:"v-vue-touch:swipe",value:(_vm.navigate),expression:"navigate",arg:"swipe"}],staticClass:"virtual-list-wrapper -paragraphs",class:{ hidden: !_vm.isParagraphsReady },on:{"click":_vm.setFocus,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.$_keymapHandlerDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;if(!$event.shiftKey)return null;if($event.ctrlKey||$event.altKey||$event.metaKey)return null;return _vm.$_keymapHandlerUp.apply(null, arguments)}]}},[(_vm.isSpinnerVisible)?_c('div',{staticClass:"hollow-dots-spinner"},[_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"})]):_vm._e(),_c('virtual-list',{directives:[{name:"focus",rawName:"v-focus",value:(_vm.focus.paragraphs),expression:"focus.paragraphs"}],key:_vm.renderScrollTrigger,ref:"virtualList",staticClass:"virtual-list",class:{
      '-exact-search': _vm.isExactSearch,
    },attrs:{"tabindex":"200","keeps":200,"data-sources":_vm.paragraphs,"data-key":'id',"data-component":_vm.paragraphItem,"extra-props":{
      handleParagraphEvent: _vm.handleParagraphEvent,
      handleParaMouseDownEvent: _vm.handleParaMouseDownEvent,
      handleParaMouseUpEvent: _vm.handleParaMouseUpEvent,
      handleParaTouchStartEvent: _vm.handleParaTouchStartEvent,
      handleParaTouchEndEvent: _vm.handleParaTouchEndEvent,
    },"top-threshold":100,"bottom-threshold":600},on:{"totop":_vm.handleScrollToTop,"tobottom":_vm.handleScrollToBottom,"scroll":_vm.handleScroll}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }