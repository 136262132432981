// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-32.use[1]!../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-32.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-32.use[3]!../../../node_modules/less-loader/dist/cjs.js??clonedRuleSet-32.use[4]!./SearchHits.less?vue&type=style&index=0&prod&lang=less&external");
if(content.__esModule) content = content.default;
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add CSS to Shadow Root
var add = require("!../../../node_modules/vue-style-loader/lib/addStylesShadow.js").default
module.exports.__inject__ = function (shadowRoot) {
  add("607d1d27", content, shadowRoot)
};