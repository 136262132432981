import map from 'lodash/map';
import first from 'lodash/first';
import sortBy from 'lodash/sortBy';
import each from 'lodash/each';
import uniq from 'lodash/uniq';
import xRegExp from 'xregexp';
import markerUtils from './marker-utils.js';
import Locator from '../locator';

/* bitmask flags */
export const INCLUDE_PRECEDING_WHITESPACE = 1; // 0b01
export const INCLUDE_TRAILING_WHITESPACE = 2; // 0b10

export default class TextUtils {
  constructor() {}

  /**
   *
   * @param {Array.<string>} searchTerms
   * @param {string} text
   * @returns {Array.<CharacterOffsets>}
   */
  locateSearchTermsInText(searchTerms, text) {
    const searchTermsPattern = this._getSearchTermsPattern(searchTerms);
    const nonBoundaryPattern = xRegExp('[' + this._getNonBoundaryChars() + ']');

    return this._collectRealOffsetsByPattern(searchTermsPattern, text, {
      nonBoundaryPattern: nonBoundaryPattern,
    });
  }

  searchQuoteRealOffsets(preparedText, quote) {
    if (quote.length === 0) {
      return [];
    }
    const self = this;
    const quotesRe = map(quote, function(quoteWords) {
      return self._getSearchTermsPattern(quoteWords);
    });
    let quoteMatch;
    const realOffsets = [];
    let realOffset = [];

    let lastPosition = 0;
    const quotesReIndex = 0;
    let cutText = preparedText;
    let stop = false;
    const nonBoundaryPattern = xRegExp('[' + this._getNonBoundaryChars() + ']');
    let foundMatch;
    while (!stop) {
      for (let i = quotesReIndex; i < quotesRe.length; i++) {
        quoteMatch = first(
          this._collectRealOffsetsByPattern(quotesRe[i], cutText, {
            nonBoundaryPattern: nonBoundaryPattern,
          })
        );
        foundMatch = Boolean(quoteMatch && quoteMatch.length);
        if (i === 0 && !foundMatch) {
          stop = true;
          break;
        } else if (
          !foundMatch ||
          (!this._validateSpaceBetweenWord(
            preparedText,
            lastPosition,
            lastPosition + quoteMatch[0]
          ) &&
            i !== 0)
        ) {
          i = -1;
          realOffset = [];
          continue;
        }

        realOffset[0] =
          realOffset.length === 0
            ? lastPosition + quoteMatch[0]
            : realOffset[0];
        lastPosition += quoteMatch[1];
        realOffset[1] = lastPosition;

        cutText = preparedText.substring(lastPosition);
      }

      if (realOffset.length !== 0) {
        realOffsets.push(realOffset);
      }
      realOffset = [];
    }
    return realOffsets;
  }

  /**
   *
   * @param {Element} element
   * @returns {string}
   */
  extractContent(element) {
    if (!element) {
      throw new Error('element is undefined');
    }

    const clonedElement = element.cloneNode(true);

    const metaElements = markerUtils.getMetaElements(clonedElement);
    metaElements.forEach(function(el) {
      const metaElementReplacementText = clonedElement.ownerDocument.createTextNode(
        ' '
      );
      el.parentNode.replaceChild(metaElementReplacementText, el);
    });

    const spaceElements = clonedElement.querySelectorAll('span.dSpc');
    [].forEach.call(spaceElements, function(el) {
      const newSpaceTextNode = clonedElement.ownerDocument.createTextNode(' ');
      el.parentNode.replaceChild(newSpaceTextNode, el);
    });

    const newlineElements = clonedElement.querySelectorAll('br, hr');
    [].forEach.call(newlineElements, function(el) {
      const newlineTextNode = clonedElement.ownerDocument.createTextNode('\n');
      el.parentNode.replaceChild(newlineTextNode, el);
    });
    return clonedElement.textContent;
  }

  /**
   *
   * @param {Array.<CharacterOffsets>} stableOffsets
   * @param {Element} element
   * @param {number} [withEdgeWhitespace]
   * @returns {Array.<DomLocatorBlock>}
   */
  convertIntoDomLocatorBlocks(stableOffsets, element, withEdgeWhitespace) {
    // assert stableOffsets array is: not empty, sorted top-to-bottom, without overlapping ranges
    const textNodeIterator = element.ownerDocument.createNodeIterator(
      element,
      4, // NodeFilter.SHOW_TEXT
      textNode =>
        this._isArtificialTextNode(textNode) ||
        markerUtils.isMeta(textNode.parentNode)
          ? 2
          : 1,
      null
    );
    withEdgeWhitespace = withEdgeWhitespace || 0; // do not include whitespace at the edge of word
    const withPrecedingWhitespace =
      withEdgeWhitespace & INCLUDE_PRECEDING_WHITESPACE;
    const withTrailingWhitespace =
      withEdgeWhitespace & INCLUDE_TRAILING_WHITESPACE;
    const stableCharsPattern = this._getStableCharactersPattern();
    let stableCharsTotal = 0;
    let recheckTextNode = false;
    let textNode;

    const domLocatorBlockList = stableOffsets.map(
      /**
       *
       * @param {CharacterOffsets} stableOffsets
       * @returns {DomLocatorBlock}
       * @private
       */
      stableOffsets => {
        const domLocatorBlock = [];
        const stableStart = stableOffsets[0];
        const stableEnd = stableOffsets[1];
        // assert !(stableStart >= stableEnd)
        let domLocator, stableCharsSequence;
        const INFINITE_LOOP = true;

        while (INFINITE_LOOP) {
          if (!recheckTextNode) {
            textNode = textNodeIterator.nextNode();
            stableCharsPattern.lastIndex = 0;
            if (textNode === null) {
              break;
            }
          }
          recheckTextNode = false;

          if (domLocator) {
            domLocator = {
              textNode: textNode,
              start: 0,
            };
          }

          while (
            (stableCharsSequence = stableCharsPattern.exec(textNode.data))
          ) {
            stableCharsTotal += stableCharsSequence[0].length;
            if (!domLocator) {
              if (
                stableStart < stableCharsTotal ||
                (withPrecedingWhitespace && stableStart === stableCharsTotal)
              ) {
                domLocator = {
                  textNode: textNode,
                  start:
                    stableCharsPattern.lastIndex -
                    stableCharsTotal +
                    stableStart,
                };
              }
            }

            if (domLocator) {
              if (
                (!withTrailingWhitespace && stableEnd <= stableCharsTotal) ||
                stableEnd === stableCharsTotal - stableCharsSequence[0].length
              ) {
                domLocator.end =
                  stableCharsPattern.lastIndex - stableCharsTotal + stableEnd;
                break;
              }
            }
          }

          if (domLocator) {
            if (domLocator.start === domLocator.end) {
              break;
            }
            if (domLocator.start !== textNode.data.length) {
              domLocatorBlock.push(domLocator);
              if (domLocator.end) {
                recheckTextNode =
                  stableCharsPattern.lastIndex < textNode.data.length;
                break;
              }
              domLocator.end = textNode.data.length;
            }
          }
        }
        return domLocatorBlock;
      }
    );
    return domLocatorBlockList;
  }

  turnIntoStableOffsets(realOffsets, text) {
    let accumulatedWhitespace = 0;
    let previousRealOffset = 0;
    const whitespaceCharacterPattern = this._getWhitespaceCharacterPattern();

    function _dehydrateRealOffset(realOffset) {
      const whitespaceBeforeOffset = text
        .slice(previousRealOffset, realOffset)
        .match(whitespaceCharacterPattern);

      if (whitespaceBeforeOffset) {
        accumulatedWhitespace += whitespaceBeforeOffset.length;
      }
      previousRealOffset = realOffset;
      return realOffset - accumulatedWhitespace;
    }

    const stableOffsets = realOffsets.map(function(realOffset) {
      return Array.isArray(realOffset)
        ? realOffset.map(_dehydrateRealOffset)
        : _dehydrateRealOffset(realOffset);
    });
    return stableOffsets;
  }

  /**
   *
   * @param {Node} textNode
   * @returns {boolean}
   * @private
   */
  _isArtificialTextNode(textNode) {
    return /^(\n\s+)?$/.test(textNode.data);
  }
  /**
   *
   * @returns {RegExp}
   * @private
   */
  _getStableCharactersPattern() {
    return /\S+/g;
  }
  /**
   * @param  {Array} realOffsets
   * @return {Array} normalizedRealOffsets
   */
  normalizingOverLoops(realOffsets) {
    const sortedRealOffsets = sortBy(realOffsets, '0');
    let currentRealOffsets = sortedRealOffsets.shift();
    const normalizedRealOffsets = [];
    each(sortedRealOffsets, realOffset => {
      if (this._inRange(realOffset, currentRealOffsets)) {
        currentRealOffsets[1] =
          realOffset[1] > currentRealOffsets[1]
            ? realOffset[1]
            : currentRealOffsets[1];
      } else {
        normalizedRealOffsets.push(currentRealOffsets);
        currentRealOffsets = realOffset;
      }
    });
    normalizedRealOffsets.push(currentRealOffsets);

    return normalizedRealOffsets;
  }

  _inRange(element, range) {
    return element[0] >= range[0] && element[0] <= range[1];
  }

  _getSearchTermsPattern(searchTerms) {
    const sortedWords = uniq(searchTerms);
    sortedWords.sort(function(a, b) {
      return b.length - a.length;
    });

    const allowedBeforeWord = '[\\p{Po}\\p{Ps}\\p{Pi}\\p{N}\\p{S}]*';
    const allowedAfterWord = '[\\p{Po}\\p{Pe}\\p{Pf}\\p{N}\\p{S}]*';
    const wordsAlternation =
      '(?:' + sortedWords.map(xRegExp.escape).join('|') + ')';
    const searchTermsPattern = xRegExp(
      '(' + allowedBeforeWord + wordsAlternation + allowedAfterWord + ')',
      'gi'
    );

    return searchTermsPattern;
  }

  _getNonBoundaryChars() {
    return '\\p{L}\\p{M}\\p{P}';
  }

  _collectRealOffsetsByPattern(pattern, text, options) {
    if (!pattern.global) {
      throw new Error('Pattern should be global');
    }
    const nonBoundaryPattern = options.nonBoundaryPattern;
    const concatConditionChecker = options.concatConditionChecker;

    let realOffsets = [];
    let match, start, end;

    while ((match = pattern.exec(text))) {
      end = pattern.lastIndex;
      start = end - match[0].length;
      if (nonBoundaryPattern) {
        if (
          nonBoundaryPattern.test(text.charAt(end)) ||
          nonBoundaryPattern.test(text.charAt(start - 1))
        ) {
          continue;
        }
      }
      realOffsets.push([start, end]);
    }

    if (concatConditionChecker) {
      realOffsets = this._concatByCondition(
        realOffsets,
        concatConditionChecker,
        text
      );
    }

    return realOffsets;
  }

  _validateSpaceBetweenWord(sentence, lastPosition, newPosition) {
    if (lastPosition === newPosition) {
      return true;
    }
    const spaceBetweenWord = sentence.substring(lastPosition, newPosition);

    const validateRe = xRegExp('^\\P{L}+$');
    return validateRe.test(spaceBetweenWord);
  }

  _concatByCondition(offsets, concatConditionChecker, text) {
    const joinedOffsets = [];
    let i = 0;
    let joinStartOffset = 0;
    let str;

    let offset;
    while ((offset = offsets[i])) {
      joinStartOffset = joinStartOffset || offset[0];
      str = text.slice(joinStartOffset, offset[1]);
      if (!concatConditionChecker(str)) {
        joinedOffsets.push([joinStartOffset, offset[1]]);
        joinStartOffset = 0;
      }
      i++;
    }

    if (joinStartOffset) {
      joinedOffsets.push([joinStartOffset, offsets[i - 1][1]]);
    }
    return joinedOffsets;
  }

  _getWhitespaceCharacterPattern() {
    return /\s/g;
  }

  calculateContentStableLength(element) {
    const textContent = this.extractContent(element);
    const stableCharactersPattern = this._getStableCharactersPattern();
    let stableLength = 0;

    let match;
    /* jshint -W084 */
    while ((match = stableCharactersPattern.exec(textContent))) {
      stableLength += match[0].length;
    }
    /* jshint +W084 */
    return stableLength;
  }

  collectWordsStableOffsets(textContent) {
    const wordsStableOffsets = [];
    const stableCharactersPattern = this._getStableCharactersPattern();
    let previousPositionEnd = 0;

    let match;
    /* jshint -W084 */
    while ((match = stableCharactersPattern.exec(textContent))) {
      wordsStableOffsets.push([
        previousPositionEnd,
        (previousPositionEnd += match[0].length),
      ]);
    }
    /* jshint +W084 */
    return wordsStableOffsets;
  }
  /**
   * Single offset recoverer
   *
   * @param {number} stableOffset
   * @param {string} text
   * @param {boolean} [isWordEnding=false]
   * @returns {number} realOffset
   */
  recoverRealOffset(stableOffset, text, isWordEnding) {
    const arrayResult = this.recoverRealOffsets(
      [stableOffset],
      text,
      isWordEnding
    );
    return arrayResult[0];
  }

  extractContentByRangeLocator(locator) {
    if (
      !(locator instanceof Locator.InTextRangeLocator) ||
      locator.endLocator.prefixedParagraphId !==
        locator.startLocator.prefixedParagraphId
    ) {
      return null;
    }

    const para = markerUtils.getParaByRangeLocator(locator);
    const textContent = this.extractContent(para);
    const begin = this.recoverRealOffset(
      locator.startLocator.logicalCharOffset,
      textContent
    );
    const end = this.recoverRealOffset(
      locator.endLocator.logicalCharOffset,
      textContent
    );
    return textContent.slice(begin, end);
  }

  /**
   *
   * @param {Array.<CharacterOffsets|number>} stableOffsets
   * @param {string} text
   * @param {boolean} [isWordEnding=false]
   * @returns {Array.<CharacterOffsets|number>} realOffsets
   */
  recoverRealOffsets(stableOffsets, text, isWordEnding) {
    isWordEnding = isWordEnding || false;
    const realOffsets = [];

    let i = 0;

    const stableCharactersPattern = this._getStableCharactersPattern();
    let accumulatedStableCharacters = 0;

    let realOffset = null;
    const arrayedStableOffsets = stableOffsets.map(function(offset) {
      if (Array.isArray(offset)) {
        if (offset.length > 2) {
          throw new Error(
            'Illegal parameter: neither a single offset nor word boundaries'
          );
        }
      } else {
        offset = [offset];
      }
      return offset;
    });

    let match, lastIndex, charDiff, charsInMatch, isOffsetPair;
    /* jshint -W084 */
    while ((match = stableCharactersPattern.exec(text))) {
      charsInMatch = match[0].length;
      accumulatedStableCharacters += charsInMatch;
      lastIndex = stableCharactersPattern.lastIndex;

      while (
        i < arrayedStableOffsets.length &&
        accumulatedStableCharacters >= arrayedStableOffsets[i][0]
      ) {
        isOffsetPair = arrayedStableOffsets[i].length === 2;

        if (accumulatedStableCharacters === arrayedStableOffsets[i][0]) {
          if (isOffsetPair || !isWordEnding) {
            break;
          }
          realOffset = lastIndex;
        }

        if (realOffset === null) {
          charDiff = accumulatedStableCharacters - arrayedStableOffsets[i][0];
          realOffset = lastIndex - charDiff;
        }

        if (isOffsetPair) {
          charDiff = accumulatedStableCharacters - arrayedStableOffsets[i][1];
          if (charDiff < 0) {
            break;
          }
          realOffset = [realOffset, lastIndex - charDiff];
        }

        realOffsets.push(realOffset);
        realOffset = null;
        i++;
      }

      if (i === arrayedStableOffsets.length) {
        break;
      }
    }
    /* jshint +W084 */

    // assert stableOffsets.length === realOffsets.length
    return realOffsets;
  }
}
