import get from 'lodash/get';

import * as log from 'loglevel';
log.setLevel('error');

import HitsFactory from '@/classes/factories/HitsFactory';
import SearchFactory from '@/classes/factories/SearchFactory';

import eventManager from '@/services/EventService';
import InputService from '@/services/InputService';
import localStorageService from '@/services/LocalStorageService';
import validateQueryService from '@/services/Search/ValidateQueryService';

import LocalStorageKeyEnum from '@/enums/LocalStorageKeyEnum';
import ErrorMessagesEnum from '@/enums/ErrorMessagesEnum';

import parseQuery from '@/utils/searchUtils';

import config from '@/configs/default.config.json';

import { MIN_CHARS_COUNT_FOR_SEARCH } from '@/constants/constants';

const DEFAULT_SEARCH_TEXT = '';
const DEFAULT_PARSED_QUERY = {};
const DEFAULT_PARSED_RESPONSE = {};
const DEFAULT_SEARCH_IN_PROCESS = false;
const DEFAULT_TOTAL_VALUE = 0;
const DEFAULT_FOUND_NOTHING = false;
const DEFAULT_SHOULD_TRIGGER_INPUT = true;

const state = _createInitialSearchState();

function _createInitialSearchState() {
  return {
    searchInProcess: DEFAULT_SEARCH_IN_PROCESS,
    parsedQuery: DEFAULT_PARSED_QUERY,
    parsedResponse: DEFAULT_PARSED_RESPONSE,
    searchText: DEFAULT_SEARCH_TEXT,
    totalPublications: DEFAULT_TOTAL_VALUE,
    totalHits: DEFAULT_TOTAL_VALUE,
    requestIds: [],
    foundNothing: DEFAULT_FOUND_NOTHING,
    shouldTriggerInput: DEFAULT_SHOULD_TRIGGER_INPUT,
  };
}

const getters = {
  getSearchText: state => {
    return state.searchText;
  },
  getParsedQuery: state => {
    return state.parsedQuery;
  },
  getParsedResponse: state => {
    return state.parsedResponse;
  },
  getFoundNothing: state => {
    return state.foundNothing;
  },
  getShouldTriggerInput: state => {
    return state.shouldTriggerInput;
  },
};

const actions = {
  async performSearch({ dispatch, commit, getters }, payload) {
    const { fromFilterReq } = payload || {};
    try {
      eventManager.publish('cancelPreviousRequests');

      const searchText = getters.getSearchText;
      if (searchText?.trim().length < MIN_CHARS_COUNT_FOR_SEARCH) {
        return;
      }

      dispatch('setFoundNothing', false);
      dispatch('setSearchInProcess', true);
      dispatch('fillParsedQuery');

      const parsedQuery = getters['getParsedQuery'];
      if (!parsedQuery.validResp) {
        throw new Error(ErrorMessagesEnum.QUERY_IS_NOT_VALID);
      }

      const hitsSearchResponse = await dispatch('SwHitsStore/getHits', null, {
        root: true,
      });

      let firstDoc = get(hitsSearchResponse, 'sentencesList.rows[0]', {});
      firstDoc = HitsFactory.createHitItem(firstDoc);

      if (!firstDoc.id) {
        getFoundFilteredPublicationIds({ dispatch }, { fromFilterReq });
        throw new Error(ErrorMessagesEnum.NO_HITS_FOUND);
      }

      const navigationListSearchResponse = await dispatch(
        'SwNavigationStore/getNavigation',
        firstDoc,
        { root: true }
      );

      dispatch('resetSearchStates', { fromFilterReq });

      dispatch(
        'SwNavigationStore/fillNavigationStore',
        navigationListSearchResponse,
        { root: true }
      );
      dispatch('SwHitsStore/fillHitsStore', hitsSearchResponse, { root: true });
      dispatch('SwNavigationStore/setHitByNavigationIndex', 0, { root: true });
      commit('setTotalPublications', hitsSearchResponse.totalPublications);
      commit('setTotalHits', hitsSearchResponse.totalHits);
      commit('setParsedResponse', hitsSearchResponse.sentencesList);

      getFoundFilteredPublicationIds({ dispatch }, { fromFilterReq });

      dispatch('SwErrorStore/setIsRequestCancelled', false, { root: true });
    } catch (error) {
      handleError(error, dispatch, commit, {
        fromFilterReq,
      });
    } finally {
      dispatch('setSearchInProcess', false);
    }
    return Promise.resolve();
  },

  setSearchText({ commit, dispatch, rootGetters }, searchText) {
    if (this.state.SwSearchStore.searchText === searchText) {
      return;
    }
    if (searchText === '') {
      dispatch('resetSearchStates');
      dispatch('resetSearchStore');
      commit('setSearchText', searchText);
      dispatch('setLastSearchText');
      return;
    }
    let correctInput = searchText;
    const isInputFromVirtualKeyboard =
      rootGetters['SwKeyboardStore/getIsInputFromVirtualKeyboard'];
    const isBackspacePressed =
      rootGetters['SwKeyboardStore/getIsBackspacePressed'];

    if (isInputFromVirtualKeyboard && !isBackspacePressed) {
      correctInput = InputService.correctInputIfCursorInsideWord(
        searchText,
        rootGetters,
        dispatch
      );
    }
    dispatch('SwKeyboardStore/setIsBackspacePressed', false, { root: true });
    commit('setSearchText', correctInput);
    dispatch('setLastSearchText');
  },

  fillParsedQuery({ commit, rootGetters }) {
    const lang = rootGetters['SwContextStore/getLang'];
    const searchText = this.state.SwSearchStore.searchText;
    let filter = rootGetters['SwFilterStore/getFilteredPublicationIds'];
    const openedPublicationId =
      rootGetters['SwContextStore/getOpenedPublicationId'];
    filter = openedPublicationId ? [openedPublicationId] : filter;
    const parsedQuery = parseQuery(
      searchText,
      config.searchConfig,
      lang,
      filter,
      openedPublicationId
    );
    const validatedParsedQuery = validateQueryService.validateQuery(
      searchText,
      lang,
      parsedQuery
    );
    commit('setParsedQuery', validatedParsedQuery);
  },

  resetSearchStates({ dispatch }, payload) {
    const { fromFilterReq } = payload || {};

    dispatch('SwHitsStore/resetHitsStore', null, { root: true });
    dispatch('SwParagraphsStore/resetParagraphsStore', null, {
      root: true,
    });
    dispatch('SwNavigationStore/resetNavigationStore', null, { root: true });
    dispatch('SwKeyboardStore/resetKeyboardStore', null, { root: true });
    dispatch('SwPublicationsStore/resetPublicationsStore', null, {
      root: true,
    });

    if (!fromFilterReq) {
      dispatch('SwFilterStore/resetFoundFilteredPublicationIds', null, {
        root: true,
      });
    }
  },

  resetSearchStore({ commit, dispatch }) {
    commit('setParsedQuery');
    commit('setSearchInProcess');
    commit('setTotalPublications');
    commit('setTotalHits');
    commit('setFoundNothing', DEFAULT_FOUND_NOTHING);
    commit('setSearchInProcess', DEFAULT_SEARCH_IN_PROCESS);

    dispatch('SwErrorStore/resetErrorStore', null, { root: true });
  },

  totalReset({ dispatch }, ifNeedSaveText = false) {
    if (!ifNeedSaveText) {
      dispatch('setSearchText', '');
    }
    dispatch('resetSearchStore');
    dispatch('resetSearchStates');
    dispatch('SwErrorStore/resetErrorStore', null, { root: true });
  },

  setSearchInProcess({ commit }, isInProcess) {
    commit('setSearchInProcess', isInProcess);
  },

  setFoundNothing({ commit }, foundNothing) {
    commit('setFoundNothing', foundNothing);
  },

  getLastSearchText({ commit }) {
    const lastSearchText = localStorageService.getDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_TEXT
    );
    commit('setSearchText', lastSearchText);
  },

  setLastSearchText({ state }) {
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_TEXT,
      state.searchText
    );
  },
};

const mutations = {
  setSearchText(state, searchText) {
    state.searchText = searchText || DEFAULT_SEARCH_TEXT;
  },
  setParsedQuery(state, parsedQuery) {
    state.parsedQuery = parsedQuery || DEFAULT_PARSED_QUERY;
  },
  setSearchInProcess(state, isInProcess) {
    state.searchInProcess = isInProcess || DEFAULT_SEARCH_IN_PROCESS;
  },
  setTotalPublications(state, value) {
    state.totalPublications = value || DEFAULT_TOTAL_VALUE;
  },
  setTotalHits(state, value) {
    state.totalHits = value || DEFAULT_TOTAL_VALUE;
  },
  setShouldTriggerInput(state, value) {
    state.shouldTriggerInput = value;
  },

  setFoundNothing(state, foundNothing) {
    state.foundNothing = foundNothing || DEFAULT_FOUND_NOTHING;
  },
  setParsedResponse(state, parsedResponse) {
    state.parsedResponse = SearchFactory.createParsedResponse(parsedResponse);
  },
};

function handleError(error, dispatch, commit, payload) {
  const { fromFilterReq } = payload || {};
  const noResultsByFiltering =
    fromFilterReq && error?.message === ErrorMessagesEnum.NO_HITS_FOUND;

  log.info(error.message);
  dispatch('resetSearchStates', { fromFilterReq });

  if (error?.message === ErrorMessagesEnum.NO_HITS_FOUND) {
    dispatch('setFoundNothing', true);
    commit('setTotalPublications', 0);
    commit('setTotalHits', 0);
  }

  if (noResultsByFiltering) {
    return;
  }
  dispatch('SwErrorStore/setErrorMessage', error, { root: true });
}

function getFoundFilteredPublicationIds({ dispatch }, { fromFilterReq }) {
  if (!fromFilterReq) {
    dispatch('SwFilterStore/getFoundFilteredPublicationIds', null, {
      root: true,
    });
  }
}

export default {
  name: 'SwSearchStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
