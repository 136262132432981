import PublicationLocator from './publication-locator';

/**
 * Locator attached to the start of Publication
 *
 * @constructor
 * @extends {PublicationLocator}
 */

export default class PublicationStartLocator extends PublicationLocator {
  /**
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   */
  compareTo(locator) {
    return this._comparePublicationStartWith(locator);
  }

  /**
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   * @private
   */
  _comparePublicationStartWith(locator) {
    return locator.constructor === PublicationStartLocator ? 0 : -1;
  }
  /**
   *
   * @param {PublicationLocator} locator
   * @returns {number}
   */
  compareBasisTo(locator) {
    return this._comparePublicationStartWith(locator);
  }
}
