const state = {
  isOnline: navigator.onLine,
};

const getters = {
  getIsOnline: state => {
    return state.isOnline;
  },
};

const actions = {
  setIsOnline({ commit }, isOnline) {
    commit('setIsOnline', isOnline);
  },
};

const mutations = {
  setIsOnline(state, isOnline) {
    state.isOnline = isOnline;
  },
};

export default {
  getters,
  actions,
  mutations,
  state,
  namespaced: true,
  name: 'SwOfflineModeStore',
};
