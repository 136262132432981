<template>
  <div class="base-switch">
    <div
      class="switch-container"
      @click="toggleSwitch"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div class="switch-control">
        <input type="checkbox" v-model="checked" />
        <span class="slider round"></span>
      </div>
      <div class="switch-label">{{ label }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseSwitcher',
    props: {
      label: {
        type: String,
        default: '',
      },
      isChecked: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        checked: false,
        startX: 0,
        isMoving: false,
      };
    },
    mounted() {
      this.checked = this.isChecked;
    },
    methods: {
      toggleSwitch(event) {
        event.stopPropagation();
        if (!this.isMoving) {
          this.checked = !this.checked;
          this.$emit('change', this.checked);
        }
        this.isMoving = false;
      },
      handleTouchStart(event) {
        this.startX = event.touches[0].clientX;
        this.isMoving = false;
      },
      handleTouchMove(event) {
        this.isMoving = true;
        const touchX = event.touches[0].clientX;
        const deltaX = touchX - this.startX;

        const swipeThreshold = 10;

        if (deltaX > swipeThreshold && !this.checked) {
          this.checked = true;
          this.$emit('change', this.checked);
        } else if (deltaX < -swipeThreshold && this.checked) {
          this.checked = false;
          this.$emit('change', this.checked);
        }
      },
      handleTouchEnd() {
        if (this.isMoving) {
          this.$emit('change', this.checked);
        }
        this.isMoving = false;
      },
    },
  };
</script>

<style scoped lang="less">
  .base-switch {
    display: inline-block;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .switch-container {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }

  .switch-control {
    position: relative;
    width: 32px;
    height: 16px;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
  }

  input {
    display: none;
  }

  input:checked + .slider {
    background-color: var(--sw-primary-color);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--sw-primary-color);
  }

  input:checked + .slider:before {
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 16px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .switch-label {
    padding-top: 2px;
    line-height: 16px;
  }
</style>
