import get from 'lodash/get';
import * as log from 'loglevel';

import ParseResponseUtils from '@/services/ElasticSearch/ParseResponseUtils';

const parseInitSearchResponse = ({ responses }) => {
  return {
    searchInfo: get(responses[0], 'data', null),
    esInfoMap: responses[1] || null,
  };
};

const parseRemoteConfigResponse = ({ response }) => {
  return get(response, 'data', null);
};

const parseParagraphsResponse = ({ response }) => {
  const prevParagraphs = response[0];
  const nextParagraphs = response[1];
  return ParseResponseUtils.mergePrevNextPara(prevParagraphs, nextParagraphs);
};

const parseParagraphsToMapWithoutParaId = ({ response }) => {
  if (response.data && response.data.hits) {
    response.data.hits.hits = response.data.hits.hits.map((rawDoc, index) => {
      const doc = rawDoc._source;
      const locator = doc.locator || '';
      const paraNum = locator.split('.')[0];
      doc.paraId =
        typeof paraNum === 'string' && paraNum.length > 0
          ? `para_${paraNum}`
          : `para_${index}`;
      return rawDoc;
    });
    return parseParagraphsToMap({ response });
  }
};

const parseFoundPublicationIdsResponse = ({ response }) => {
  const pubIdsBuckets = get(
    response,
    'data.aggregations.publicationIds.buckets',
    []
  );
  return pubIdsBuckets.reduce((acc, bucket) => {
    return { ...acc, [bucket.key]: true };
  }, {});
};

const parseParagraphsToMap = ({ response }) => {
  const paragraphs = get(response, 'data.hits.hits', null);

  const paraMap = {};
  const paraIds = [];

  if (paragraphs) {
    paragraphs.forEach(paraDoc => {
      const highlightTextExact = get(paraDoc, 'highlight["text.exact"]', []);
      const highlightText = highlightTextExact.length
        ? highlightTextExact
        : get(paraDoc, 'highlight.text', []);
      if (highlightText.length > 1) {
        log.error(`In creating fn parseParagraphsToMap get more then one highlight sentence,
          need increase 'number_of_fragments' and 'fragment_size' in query
          addHighlightQuery for get one sentence`);
      }
      const doc = paraDoc._source;
      if (highlightText[0]) {
        doc.text = highlightText[0];
      }
      const paraId = doc.paraId;
      if (!paraMap.hasOwnProperty(paraId)) {
        paraMap[paraId] = [];
      }
      paraMap[paraId].push(paraDoc._source);
      paraIds.push(paraId);
    });
  }

  paraIds.forEach(paraId => {
    paraMap[paraId].sort((sentA, sentB) => {
      return sentA.sentenceNumber - sentB.sentenceNumber;
    });
  });

  return paraMap;
};

const parseFilterResponse = ({ response }) => {
  const aggregations = get(response, 'data.aggregations', null);
  const genres = get(aggregations, 'genres.buckets', []);
  return genres.reduce((_genres, genre) => {
    const authors = get(genre, 'authors.buckets', []);
    const authorsArr = authors.reduce((_authors, author) => {
      const titlesByAuthor = get(author, 'titles.buckets', []);
      const titlesByAuthorArr = titlesByAuthor.reduce(
        (_titlesByAuthor, _titleByAuthor) => {
          const _tByAuthors = get(_titleByAuthor, 'titles.buckets', []);
          const titlesArr = _tByAuthors.reduce((_titles, _title) => {
            const titleNormalized = _titleByAuthor.key;
            const [
              titleLabel,
              publicationId,
              bookType,
              authorName,
              authorNameNormalized,
              collectionTitleNormalized,
            ] = _title.key;
            _titles.push({
              info: {
                label: titleLabel,
                labelNormalized: titleNormalized,
                isCollection: !!collectionTitleNormalized,
                bookType,
                publicationId,
                collectionTitleNormalized,
                authorName,
                authorNameNormalized,
              },
              data: {},
            });
            return _titles;
          }, []);
          return [..._titlesByAuthor, ...titlesArr];
        },
        []
      );
      _authors.push({
        info: {
          labelNormalized: author.key,
          collectionTitleNormalized:
            titlesByAuthorArr[0]?.info?.collectionTitleNormalized,
          isCollection: !!titlesByAuthorArr[0]?.info?.collectionTitleNormalized,
        },
        data: titlesByAuthorArr,
      });
      return _authors;
    }, []);
    _genres[genre.key] = {
      info: {},
      data: authorsArr,
    };
    return _genres;
  }, {});
};

const parseFilterCategoriesResponse = ({ response, lang }) => {
  const aggregations = get(response, 'data.aggregations', null);
  const genres = get(aggregations, 'genres.buckets', []);
  return genres.reduce((_genres, _genre) => {
    const categoryNormalized = _genre.key;
    const categoryLabel = _getMoreDiacriticsStringFromArray(
      get(_genre, 'genres.buckets', []),
      lang
    );
    _genres[categoryNormalized] = {
      info: {
        label: categoryLabel,
      },
      data: [],
    };
    return _genres;
  }, {});
};

const parseFilterAuthorsByCategoriesResponse = ({
  response,
  lang,
  includeAuthors,
}) => {
  const aggregations = get(response, 'data.aggregations', null);
  const genres = get(aggregations, 'genres.buckets', []);
  return genres.reduce((_genres, genre) => {
    const authors = get(genre, 'authors.buckets', []);
    if (includeAuthors?.length) {
      _sortAuthorsByArray(authors, includeAuthors);
    }
    const authorsArr = authors.reduce((_authors, _author) => {
      const labelNormalized = _author.key;
      const [, authorLabelNormalized, collectionTitle] = get(
        _author,
        'authors.buckets[0].key',
        []
      );
      const authorLabels = get(_author, 'authors.buckets', []).map(item => {
        return { key: item.key[0] };
      });
      const authorLabel = _getMoreDiacriticsStringFromArray(authorLabels, lang);
      _authors.push({
        info: {
          label: authorLabel,
          labelNormalized: authorLabelNormalized,
          collectionTitle: collectionTitle,
          collectionTitleNormalized: collectionTitle ? labelNormalized : '',
          isCollection: !!collectionTitle,
        },
        data: [],
      });
      return _authors;
    }, []);
    _genres[genre.key] = {
      info: {},
      data: authorsArr,
    };
    return _genres;
  }, {});
};

const parseFilterTitlesByAuthorsResponse = ({ response }) => {
  const aggregations = get(response, 'data.aggregations', null);
  const authors = get(aggregations, 'authors.buckets', []);
  return authors.reduce((_authors, author) => {
    const titles = get(author, 'titles.buckets', []);
    _authors[author.key] = titles.reduce((_titles, _title) => {
      const [title, publicationId, bookType] = _title.key;
      _titles[title] = [publicationId, bookType];
      return _titles;
    }, {});
    return _authors;
  }, {});
};

const parseTitlesResponse = ({ response }) => {
  const hits = get(response, 'data.hits.hits', []);
  let parseTitlesResponse = [];

  hits.forEach(hit => {
    let parsedInnerHits = get(hit, 'inner_hits.cluster.hits.hits', []);

    parsedInnerHits = parsedInnerHits.map((innerHit, index) => {
      const parsedInnerHit = { ...innerHit._source };

      if (index === 0) {
        parsedInnerHit.firstGroupItem = true;
      }
      parsedInnerHit.highlight = get(innerHit, 'highlight.text', [
        parsedInnerHit.text,
      ]).join(' ... ');
      return parsedInnerHit;
    });
    parseTitlesResponse = [...parseTitlesResponse, ...parsedInnerHits];
  });
  return {
    totalBooks: get(response, 'data.aggregations.totalPublications.value', 0),
    titlesList: parseTitlesResponse,
  };
};

const parseNavigationResponse = ({ response }) => {
  const hits = get(response, 'data.hits.hits', []);
  return hits.map(hit => hit._source);
};

const parseSingleHitResponse = ({ response }) => {
  return get(response, 'data.hits.hits[0]._source', {});
};

const parseHitsResponse = ({ response, supplementalSummary, collapsed }) => {
  const sentences = get(response, 'data.hits.hits', []);
  const indexName = get(response, 'data.hits.hits[0]', {})._index;

  const stems = {};
  const quotes = {};

  let parsedSentences = collapsed
    ? ParseResponseUtils.createClusteredHitsList(sentences)
    : sentences;
  parsedSentences = parsedSentences.map(sentence => {
    const parsedSentence = { ...sentence._source };

    const summary = ParseResponseUtils.getSummaryByIndexName(
      supplementalSummary,
      indexName
    );

    const highlight = get(sentence, 'highlight.text', [
      parsedSentence.text,
    ]).join(' ... ');

    const quoteHighlight = get(sentence, 'highlight.["text.exact"]', [
      parsedSentence.text,
    ]).join(' ... ');

    const highlightedStems = ParseResponseUtils.getStems(highlight);
    const highlightedQuotes = ParseResponseUtils.getQuotes(quoteHighlight);

    ParseResponseUtils.addToMap(stems, highlightedStems);
    ParseResponseUtils.addToMap(quotes, highlightedQuotes);

    if (summary) {
      parsedSentence.type = 'Supplemental';
    }

    const sentenceHighlight =
      highlightedQuotes.length === 0 ? highlight : quoteHighlight;

    return {
      ...parsedSentence,
      ...{
        highlight: sentenceHighlight,
      },
    };
  });

  const quotesArr = Object.keys(quotes).map(function(quote) {
    return quote.split(/\s+/g).map(word => [word]);
  });
  const stemsArr = Object.keys(stems);

  return {
    indexName,
    totalPublications: get(
      response,
      'data.aggregations.totalPublications.value',
      0
    ),
    totalHits: get(response, 'data.hits.total.value', 0),
    sentencesList: {
      rows: parsedSentences,
      stems: stemsArr,
      quotes: quotesArr,
      highlightRules: {
        stems: quotesArr.length !== 0,
        quotes: false,
      },
    },
  };
};

const normalizationMethodByLang = {
  default: 'NFD',
  ar: 'NFKC',
  fa: 'NFKC',
};

function _getMoreDiacriticsStringFromArray(arr, lang) {
  let maxDiacriticsCount = -1;
  let maxDiacriticsString = null;

  for (const key of arr) {
    const str = key.key;
    const normalizationMethod =
      normalizationMethodByLang[lang] || normalizationMethodByLang.default;
    const normalizedStr = str.normalize(normalizationMethod);

    const diacriticsCount = normalizedStr
      .split('')
      .filter(c => /\p{M}/u.test(c)).length;

    if (diacriticsCount > maxDiacriticsCount) {
      maxDiacriticsCount = diacriticsCount;
      maxDiacriticsString = str;
    }
  }
  return maxDiacriticsString;
}

function _sortAuthorsByArray(authors, sortArray) {
  authors.sort((a, b) => {
    a = a.key;
    b = b.key;
    const aIndex = sortArray.indexOf(a);
    const bIndex = sortArray.indexOf(b);
    if (aIndex === -1 && bIndex === -1) {
      return 0;
    }
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  });
}

export default {
  parseInitSearchResponse,
  parseRemoteConfigResponse,
  parseParagraphsResponse,
  parseHitsResponse,
  parseSingleHitResponse,
  parseNavigationResponse,
  parseFilterResponse,
  parseFilterCategoriesResponse,
  parseFilterAuthorsByCategoriesResponse,
  parseFilterTitlesByAuthorsResponse,
  parseTitlesResponse,

  parseParagraphsToMap,
  parseParagraphsToMapWithoutParaId,
  parseFoundPublicationIdsResponse,
};
