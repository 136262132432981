import {
  FOCUS_FILTER_FIELD,
  FOCUS_SELECTOR_SEARCH_FIELD,
} from '@/constants/constants';

const DEFAULT_IS_INPUT_FROM_VIRTUAL_KEYBOARD = false;
const DEFAULT_IS_BACKSPACE_PRESSED = false;
const DEFAULT_IS_COPY_PAST = false;

function _createInitialKeyboardState() {
  return {
    keyboardInput: null,
    lastFocusedField: null,
    isInputFromVirtualKeyboard: DEFAULT_IS_INPUT_FROM_VIRTUAL_KEYBOARD,
    isBackspacePressed: DEFAULT_IS_BACKSPACE_PRESSED,
    isCopyPast: DEFAULT_IS_COPY_PAST,
  };
}

function isInputField(field) {
  return field === FOCUS_FILTER_FIELD || field === FOCUS_SELECTOR_SEARCH_FIELD;
}

const state = _createInitialKeyboardState();

const getters = {
  getInput: state => field => state.keyboardInput?.[field] ?? '',
  getIsInputFromVirtualKeyboard: state => {
    return state.isInputFromVirtualKeyboard;
  },
  getIsBackspacePressed: state => {
    return state.isBackspacePressed;
  },
  getIsCopyPast: state => {
    return state.isCopyPast;
  },
};

const actions = {
  setIsInputFromVirtualKeyboard({ commit }, isInputFromVirtualKeyboard) {
    commit('setIsInputFromVirtualKeyboard', isInputFromVirtualKeyboard);
  },
  setIsBackspacePressed({ commit }, isBackspacePressed) {
    commit('setIsBackspacePressed', isBackspacePressed);
  },
  setIsCopyPast({ commit }, isCopyPast) {
    commit('setIsCopyPast', isCopyPast);
  },
  resetKeyboardStore({ commit }) {
    commit('setIsInputFromVirtualKeyboard');
    commit('setIsBackspacePressed');
    commit('setIsCopyPast');
  },
};

const mutations = {
  setIsInputFromVirtualKeyboard(state, isInputFromVirtualKeyboard) {
    state.isInputFromVirtualKeyboard =
      isInputFromVirtualKeyboard || DEFAULT_IS_INPUT_FROM_VIRTUAL_KEYBOARD;
  },
  setIsBackspacePressed(state, isBackspacePressed) {
    state.isBackspacePressed =
      isBackspacePressed || DEFAULT_IS_BACKSPACE_PRESSED;
  },
  setIsCopyPast(state, isCopyPast) {
    state.isCopyPast = isCopyPast || DEFAULT_IS_COPY_PAST;
  },
  setLastFocusedField(state, lastFocusedField) {
    if (!isInputField(lastFocusedField)) {
      return;
    }
    state.lastFocusedField = lastFocusedField;
  },
  setKeyboardInput(state, { field, input }) {
    if (!isInputField(field)) {
      return;
    }
    state.keyboardInput = { ...(state.keyboardInput || {}), [field]: input };
  },
};

export default {
  getters,
  actions,
  mutations,
  state,
  namespaced: true,
  name: 'SwKeyboardStore',
};
