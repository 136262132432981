import utils from '@/utils/utils';

const COLLECTION_NORMALIZED_PREFIX = 'collection';
const COLLECTION_LABEL_PREFIX = 'Collection:';

class FilterTreeNode {
  constructor(data = {}, parent = null) {
    this.id = data.id || utils.generateShortUuid();
    this.key = data.key || null;
    this.lang = data.lang || '';

    this.foundPublicationIds = data.foundPublicationIds || {};

    this.name = data.name || '';
    this.nameReNormalized = data.nameReNormalized || '';
    this.originalName = data.originalName || '';

    this.parent = parent;
    this.children = [];

    this.filterText = data.filterText || '';
    this.filterPattern = data.filterPattern || '';

    this.checked = data.checked || false;
    this.hasChecked = data.hasChecked || false;

    this.filtered = data.filtered || false;
    this.hasFiltered = data.hasFiltered || false;

    this.hasFilteredChecked = data.hasFilteredChecked || false;

    this.expanded = data.expanded || false;

    this.isCategoriesHidden = data.isCategoriesHidden || false;

    this.isAllChildrenSupplemental = data.isAllChildrenSupplemental || false;
  }

  insertNodes(data) {
    for (const [key, value] of Object.entries(data)) {
      const found = this.children.find(child => child.key === key);
      if (found) {
        found.insertNodes({
          [key]: value,
        });
        continue;
      }
      this.children.push(
        new CategoryFilterTreeNode({}, this).insertNodes({
          [key]: value,
        })
      );
    }
    return this;
  }

  setLang(lang) {
    this.lang = lang;
    return this;
  }

  setFoundPublicationIds(foundPublicationIds) {
    this.foundPublicationIds = foundPublicationIds;
    this.children?.forEach(child =>
      child.setFoundPublicationIds(foundPublicationIds)
    );
    return this;
  }

  setCategoriesHidden(isCategoriesHidden) {
    this.isCategoriesHidden = isCategoriesHidden;
    return this;
  }

  extendCategoriesWithAuthors(filterItems) {
    this.insertNodes(filterItems);
    return this;
  }
  extendCategoriesAndAuthorsWithTitles(filterItems) {
    this.insertNodes(filterItems);
    return this;
  }
  extendAuthorsWithTitles(filterItems) {
    for (const [key, value] of Object.entries(filterItems)) {
      const authorInCategories = this.children.reduce((acc, curr) => {
        const found = curr.children.find(child => {
          return child.key === key;
        });
        return found || acc;
      }, null);
      if (authorInCategories) {
        authorInCategories.insertNodes({ [key]: value });
      }
    }
    return this;
  }

  setExpanded(expanded) {
    this.expanded = expanded;
    return this;
  }

  setChecked(checked, directClick = true) {
    if (directClick) {
      this.checked = checked;
      this.children?.forEach(child => child.setChecked(checked));
    } else {
      this.checked = this.children?.every(child => child.checked);
      this.setHasChecked();
    }

    if (this.parent) {
      this.parent.setChecked(checked, false);
    }
    return this;
  }

  setFilterText(filterText, filterPattern = null) {
    this.filterText = filterText;
    this.filterPattern = filterPattern || this.createFilterPattern(filterText);
    this.children?.forEach(child =>
      child.setFilterText(this.filterText, this.filterPattern)
    );

    this.setExpanded(this.filterText.length);

    this.setFiltered();
    this.setHasFiltered();
    return this;
  }

  createFilterPattern(filterText) {
    let filterPattern = [...filterText.trim()];

    if (filterPattern.length) {
      filterPattern = filterPattern
        .map((letter, i) => {
          if (i === filterPattern.length - 1) {
            return letter;
          }
          return letter === ' ' ? '\\**' : letter + '\\**';
        })
        .join('');
    }
    return filterPattern;
  }

  setHasChecked() {
    this.hasChecked = this.children?.some(
      child => child.hasChecked || child.checked
    );
    return this;
  }

  setFiltered() {
    const filterText = this.filterText.split(' ').join('');
    this.filtered = this.name.includes(filterText);
    return this;
  }

  setHasFiltered() {
    this.hasFiltered = this.children?.some(
      child => child.filtered || child.hasFiltered
    );
    return this;
  }

  setHasFilteredChecked() {
    this.hasFilteredChecked = this.children?.some(
      child => (child.filtered && child.checked) || child.hasFilteredChecked
    );
    return this;
  }

  get isVisible() {
    return true;
  }

  get isFound() {
    return true;
  }

  get label() {
    if (
      !this.filterPattern.length ||
      !this.filtered ||
      !this.filterText.length
    ) {
      return this.originalName;
    }

    const regex = RegExp(this.filterPattern, 'gi');
    let label = this.originalName;
    let match;

    while ((match = regex.exec(this.nameReNormalized))) {
      const startPosition = match.index;
      const endPosition = regex.lastIndex;

      label =
        this.originalName.substring(0, startPosition) +
        '<span class="filter-req">' +
        this.originalName.substring(startPosition, endPosition) +
        '</span>' +
        this.originalName.substring(endPosition);
    }
    return label;
  }

  get visibleChildren() {
    return this.children?.filter(child => child.isVisible);
  }

  get checkedCount() {
    return this.children.reduce((acc, child) => {
      acc += child.checkedCount;
      return acc;
    }, 0);
  }

  get filteredPublicationIds() {
    return this.children.reduce((acc, child) => {
      acc = [...acc, ...child.filteredPublicationIds];
      return acc;
    }, []);
  }

  getNormalizedFilterText(filterText = '', lang) {
    filterText = filterText.toLowerCase().replace(/^\s*(a|an|the)\s+/gi, '');
    return _getReNormalizedName(filterText, lang, '');
  }

  get isAnyCategoryEmpty() {
    return this.children.length
      ? this.children?.some(child => child.isAnyCategoryEmpty)
      : true;
  }

  get isAnyAuthorEmpty() {
    return this.children.length
      ? this.children?.some(child => child.isAnyAuthorEmpty)
      : true;
  }
}

class CategoryFilterTreeNode extends FilterTreeNode {
  constructor(data = {}, parent = null) {
    super(data, parent);
    this.level = 0;
    this.lang = this.parent?.lang || '';
    this.isCategoriesHidden = this.parent?.isCategoriesHidden || false;
  }

  insertNodes(data) {
    const [key, value] = Object.entries(data)[0];
    this.key = this.key || key;
    this.name = this.name || key;
    this.originalName = this.originalName || value?.info?.label;
    this.nameReNormalized = _getReNormalizedName(this.originalName, this.lang);

    for (const val of value.data) {
      const composedKey = val.info?.isCollection
        ? val.info?.collectionTitleNormalized
        : val.info?.labelNormalized;

      const found = this.children.find(child => {
        return child.key === composedKey;
      });
      if (found) {
        found.insertNodes({
          [composedKey]: val,
        });
        continue;
      }

      this.children.push(
        new AuthorFilterTreeNode({ key: composedKey }, this).insertNodes({
          [composedKey]: val,
        })
      );
    }
    return this;
  }

  get isVisible() {
    return (
      !this.filterText.length ||
      this.hasFiltered ||
      this.children.some(item => item.hasFiltered)
    );
  }

  get checkboxDisabled() {
    return (
      !!this.filterText.length ||
      this.children.some(child => child.checkboxDisabled)
    );
  }

  get label() {
    return this.originalName;
  }

  get isAnyCategoryEmpty() {
    return !this.children.length;
  }

  get isAnyAuthorEmpty() {
    return this.children.length
      ? this.children?.some(child => child.isAnyAuthorEmpty)
      : true;
  }
}

class AuthorFilterTreeNode extends FilterTreeNode {
  constructor(data = {}, parent = null) {
    super(data, parent);
    this.level = 1;
    this.lang = this.parent?.lang || '';
    this.isCategoriesHidden = this.parent?.isCategoriesHidden || false;
  }
  insertNodes(data) {
    const [key, value] = Object.entries(data)[0];
    this.key = this.key || key;
    this.name = this.name || value.info?.labelNormalized;
    this.originalName = this.originalName || value?.info?.label;
    this.isCollection = this.isCollection || value?.info?.isCollection;

    if (this.isCollection) {
      this.setName(value.info?.collectionTitleNormalized);
      this.setOriginalName(value.info?.collectionTitle);
    }

    this.nameReNormalized = _getReNormalizedName(this.originalName, this.lang);

    const newChildren = [];
    for (const val of value.data) {
      const found = this.children.find(child => {
        return (
          child.key === val.info.labelNormalized &&
          child.publicationId === val.data.publicationId
        );
      });
      if (found) {
        found.insertNodes({
          [val.info.labelNormalized]: val,
        });
      } else {
        newChildren.push(
          new TitleFilterTreeNode({}, this).insertNodes({
            [val.info.labelNormalized]: val,
          })
        );
      }
    }

    if (newChildren.length) {
      this.children = [...this.children, ...newChildren];
      this.setIsAllChildrenSupplemental();
    }
    return this;
  }

  setName(extraNameNormalized) {
    if (extraNameNormalized) {
      this.name = COLLECTION_NORMALIZED_PREFIX + extraNameNormalized;
    }
  }

  setOriginalName(extraName) {
    if (extraName) {
      this.originalName = `<b>${COLLECTION_LABEL_PREFIX}</b> ${extraName}`;
    }
  }

  setIsAllChildrenSupplemental() {
    this.isAllChildrenSupplemental = !this.children.some(
      child => child.type === 'Book'
    );
    return this;
  }

  get childrenCount() {
    return this.children?.length;
  }

  get checkedCount() {
    return this.children?.filter(paragraph => paragraph.checked).length;
  }

  get isVisible() {
    const isCategoryExpanded = this.isCategoriesHidden || this.parent?.expanded;

    if (this.filterText.length) {
      return isCategoryExpanded && (this.filtered || this.hasFiltered);
    }
    return isCategoryExpanded;
  }

  get filteredPublicationIds() {
    return this.children.reduce((acc, child) => {
      if (child.checked) {
        acc.push(child.publicationId);
      }
      return acc;
    }, []);
  }

  get checkboxDisabled() {
    return !this.children.length;
  }

  get isAnyAuthorEmpty() {
    return !this.children?.length;
  }
}

class TitleFilterTreeNode extends FilterTreeNode {
  constructor(data = {}, parent = null) {
    super(data, parent);
    this.level = 2;
    this.publicationId = data.publicationId || null;
    this.type = data.type || null;
    this.lang = this.parent?.lang || '';
    this.collectionTitleNormalized = data.collectionTitleNormalized || null;
    this.isCollection = data.isCollection || false;
  }

  insertNodes(data) {
    const [key, value] = Object.entries(data)[0];
    this.key = this.key || key;
    this.name = this.name || key;
    this.originalName = this.originalName || value?.info?.label;
    this.publicationId = value.info.publicationId;
    this.type = value.info.bookType;
    this.collectionTitleNormalized = value.info.collectionTitleNormalized;
    this.isCollection = value.info.isCollection;

    if (this.isCollection) {
      this.setName(value.info?.authorNameNormalized);
    }

    this.nameReNormalized = _getReNormalizedName(this.originalName, this.lang);

    return this;
  }

  setName(authorNameNormalized) {
    if (authorNameNormalized) {
      this.name = `${this.name}${authorNameNormalized}`;
    }
  }

  get isVisible() {
    const isAuthorVisible = this.parent?.isVisible;
    const isAuthorExpanded = this.parent?.expanded;

    if (this.filterText.length) {
      return this.filtered && isAuthorVisible && isAuthorExpanded;
    }
    return isAuthorVisible && isAuthorExpanded;
  }

  get isFound() {
    return !!this.foundPublicationIds[this.publicationId];
  }
}

const normalizationMethodByLang = {
  default: 'NFD',
  ar: 'NFKC',
  fa: 'NFKC',
};

function isArabicLetter(char) {
  return /\p{Script=Arabic}/u.test(char);
}

function isLatinLetter(char) {
  return /\p{Script=Latin}/u.test(char);
}

function isCyrillicLetter(char) {
  return /\p{Script=Cyrillic}/u.test(char);
}

const checkMethodsByLang = {
  en: isLatinLetter,
  ar: isArabicLetter,
  ru: isCyrillicLetter,
  uk: isCyrillicLetter,
};

const _getReNormalizedName = (originalName, language, replaceSymbol = '*') => {
  let lang;
  const originalNameLetters = [...originalName];
  const normalizedOriginalName = originalNameLetters
    .map(letter => {
      let normalizedLetter;

      if (letter === ' ') {
        return letter;
      }

      for (lang in checkMethodsByLang) {
        if (!checkMethodsByLang[lang](letter)) {
          continue;
        }
        lang = lang === 'ar' && language === 'fa' ? 'fa' : lang;
        normalizedLetter = letter.normalize(
          normalizationMethodByLang[lang] || normalizationMethodByLang.default
        );
      }
      return normalizedLetter || letter;
    })
    .join('');
  return normalizedOriginalName
    .replace(/[\u0300-\u036f]/g, '')
    .replace(
      /(?:[^\u0600-\u06FF\u0750-\u077Fa-zA-Zа-яА-Я\r\n]|[\u064B-\u0652])/gim,
      replaceSymbol
    );
};

const createFilterTree = (data = {}, lang = '', isCategoriesHidden = false) => {
  return new FilterTreeNode()
    .setLang(lang)
    .setCategoriesHidden(isCategoriesHidden)
    .insertNodes(data);
};

export default {
  createFilterTree,
};
