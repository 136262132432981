<template>
  <div class="search-results-tooltip" dir="auto">
    <div data-test="content" class="search-results-tooltip-content">
      <div class="tooltip-block">
        <div data-test="examples" class="wrapper-text-list">
          <div v-for="(hint, index) in hints" class="text-list" :key="index">
            <div class="example-block">
              <div class="phrase-block">
                <div class="pic-block">
                  <BaseIcon icon-name="ico-lamp" />
                </div>
                <div class="text-block" v-html="hint.text"></div>
              </div>
              <div class="example-text-block">
                <div class="example-field">
                  <BaseIcon icon-name="ico-search" />
                  <div class="example-text" v-html="hint.example1"></div>
                </div>
                <div class="field-hint" v-html="hint.description1"></div>
                <div class="example-field">
                  <BaseIcon icon-name="ico-search" />
                  <div class="example-text" v-html="hint.example2"></div>
                </div>
                <div class="field-hint" v-html="hint.description2"></div>
              </div>
            </div>
            <div class="description-block">
              {{ $tc('swInfo.Tooltip.Paragraph1.title') }}
            </div>
          </div>
        </div>
        <!--/examples-->
      </div>

      <div class="tooltip-block -hot-keys">
        <div dir="auto" class="wrapper-title-block spec-title-block">
          <div class="title-block">
            <div class="text-title">{{ $tc('swInfo.HotKeys.Title') }}</div>
          </div>
        </div>

        <div class="search-keys">
          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">Backspace</span>
                <span class="no-key-button">or</span>
                <span class="key-button padd-left">Delete</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control1.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">&uarr;</span>
                <span class="no-key-button">or</span>
                <span class="key-button">&darr;</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control2.label1') }} <br />
              {{ $tc('swInfo.HotKeys.Control2.label2') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-right">Ctrl</span>
                <span class="no-key-button">+</span>
                <span class="key-button">I</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control3.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-right">Ctrl</span>
                <span class="no-key-button">+</span>
                <span class="key-button">&larr;</span>
                <span class="key-button">&rarr;</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control4.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">Esc</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control5.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-right">Shift</span>
                <span class="no-key-button">+</span>
                <span class="key-button">&larr;</span>
                <span class="key-button">&rarr;</span>
                <span class="no-key-button">or</span>
                <span class="key-button">&uarr;</span>
                <span class="key-button">&darr;</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control6.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">Tab</span>
                <span class="no-key-button">or</span>
                <span class="key-button">Shift</span>
                <span class="no-key-button">+</span>
                <span class="key-button">Tab</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control7.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-left">Enter</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control8.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">&larr;</span>
                <span class="no-key-button">or</span>
                <span class="key-button">&rarr;</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control9.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-left padd-right">Space</span>
                <span class="no-key-button">or</span>
                <span class="key-button padd-right">Shift</span>
                <span class="no-key-button">+</span>
                <span class="key-button padd-left padd-right">Space</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control10.label1') }}
            </div>
          </div>

          <div class="search-key-item" />

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-right">Shift</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('swInfo.HotKeys.Control11.label1') }}
            </div>
          </div>
        </div>
        <!--/search-keys-->
      </div>
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/BaseIcon.vue';
  import { mapState } from 'vuex';

  import * as log from 'loglevel';
  log.setLevel('debug');

  export default {
    name: 'Info',
    components: {
      BaseIcon,
    },
    data() {
      return {
        hints: [
          {
            text: this.$tc('swInfo.Tooltip.Paragraph1.label'),
            example1: this.$tc('swInfo.Tooltip.Paragraph1.example1'),
            description1: this.$tc('swInfo.Tooltip.Paragraph1.description1'),
            example2: this.$tc('swInfo.Tooltip.Paragraph1.example2'),
            description2: this.$tc('swInfo.Tooltip.Paragraph1.description2'),
          },
          {
            text: this.$tc('swInfo.Tooltip.Paragraph2.label'),
            example1: this.$tc('swInfo.Tooltip.Paragraph2.example1'),
            description1: this.$tc('swInfo.Tooltip.Paragraph2.description1'),
            example2: this.$tc('swInfo.Tooltip.Paragraph2.example2'),
            description2: this.$tc('swInfo.Tooltip.Paragraph2.description2'),
          },
          {
            text: this.$tc('swInfo.Tooltip.Paragraph3.label'),
            example1: this.$tc('swInfo.Tooltip.Paragraph3.example1'),
            description1: this.$tc('swInfo.Tooltip.Paragraph3.description1'),
            example2: this.$tc('swInfo.Tooltip.Paragraph3.example2'),
            description2: this.$tc('swInfo.Tooltip.Paragraph3.description2'),
          },
          {
            text: this.$tc('swInfo.Tooltip.Paragraph4.label'),
            example1: this.$tc('swInfo.Tooltip.Paragraph4.example1'),
            description1: this.$tc('swInfo.Tooltip.Paragraph4.description1'),
            example2: this.$tc('swInfo.Tooltip.Paragraph4.example2'),
            description2: this.$tc('swInfo.Tooltip.Paragraph4.description2'),
          },
        ],
      };
    },
    computed: {
      ...mapState('SwContextStore', ['brand']),
    },
  };
</script>

<style lang="less">
  @import 'Info.less';
</style>
